import * as React from 'react';

import { INumberedCardProps, NumberedCard } from '..';

import * as styles from './Steps.module.less';

export interface IStepsProps {
  title: string;
  elements: INumberedCardProps[];
}

export const Steps = ({ title, elements }: IStepsProps): JSX.Element => {
  const newTitle = title.substring(0, title.lastIndexOf(' '));
  const coloredWord = title.substring(title.lastIndexOf(' '));

  return (
    <div className={styles.container} data-testid="steps-block">
      <div className={styles.wrapper}>
        <h2>
          {newTitle}
          <span>{coloredWord}</span>
        </h2>
        <div>
          {React.Children.toArray(
            elements.map(({ image, title, description }, index) => (
              <NumberedCard
                title={title}
                description={description}
                image={image}
                number={index + 1}
              />
            ))
          )}
        </div>
      </div>
    </div>
  );
};
