import {
  ContentfulRichTextGatsbyReference,
  RenderRichTextData,
  renderRichText,
} from 'gatsby-source-contentful/rich-text';
import * as React from 'react';

import * as styles from './Info.module.less';

interface IInfoProps {
  data: RenderRichTextData<ContentfulRichTextGatsbyReference>;
}

const renderOptions = {
  renderText: (text) => {
    return text.split('\n').reduce((children, textSegment, index) => {
      return [...children, index > 0 && <br key={index} />, textSegment];
    }, []);
  },
};

export const Info = ({ data }: IInfoProps): JSX.Element => {
  return (
    <aside className={styles.container} data-testid="info">
      {renderRichText(data, renderOptions)}
    </aside>
  );
};
