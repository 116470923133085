import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import * as React from 'react';

import { ICustomGatsbyImageData } from '../../utils/types';
import { Link } from '../Clickable';

import * as styles from './Section.module.less';

export interface ISectionProps {
  title: string;
  description: string;
  image: ICustomGatsbyImageData;
  links: { title: string; url: string }[] | null;
  sideBySide?: boolean;
}

export const Section = ({
  title,
  description,
  image: imageProp,
  links,
  sideBySide,
}: ISectionProps): JSX.Element => {
  const image = getImage(imageProp.gatsbyImageData);
  return (
    <div
      data-testid="section-root"
      className={`${sideBySide ? styles.sideBySide : styles.fullScreen}`}
    >
      {image && <GatsbyImage image={image} alt={imageProp.title} />}
      <div className={styles.overlayContainer}>
        <div className={styles.content}>
          <h2>{title}</h2>
          <p>{description}</p>
          <div className={styles.buttonsContainer}>
            {links &&
              React.Children.toArray(
                links.map(({ title, url }, index) => {
                  const isExternal =
                    url.startsWith('http://') || url.startsWith('https://');
                  const href = isExternal ? url : `/${url}`;
                  return (
                    <Link
                      href={href}
                      isExternal={isExternal}
                      variant={index === 0 ? 'primary' : 'secondary'}
                      className={styles.linkMargin}
                    >
                      {title}
                    </Link>
                  );
                })
              )}
          </div>
        </div>
      </div>
    </div>
  );
};
