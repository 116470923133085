// extracted by mini-css-extract-plugin
export var container = "Catalogue-module--container--nYY-J";
export var sideWrapper = "Catalogue-module--sideWrapper--e42LW";
export var sideInfowrapper = "Catalogue-module--sideInfowrapper--1MEfC";
export var itemsWrapper = "Catalogue-module--itemsWrapper--M6YTL";
export var item = "Catalogue-module--item--C99gq";
export var formWrapper = "Catalogue-module--formWrapper--2hW-s";
export var displayOnDesktop = "Catalogue-module--displayOnDesktop--5_HWm";
export var tabs = "Catalogue-module--tabs--j7oLn";
export var pane = "Catalogue-module--pane--3iuAp";
export var accordion = "Catalogue-module--accordion--maFka";