import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import * as React from 'react';

import { ICustomGatsbyImageData } from '../../utils/types';
import { Link } from '../Clickable';

import * as styles from './Highlight.module.less';

export interface IHighlightProps {
  sectionTitle: string;
  title: string;
  paragraph: string;
  link?: {
    url: string;
    text: string;
  };
  img: ICustomGatsbyImageData;
  orientation?: 'normal' | 'reverse';
  teint?: 'almond' | 'tan';
}

export const Highlight = ({
  sectionTitle,
  title,
  paragraph,
  link,
  img,
  orientation = 'normal',
  teint = 'almond',
}: IHighlightProps): JSX.Element => {
  const image = getImage(img.gatsbyImageData);
  const isLinkExternal =
    link?.url?.startsWith('http://') || link?.url?.startsWith('https://');
  const href = isLinkExternal ? link?.url : `/${link?.url}`;
  return (
    <div
      className={`${styles.container} ${
        orientation === 'reverse' ? styles.reverse : ''
      } `}
      data-testid="highlight"
    >
      <div className={`${styles.textContainer} ${teint ? styles[teint] : ''}`}>
        <span>{sectionTitle}</span>
        <h2>{title}</h2>
        <p>{paragraph}</p>
        {link && href && (
          <Link href={href} isExternal={isLinkExternal} isDecorated>
            {link.text}
          </Link>
        )}
      </div>
      {image && <GatsbyImage image={image} alt={img.title} />}
    </div>
  );
};
