import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';

import { useTranslate } from '../../utils/translation/useTranslation';

import * as styles from './NotFound.module.less';

export const NotFound: React.FunctionComponent = () => {
  const { t } = useTranslate();
  return (
    <div className={styles.wrapper}>
      <div className={styles.text}>
        <div className={styles.firstLine}>{t('pageNotFoundMessage')}</div>
        <div className={styles.secondLine}>{t('pageNotFoundMessage1')}</div>
        <Link to="/">{t('goToHome')} &gt;</Link>
      </div>
      <StaticImage
        srcSet="./img/img-404.png 786w,
                ./img/img-404@2x.png 1536w,
                ./img/img-404@3x.png 2304w"
        sizes="(max-width: 767px) 786px,
               (max-width: 1440px) 1536px,
               2304px"
        src="./img/img-404.png"
        alt="broken link"
      />
    </div>
  );
};
