import { Link } from 'gatsby';
import * as React from 'react';

import { BottomBar, SubHeader, SubMenu } from '../';
import { LanguageContext } from '../../../context/LanguageContext';
import { useTranslate } from '../../../utils/translation/useTranslation';
import {
  IContentfulVacationBanner,
  ICustomGatsbyImageData,
} from '../../../utils/types';
import { VacationBanner } from '../VacationBanner/VacationBanner';
import sorpetalerLogo from '../shared/img/logo.svg';
import plusIcon from '../shared/img/plus-icon.svg';
import smallLocationIcon from '../shared/img/small-location-icon.svg';
import phoneIcon from '../shared/img/small-phone-icon.svg';

import * as styles from './Menu.module.less';

export interface IMenuLink {
  title: string;
  slug: string;
  order: number;
  menuTitle?: string;
  subPages: {
    title: string;
    menuTitle: string;
    slug: string;
    featuredImage?: ICustomGatsbyImageData;
  }[];
  btnTitle?: string;
}

export interface IMenuProps {
  links: IMenuLink[];
  socialLinks?: { icon: string; alt: string; url: string }[];
  isWidget?: boolean;
  vacationBanner?: IContentfulVacationBanner;
}

export const Menu = ({
  links,
  socialLinks,
  isWidget,
  vacationBanner,
}: IMenuProps): JSX.Element => {
  const [showBottomBar, setShowBottomBar] = React.useState(false);
  const [showSubHeader, setShowSubHeader] = React.useState(false);
  const [showSubMenu, setShowSubMenu] = React.useState(false);
  const [showBanner, setShowBanner] = React.useState<boolean>();
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [offset, setOffset] = React.useState(0);
  const language = React.useContext(LanguageContext);
  const { t } = useTranslate();

  const hideBanner = React.useCallback(() => {
    localStorage.setItem('showBanner', 'false');
    setShowBanner(false);
  }, [showBanner]);

  React.useEffect(() => {
    if (vacationBanner) {
      const currDate = new Date();
      const eventEndingDate = new Date(vacationBanner.eventEndingDate);
      if (
        localStorage.getItem('showBanner') === 'false' &&
        currDate < eventEndingDate
      ) {
        hideBanner();
      } else {
        setShowBanner(true);
      }
    }
  }, []);

  React.useEffect(() => {
    // to get the page Y offset and hide the preHeader when user scrolls
    // more at : https://stackoverflow.com/a/61018017/16059016
    window.onscroll = () => {
      setOffset(window.pageYOffset);
    };
  }, []);

  const toggleBottomBar = React.useCallback(() => {
    setShowBottomBar((prev) => !prev);
  }, [showBottomBar]);

  const toggleSubHeader = React.useCallback(
    (state) => {
      setShowSubHeader(state);
    },
    [showSubHeader]
  );

  const toggleSubMenu = React.useCallback(() => {
    setShowSubMenu((prev) => !prev);
  }, [showSubMenu]);

  return (
    <header className={styles.container}>
      {/* Black drop shadow used on the mobile version */}
      <div
        className={`${styles.menuBackground} ${showBottomBar ? styles.menuBackgroundShown : ''
          }`}
        onClick={toggleBottomBar}
      />
      {/* Pre-header section */}
      <div
        className={`${styles.preHeader} ${offset > 0 ? styles.preHeaderHidden : ''
          }`}
      >
        <ul>
          <li>
            <Link to={`${language === 'De' ? '/en/' : '/'}`}>
              <img src={smallLocationIcon} alt="International-icon" />
              {t('international')}
            </Link>
          </li>
          {/* tel in href to call the passed number */}
          <li>
            <a href="tel:+49 2393 9192 0">
              <span>
                <img src={phoneIcon} alt="phone-icon" />
                +49 2393 9192 0
              </span>
            </a>
          </li>
          <li>
            <Link to={`${language === 'En' ? '/en/contact/' : '/kontakt/'}`}>
              {t('contact')}
            </Link>
          </li>
        </ul>
      </div>
      {/* Menu section */}
      <nav
        className={
          showBottomBar ? styles.menu : `${styles.menu} ${styles.menuHidden}`
        }
      >
        <div
          className={`${styles.menuWrapper} ${isWidget ? styles.withWidgetLink : ''
            }`}
        >
          <span>{t('menu')}</span>
          <Link
            to={`${language === 'En' ? '/en/' : '/'}`}
            aria-label="Link to home page"
          >
            <img
              src={sorpetalerLogo}
              alt="sorpetaler-logo"
              data-testid="logo"
            />
          </Link>
          <a href="tel:0239391920" className={styles.widgetLink}>
            <span>{t('question')}</span>
            <span> 02393 91 92 0 </span>
            <span>Mo-Fr: 8 - 16:30 {t('clock')}</span>
          </a>
          <ul>
            {React.Children.toArray(
              links.map(({ title, order, slug, menuTitle }, index) => (
                <li
                  onClick={toggleSubMenu}
                  onMouseOver={() => {
                    toggleSubHeader(true);
                    setSelectedIndex(index);
                  }}
                >
                  {order !== 6 ? (
                    <Link to={slug}>
                      <span data-testid="menu-title">
                        {menuTitle ? menuTitle : title}
                      </span>
                      <div className={styles.arrowContainer} />
                    </Link>
                  ) : (
                    <Link to={slug}>
                      <span data-testid="menu-title">
                        {menuTitle ? menuTitle : title}
                      </span>
                      <div className={styles.arrowContainer} />
                      <img className={styles.plusIcon} src={plusIcon} alt="" />
                    </Link>
                  )}
                </li>
              ))
            )}
          </ul>
          <div className={styles.iconsContainer}>
            <ul className={styles.iconsList}>
              {socialLinks &&
                React.Children.toArray(
                  socialLinks.map(({ icon, alt, url }) => (
                    <li>
                      <a href={url} target="_blank" rel="noreferrer noopener">
                        <img src={icon} alt={alt} />
                      </a>
                    </li>
                  ))
                )}
            </ul>
          </div>
          {links[selectedIndex].order !== 3 &&
            links[selectedIndex].subPages != null && (
              <SubMenu
                showSubMenu={showSubMenu}
                toggleSubMenu={toggleSubMenu}
                link={links[selectedIndex]}
              />
            )}
        </div>
      </nav>
      {vacationBanner && vacationBanner.online && showBanner && (
        <VacationBanner {...vacationBanner} hideBanner={hideBanner} />
      )}
      {showSubHeader &&
        links[selectedIndex].order !== 3 &&
        links[selectedIndex].subPages != null && (
          <SubHeader
            link={links[selectedIndex]}
            toggleSubHeader={toggleSubHeader}
            decorated={selectedIndex === links.length - 1}
          />
        )}
      <BottomBar
        showBottomBar={showBottomBar}
        toggleBottomBar={toggleBottomBar}
        isWidget={isWidget}
      />
    </header>
  );
};
