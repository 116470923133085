import * as React from 'react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useTranslate } from '../../../utils/translation/useTranslation';

import * as styles from './Carousel.module.less';

// swiper bundle styles
import 'swiper/swiper-bundle.min.css';

// swiper core styles
import 'swiper/swiper.min.css';

// modules styles
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';

// configure Swiper to use modules
SwiperCore.use([Pagination, Navigation]);

interface ICarouselProps {
  children: React.ReactNode;
}

export const CustomCarousel: React.FunctionComponent<ICarouselProps> = ({
  children,
}) => {
  const [position, setPosition] = React.useState(0);
  const [visisbleSlides, setvisisbleSlides] = React.useState(0);
  const { t } = useTranslate();
  const getVisibileSlides = ({ index, sliderWidth, slides }) => {
    let currentActive = index;
    let count = 0;
    let width = slides[index];

    if (index < slides.length - 2) {
      while (width <= sliderWidth) {
        currentActive++;
        count++;
        width += slides[currentActive];
      }
    } else if (index < slides.length - 1) {
      currentActive = 2;
      count = 2;
    } else {
      currentActive = 1;
      count = 1;
    }
    setvisisbleSlides(count);
  };

  return (
    <div className={styles.container}>
      <Swiper
        spaceBetween={32}
        navigation
        slideToClickedSlide
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          0: {
            slidesPerView: 1,
            centeredSlides: true,
          },
          900: {
            slidesPerView: 'auto',
            centeredSlides: false,
            normalizeSlideIndex: false,
          },
        }}
        onSwiper={(swiper) => {
          getVisibileSlides({
            index: swiper.realIndex,
            sliderWidth: swiper.width,
            // @ts-expect-error slidesSizedGrid is not exported in swiper options object however it is defind and contains an array of slide widths
            slides: swiper.slidesSizesGrid,
          });
        }}
        onSlideChange={(swiper) => {
          getVisibileSlides({
            index: swiper.realIndex,
            sliderWidth: swiper.width,
            // @ts-expect-error slidesSizedGrid is not exported in swiper options object however it is defind and contains an array of slide widths
            slides: swiper.slidesSizesGrid,
          });
          setPosition(swiper.realIndex);
        }}
      >
        {React.Children.map(children, (child: React.ReactElement, index) => {
          return (
            //The class name depends on the current position and how many visible elements in the slide
            <SwiperSlide
              virtualIndex={index}
              style={{
                aspectRatio: child?.props?.imgStyle?.aspectRatio
                  ? `${child?.props?.imgStyle?.aspectRatio}`
                  : 'auto',
                //To check if the passed child is a gatsby Image
                maxHeight: child?.props?.imgStyle ? '700px' : 'max-content',
              }}
              className={`${
                index >= position && index < position + visisbleSlides
                  ? styles.visible
                  : ''
              }`}
            >
              {React.isValidElement(child) ? React.cloneElement(child) : null}
            </SwiperSlide>
          );
        })}
      </Swiper>
      <p className={styles.indicator}>
        <span className={styles.currentIndexs}>
          {position + visisbleSlides > position + 1
            ? `${position + 1}-${position + visisbleSlides}`
            : position + 1}
        </span>{' '}
        {t('from')} {React.Children.count(children)}
      </p>
      {/* The added space is there to seperate the span text from the rest */}
    </div>
  );
};
