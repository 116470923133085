import * as React from 'react';

import { WidgetCardsContainer } from '../../WidgetCardsContainer';
import * as styles from '../FensterTurenWidget.module.less';
import iconBalconyDoor from '../img/pictogram-balkontur-64.svg';
import iconFoldingDoor from '../img/pictogram-faltanlage-64.svg';
import iconFrontDoor from '../img/pictogram-haustur-64.svg';
import iconLiftSlideDoor from '../img/pictogram-hebeschiebetur-64.svg';

interface ISurveyProductItemProps {
  onSelect: (element: string) => void;
  selectedItem: string;
}

export const SurveyProductItem: React.FunctionComponent<ISurveyProductItemProps> = ({
  onSelect,
  selectedItem,
}) => {
  const onItemClick = (titles: string[]) => {
    onSelect(titles[0] || '');
  };
  return (
    <div>
      <div className={styles.question}>Was für eine Tür suchen Sie?</div>
      <WidgetCardsContainer
        elements={[
          {
            title: 'Haustür',
            icon: iconFrontDoor,
            selected: 'Haustür' === selectedItem,
          },
          {
            title: 'Balkontür',
            icon: iconBalconyDoor,
            selected: 'Balkontür' === selectedItem,
          },
          {
            title: 'Hebeschiebetür',
            icon: iconLiftSlideDoor,
            selected: 'Hebeschiebetür' === selectedItem,
          },
          {
            title: 'Faltanlage',
            icon: iconFoldingDoor,
            selected: 'Faltanlage' === selectedItem,
          },
        ]}
        selectedItems={selectedItem ? [selectedItem] : []}
        setSelectedItems={onItemClick}
      />
    </div>
  );
};
