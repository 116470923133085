import { Checkbox, Col, Form, Input, Radio, Row } from 'antd';
import Cookies from 'js-cookie';
import fetch from 'node-fetch';
import * as React from 'react';

import { IFormProps } from '..';
import { trackFormSuccess } from '../../../utils/helpers';
import { useTranslate } from '../../../utils/translation/useTranslation';
import { Button, Link } from '../../Clickable';
import * as styles from '../Shared/Form.module.less';
import { SuccessTab } from '../SuccessTab/SuccessTab';

interface IDigitalCatalogFormProps extends IFormProps {
  catalogSlug: string;
}

const formItemLayout = {
  wrapperCol: {
    xs: { span: 12 },
    sm: { span: 24 },
  },
};
export const DigitalCatalogueForm = ({
  handleSubmit,
  catalogSlug,
}: IDigitalCatalogFormProps): React.ReactElement => {
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<React.ReactNode>('');
  const { t } = useTranslate();
  const handleFinish = (values) => {
    const isTest = process.env.NODE_ENV === 'test';
    return isTest
      ? handleSubmit(values)
      : fetch('/.netlify/functions/DigitalForm', {
        method: 'POST',
        body: JSON.stringify({
          ...values,
          catalogSlug,
          gclid: Cookies.get('gclid')
        }),
      })
        .then((res) => {
          if (!res.ok) {
            setErrorMessage(t('errorMessage'));
            return;
          } else {
            return res;
          }
        })
        .then(() => {
          setIsSubmitted(true);
          trackFormSuccess('Absenden Katalog-Formular');
          handleSubmit(values);
        })
        .catch(() => setErrorMessage(t('errorMessage')));
  };
  return (
    <>
      {isSubmitted ? (
        <SuccessTab tabType="digital" />
      ) : (
        <Form {...formItemLayout} onFinish={handleFinish} name="DigitalForm">
          <Form.Item>
            <span>*{t('requiredFieldsReminder')}</span>
          </Form.Item>
          <Form.Item>
            <span>{t('salutation')}*</span>
          </Form.Item>
          <Form.Item
            name="gender"
            rules={[
              {
                required: false,
                message: t('genderMessage'),
              },
            ]}
          >
            <Radio.Group name="gender">
              <Radio value="Frau">{t('woman')}</Radio>
              <Radio value="Herr">{t('man')}</Radio>
            </Radio.Group>
          </Form.Item>
          <Row justify="space-between">
            <Col span="11">
              <Form.Item
                name="first-name"
                rules={[
                  {
                    required: true,
                    message: t('firstNameMessage'),
                  },
                ]}
              >
                <Input name="first-name" placeholder={`${t('firstname')}*`} />
              </Form.Item>
            </Col>
            <Col span="11">
              <Form.Item
                name="last-name"
                rules={[
                  {
                    required: true,
                    message: t('lastNameMessage'),
                  },
                ]}
              >
                <Input name="last-name" placeholder={`${t('lastname')}*`} />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: t('emptyEmailMessage'),
              },
              {
                type: 'email',
                message: t('validEmailMessage'),
              },
            ]}
          >
            <Input name="email" placeholder={`${t('emailAddress')}*`} />
          </Form.Item>
          <Form.Item
            name="accept-check"
            valuePropName="checked"
            rules={[
              {
                required: true,
                message: t('privacyPolicyMessage'),
              },
            ]}
          >
            <Checkbox name="accept-check">
              {t('acceptPrivarcyPolicyMsg1')}{' '}
              <Link href="/datenschutzerklaerung/" className={styles.formLink}>
                {t('acceptPrivarcyPolicyMsg2')}
              </Link>{' '}
              {t('acceptPrivarcyPolicyMsg3')}
            </Checkbox>
          </Form.Item>
          <Form.Item
            className={styles.submitBtn}
            data-cy="digitaler-submit-btn"
          >
            <Button type="submit" variant={'primary'}>
              {t('requestCatalog')}
            </Button>
          </Form.Item>
          {errorMessage && (
            <Form.Item>
              <p>{errorMessage}</p>
            </Form.Item>
          )}
        </Form>
      )}
    </>
  );
};
