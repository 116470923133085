import { Checkbox, Form, Input } from 'antd';
import { StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';

import { Button, Link } from '../Clickable';

import * as styles from './CompetitionPage.module.less';

const { TextArea } = Input;

interface ICompetitionForm {
  keywords: string;
  want: string;
  firstName: string;
  lastName: string;
  email: string;
  contactAccepted: boolean;
  dataProtectionAccepted: boolean;
}

export const CompetitionPage: React.FunctionComponent = () => {
  const [isSubmitted, setIsSubmitted] = React.useState(false);

  function handleSubmit(values: ICompetitionForm) {
    console.log(values);
    fetch('/.netlify/functions/competitionForm', {
      method: 'POST',
      body: JSON.stringify(values),
    }).then(() => {
      setIsSubmitted(true);
    });
  }

  return isSubmitted ? (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.title}>
          Vielen Dank für die Teilnahme am Gewinnspiel!
        </div>
        <div className={styles.image}>
          <StaticImage src="./assets/azubi-successful.png" alt="mask-group" />
        </div>
        <div className={styles.subtitle}>
          Dein ausgefülltes Teilnahmeformular wurde erfolgreich an uns
          übermittelt. Wir benachrichtigen dich, falls du zu den glücklichen
          Gewinnern gehörst.
        </div>
      </div>
    </div>
  ) : (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.hero}>
          <h1>Gewinnspiel</h1>
        </div>
        <div className={styles.title}>
          Wie stellst du dir deinen Traumarbeitgeber vor?
        </div>
        <div className={styles.subtitle}>
          Jetzt Fragen beantworten und mit etwas Glück einen von drei Stanley
          Cups gewinnen.
        </div>
        <div className={styles.image}>
          <StaticImage src="./assets/mask-group.webp" alt="mask-group" />
        </div>
        <div className={styles.form}>
          <Form onFinish={handleSubmit}>
            <div>
              Diese 3 Schlagwörter treffen auf meinen Traumarbeitgeber zu:*
            </div>
            <Form.Item
              name="keywords"
              rules={[
                {
                  required: true,
                  // message: 'erforderlich',
                  validator: (_, value: string) => {
                    const wordsCount = value.split(' ').length;
                    if (wordsCount < 3)
                      return Promise.reject(
                        'Geben Sie mindestens drei Wörter ein'
                      );
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <TextArea rows={4} />
            </Form.Item>
            <div>Ich wünsche mir von meinem Traumarbeitgeber...*</div>
            <Form.Item
              name="want"
              rules={[
                {
                  required: true,
                  message: 'erforderlich',
                },
              ]}
            >
              <TextArea rows={4} />
            </Form.Item>
            <Form.Item
              name="firstName"
              rules={[
                {
                  required: true,
                  message: 'Bitte geben Sie Ihren Vornamen ein!',
                },
              ]}
            >
              <Input name="firstName" placeholder="Vorname*" />
            </Form.Item>
            <Form.Item
              name="lastName"
              rules={[
                {
                  required: true,
                  message: 'Bitte geben Sie Ihren Nachnamen ein!',
                },
              ]}
            >
              <Input name="lastName" placeholder="Nachname*" />
            </Form.Item>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: 'Bitte geben Sie Ihre E-Mail Adresse ein!',
                },
                {
                  type: 'email',
                  message:
                    'Bitte geben Sie eine gültige E-Mail Adresse ein (IE:example@gmail.com)',
                },
              ]}
            >
              <Input name="email" placeholder="E-Mail-Adresse*" />
            </Form.Item>
            <Form.Item
              name="dataProtectionAccepted"
              valuePropName="checked"
              rules={[
                {
                  required: true,
                  message:
                    'Bitte überprüfen Sie die Datenschutzerklärung und stimmen Sie der Datenverarbeitung zu!',
                },
              ]}
            >
              <Checkbox name="dataProtectionAccepted">
                Ich habe die{' '}
                <Link
                  href="/datenschutzerklaerung/"
                  className={styles.formLink}
                >
                  Datenschutzerklärung
                </Link>{' '}
                gelesen und willige in die Datenverarbeitung ein.*
              </Checkbox>
            </Form.Item>
            <Form.Item
              name="contactAccepted"
              valuePropName="checked"
              rules={[
                {
                  required: true,
                  message:
                    'Bitte akzeptieren Sie die Kontaktaufnahme im Rahmen des Wettbewerbs',
                },
              ]}
            >
              <Checkbox name="contactAccepted">
                Ja, ich möchte am Gewinnspiel teilnehmen. Mit der
                Kontaktaufnahme im Rahmen des Gewinnspiels bin ich
                einverstanden.*
              </Checkbox>
            </Form.Item>
            <Form.Item>
              <Button type="submit" variant="primary">
                Senden
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};
