import * as React from 'react';

import { WidgetCardsContainer } from '../../WidgetCardsContainer';
import * as styles from '../FensterTurenWidget.module.less';
import iconFireProtectionWindow from '../img/pictogram-brandschutzfenster-64.svg';
import iconLiftSlideDoor from '../img/pictogram-hebeschiebetur-64.svg';
import iconNoSpecialWindows from '../img/pictogram-keine-spezialfenster-64.svg';
import iconSlidingWindow from '../img/pictogram-schiebefenster-64.svg';
import iconSwingWindow from '../img/pictogram-schwingfenster-64.svg';
import iconSpecialShapes from '../img/pictogram-sonderformen-64.svg';

interface IConstructionProps {
  onSelect: (element: string[]) => void;
  selectedItem: string[];
}

export const Contstruction: React.FunctionComponent<IConstructionProps> = ({
  onSelect,
  selectedItem,
}) => {
  const onItemClick = (titles: string[]) => {
    onSelect(titles);
  };

  return (
    <div>
      <div className={styles.question}>Benötigen Sie Spezialfenster?</div>
      <div>Mehrfachselektion ist möglich</div>
      <WidgetCardsContainer
        elements={[
          {
            title: 'Schiebefenster',
            icon: iconSlidingWindow,
            selected: selectedItem.includes('Schiebefenster'),
          },
          {
            title: 'Hebeschiebetür',
            icon: iconLiftSlideDoor,
            selected: selectedItem.includes('Hebeschiebetür'),
          },
          {
            title: 'Sonderformen',
            icon: iconSpecialShapes,
            selected: selectedItem.includes('Sonderformen'),
          },
          {
            title: 'Schwingfenster',
            icon: iconSwingWindow,
            selected: selectedItem.includes('Schwingfenster'),
          },
          {
            title: 'Brandschutzfenster',
            icon: iconFireProtectionWindow,
            selected: selectedItem.includes('Brandschutzfenster'),
          },
          {
            title: 'Nein, keine Spezialfenster',
            icon: iconNoSpecialWindows,
            selected: selectedItem.includes('Nein, keine Spezialfenster'),
          },
        ]}
        selectedItems={selectedItem}
        setSelectedItems={onItemClick}
        multipleSelect
      />
    </div>
  );
};
