import { Collapse } from 'antd';
import { Link } from 'gatsby';
import * as React from 'react';

import { IMenuLink } from '..';
import { LanguageContext } from '../../context/LanguageContext';
import { useTranslate } from '../../utils/translation/useTranslation';

import * as styles from './Footer.module.less';
import arrowIcon from './img/arrow-icon.svg';
import sorpetalerLogo from './img/sorpetaler-logo.svg';

export interface IInfoLink {
  title: string;
  url: string;
}

interface IFooterProps {
  socialLinks: {
    title: string;
    url: string;
    logo: {
      title: string;
      src: string;
    };
  }[];
  menuLinks: IMenuLink[];
  infoLinks: IInfoLink[];
}

const { Panel } = Collapse;

export const Footer = ({
  socialLinks,
  menuLinks,
  infoLinks,
}: IFooterProps): JSX.Element => {
  const currentYear = new Date().getFullYear();
  const language = React.useContext(LanguageContext);
  const { t } = useTranslate();

  return (
    <footer className={styles.container}>
      <div className={styles.subContainer}>
        <div className={styles.logoAndSocialsContainer}>
          <Link to={`${language === 'En' ? '/en/' : '/'}`}>
            <img src={sorpetalerLogo} alt="sorpetaler" />
            <span data-testid="trademark">© Sorpetaler Fensterbau GmbH</span>
          </Link>
          <ul>
            {React.Children.toArray(
              socialLinks.map(({ title, url, logo }) => (
                <li>
                  <a
                    href={url}
                    data-testid="social-links"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <img src={logo.src} alt={logo.title} />
                    <span>{title}</span>
                  </a>
                </li>
              ))
            )}
          </ul>
        </div>

        <div className={styles.menuContainer}>
          {menuLinks.map(({ title, menuTitle, subPages, slug }) => (
            <div key={slug} data-testid="menu-links">
              <Link to={slug}>{menuTitle ? menuTitle : title}</Link>
              <ul>
                {subPages &&
                  subPages.map(({ menuTitle, slug: subPageSlug }) => (
                    <li key={subPageSlug} className={styles.item}>
                      <Link to={subPageSlug}>{menuTitle}</Link>
                    </li>
                  ))}
              </ul>
            </div>
          ))}
        </div>
        {/* Callapsible component is shown only on tablet and mobile devices */}
        <Collapse
          accordion
          className={styles.accordion}
          defaultActiveKey={['0']}
          expandIconPosition="right"
          /* @ts-expect-error Panelkey is not recognized by typescript because it doesn't exist in the declaration file, although it is defined and returns a value */
          expandIcon={({ isActive, panelKey }) =>
            panelKey != 2 ? (
              <img
                // pass the scoped classname to the accordion
                className={`${isActive ? 'rotated' : ''}`}
                src={arrowIcon}
                alt="expand-icon"
              />
            ) : null
          }
          ghost
        >
          {menuLinks.map(({ title, subPages, slug, menuTitle }, index) => (
            <Panel
              key={index.toString()}
              header={<Link to={slug}>{menuTitle ? menuTitle : title}</Link>}
              collapsible={
                index === 2
                  ? 'disabled'
                  : undefined || subPages
                    ? undefined
                    : 'disabled'
              }
              showArrow={subPages != null}
            >
              <ul>
                {subPages &&
                  subPages.map(({ menuTitle, slug: subPageSlug }) => (
                    <li key={subPageSlug} className={styles.item}>
                      <Link to={subPageSlug}>{menuTitle}</Link>
                    </li>
                  ))}
              </ul>
            </Panel>
          ))}
        </Collapse>
        {/*  */}
        <div className={styles.linksContainer}>
          <p>© {currentYear} · © Sorpetaler Fensterbau GmbH</p>
          <ul>
            {React.Children.toArray(
              infoLinks.map(({ title, url }) => (
                <li>
                  <Link data-testid="info-links" to={url}>
                    {title}
                  </Link>
                </li>
              ))
            )}
            <li>
              <a
                // instead of using an empty href or a href="#" The button role tells the user that the particular element is being treated as a button as an override for whatever semantics the underlying element may have had.
                role="button"
                onClick={() => {
                  if (window['CookieFirst']) {
                    window['CookieFirst'].openPanel();
                  }
                }}
              >
                {t('cookie')}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};
