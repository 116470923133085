import * as React from 'react';

import { useTranslate } from '../../../utils/translation/useTranslation';
import catalogue from '../Shared/img/pictogram-catalog-send-64.svg';

import * as styles from './SuccessTab.module.less';

interface Props {
  tabType: 'print' | 'digital';
}

export const SuccessTab: React.FunctionComponent<Props> = (props) => {
  const { t } = useTranslate();
  return (
    <div className={styles.successScreen}>
      <img src={catalogue} alt="catalogue-icon" />
      {props.tabType === 'digital' && <p>{t('digitalMessage')}</p>}
      {props.tabType === 'print' && <p>{t('printSuccessMessage')}</p>}
    </div>
  );
};
