import * as React from 'react';

import * as styles from './Paragraph.module.less';

interface IParagraphProps {
  title: string;
  text: string;
  sideBySide?: boolean;
}

export const Paragraph = ({
  title,
  text,
  sideBySide,
}: IParagraphProps): JSX.Element => {
  return (
    // we need to pass an empty string if sideBySide prop is falsy to not get undefined as classname. If we use sideBySide && styles.sideBySide we get undefined as className
    <div
      data-testid="paragraph"
      className={`${styles.container} ${sideBySide ? styles.sideBySide : ''}`}
    >
      <h2>{title}</h2>
      <p>{text}</p>
    </div>
  );
};
