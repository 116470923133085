import { Slider } from 'antd';
import * as React from 'react';

import * as styles from './ValuesSlider.module.less';

interface IValuesSliderProps {
  product: string;
  min: number;
  max: number;
  value: number;
  onChange: (value: number) => void;
}

export const ValuesSlider: React.FunctionComponent<IValuesSliderProps> = (
  props
) => {
  return (
    <div className={styles.sliderContainer}>
      <div className={styles.buttonContainer}>
        <button
          className={styles.rightControlButton}
          onClick={() => {
            const value =
              props.value - 1 < props.min ? props.min : props.value - 1;
            props.onChange(value);
          }}
        >
          -
        </button>
      </div>

      <div className={styles.sliderWrapper}>
        <Slider
          min={props.min}
          max={props.max}
          onChange={props.onChange}
          value={props.value}
        />
      </div>

      <div className={styles.buttonContainer}>
        <button
          className={styles.leftControlButton}
          onClick={() => {
            const value =
              props.value + 1 > props.max ? props.max : props.value + 1;
            props.onChange(value);
          }}
        >
          +
        </button>
      </div>
      <div className={styles.sliderText} data-testid="slider-text">
        {props.value} {props.product}
      </div>
    </div>
  );
};
