import * as React from 'react';

import {
  CardsContainer,
  ICardProps,
  IInfoLink,
  IMenuLink,
} from '../components';
import { TagsFilter } from '../components/TagsFilter';

// to color the last word of a title
export const ColorTitle = (text: string): JSX.Element => {
  let newTitle;
  let coloredWord;
  if (text.split(' ').length > 1) {
    newTitle = text.substring(0, text.lastIndexOf(' '));
    coloredWord = text.substring(text.lastIndexOf(' '));
  } else {
    newTitle = text;
  }

  if (coloredWord) {
    return (
      <>
        {newTitle} <span>{coloredWord}</span>
      </>
    );
  } else return newTitle;
};

export function trackWidgetStep(widget: string, step: number): void {
  if (typeof gtag === 'function') {
    gtag('event', 'widget_step', {
      event_category: widget,
      event_label: `step ${step}`,
    });
  }
}

export function trackFormSuccess(
  event: 'New-Widget-Fenster' | 'New-Widget-Türen' | 'Absenden Katalog-Formular'
): void {
  if (typeof gtag === 'function') {
    gtag('event', event);
  }
}

export const FilteredElements = (elements: ICardProps[]): JSX.Element => {
  const tags: string[] = elements
    .flatMap((referenceInfo) => referenceInfo.tags) // Array<Array<{title: string} | null>> => Array<{title: string} | null>
    .reduce<string[]>((acc, tag) => {
      if (tag) return [...acc, tag.title];
      return acc;
    }, []); // => Array<string>
  const uniqueTags = [...new Set(tags)]; // remove duplicates
  const tagsList = uniqueTags.map((tag) => ({ label: tag, value: tag }));
  return (
    <TagsFilter tagsList={tagsList}>
      {(selectedTags) => {
        const filteredElements: ICardProps[] = selectedTags.includes('all')
          ? elements
          : elements.filter((element) => {
            const elementTags = element.tags?.map((tag) => tag.title);
            return elementTags
              ?.map((tag) => selectedTags.includes(tag))
              .some((i) => i);
          });
        return <CardsContainer elements={filteredElements} />;
      }}
    </TagsFilter>
  );
};

// Function that format the MenuLinks with the correct Urls depending on the language of each page
export function formatMenuLinks(
  categories: IMenuLink[],
  language: 'De' | 'En'
): IMenuLink[] {
  return categories.map((node) => {
    return {
      ...node,
      slug: `${language === 'De' ? '/' : '/en/'}${node.slug}/`,
      subPages: node.subPages?.map((subPage) => {
        return {
          ...subPage,
          slug: `${language === 'De' ? '/' : '/en/'}${node.slug}/${subPage.slug
            }/`,
        };
      }),
    };
  });
}

export function formatInfoLinks(
  infoLinks: IInfoLink[],
  language: 'De' | 'En'
): IInfoLink[] {
  return infoLinks.map((node) => {
    return {
      ...node,
      url: `${language === 'De' ? '/' : '/en/'}${node.url}/`,
    };
  });
}