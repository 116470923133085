import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import * as React from 'react';

import { ICustomGatsbyImageData } from '../../../utils/types';

import * as styles from './LargeCard.module.less';

interface ILargeCardProps {
  image: ICustomGatsbyImageData;
}

export const LargeCard = ({ image }: ILargeCardProps): JSX.Element => {
  const featuredImage = getImage(image.gatsbyImageData);
  return (
    <div className={styles.container}>
      {featuredImage && (
        <GatsbyImage image={featuredImage} alt={image?.title} />
      )}
      <div className={styles.content}>
        <h3>{image?.title}</h3>
        <p>{image?.description}</p>
      </div>
    </div>
  );
};
