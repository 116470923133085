import * as React from 'react';

import { LanguageContext } from '../../context/LanguageContext';

import { translation } from './translation';

export function useTranslate(): { t: (id: string) => string } {
  const language = React.useContext(LanguageContext);

  const t = (id: string): string => {
    return translation[id][language];
  };

  return { t };
}
