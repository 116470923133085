import { Breadcrumb } from 'antd';
import { Link as GatsbyLink } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import * as React from 'react';

import { LanguageContext } from '../../../context/LanguageContext';
import { ICustomGatsbyImageData } from '../../../utils/types';

import * as styles from './SmallHero.module.less';

export interface ISmallHeroProps {
  title: string;
  subheading?: string;
  description?: string;
  featuredImage?: ICustomGatsbyImageData;
  location?: {
    pathname: string;
  };
}

export const SmallHero = ({
  title,
  subheading,
  description,
  featuredImage,
  location,
}: ISmallHeroProps): React.ReactElement => {
  // to color the last word
  const isLongTitile = title.split(' ').length > 1;
  const newTitle = title.substring(0, title.lastIndexOf(' '));
  const coloredWord = title.substring(title.lastIndexOf(' '));
  const path = location?.pathname
    ?.split('/')
    .filter((el) => el !== '' && el !== 'en');
  const image = featuredImage && getImage(featuredImage.gatsbyImageData);
  const language = React.useContext(LanguageContext);
  const homePageUrl = `${language === 'En' ? '/en' : '/'}`;

  return (
    <div className={styles.container} data-testid="smallHero">
      <div className={styles.wrapper}>
        {image && <GatsbyImage image={image} alt={featuredImage.title} />}
        {image && <div className={styles.imageOverlay}></div>}
        <h1 className={styles.title}>
          {isLongTitile ? newTitle : title}
          {isLongTitile && (
            <span className={styles.coloredWord}>{coloredWord}</span>
          )}
        </h1>
      </div>
      {path && (
        <Breadcrumb className={styles.breadcrumb} separator="|">
          <Breadcrumb.Item className={styles.homePageItem}>
            <GatsbyLink to={homePageUrl}></GatsbyLink>
          </Breadcrumb.Item>
          {React.Children.toArray(
            path.map((el, index) => (
              <Breadcrumb.Item
                className={`${styles.breadcrumbItem} ${index === path.length - 1 ? styles.currentPageItem : ''
                  }`}
              >
                {index === path.length - 1 ? (
                  <span>{el.replace(new RegExp('-', 'g'), ' ')}</span>
                ) : (
                  <GatsbyLink to={`${homePageUrl}${el}/`}>
                    {el.replace(new RegExp('-', 'g'), ' ')}
                  </GatsbyLink>
                )}
              </Breadcrumb.Item>
            ))
          )}
        </Breadcrumb>
      )}

      {subheading && description && (
        <div className={styles.descriptionContainer}>
          <h2 className={styles.subheading}>{subheading}</h2>
          <p className={styles.description}>{description}</p>
        </div>
      )}
      <span className={styles.logo} />
    </div>
  );
};
