import * as React from 'react';

import * as styles from './IconGrid.module.less';

interface IIconGridProps {
  title: string;
  elements: {
    icon: { title: string; file: { url: string } };
    title: string;
    description: {
      description: string;
    };
  }[];
}

export const IconGrid = ({
  title,
  elements,
}: IIconGridProps): React.ReactElement => {
  const newTitle = title.substring(0, title.lastIndexOf(' '));
  const coloredWord = title.substring(title.lastIndexOf(' '));

  return (
    <div className={styles.container} data-testid="iconGrid">
      <h2>
        {newTitle}
        <span className={styles.coloredWord}>{coloredWord}</span>
      </h2>
      <div className={styles.itemWrapper}>
        {React.Children.toArray(
          elements.map(({ title, icon, description }) => (
            <div className={styles.item}>
              <img src={icon?.file?.url} alt={icon?.title} loading="lazy" />
              <h3>{title}</h3>
              <p>{description?.description}</p>
            </div>
          ))
        )}
      </div>
    </div>
  );
};
