import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import * as React from 'react';

import { Card, ImageTile, LargeCard, VideoCard } from '..';
import { IContentfulSliderBlock } from '../../utils/fragments/sliderBlockFragment';

export const sliderElementRenderer = (
  item: IContentfulSliderBlock
):
  | (React.ReactChild | React.ReactFragment | React.ReactPortal)[]
  | undefined => {
  switch (item.elementType) {
    case 'Images':
      return React.Children.toArray(
        item.images?.map((image) => {
          const featuredImage = getImage(image.gatsbyImageData);
          return (
            featuredImage && (
              <GatsbyImage
                image={featuredImage}
                alt={image.title}
                imgStyle={{ aspectRatio: `${image.resize?.aspectRatio}` }}
              />
            )
          );
        })
      );

    case 'Cards':
      return React.Children.toArray(
        item.elements
          ?.map((element) =>
            'image' in element ? (
              <Card
                {...element}
                internalLink={{
                  title: element.internalLink?.title,
                  url: `/${element.internalLink?.url}`,
                }}
              />
            ) : null
          )
          .filter(Boolean)
      );

    case 'Video Cards':
      return React.Children.toArray(
        item.elements
          ?.map((element) =>
            'videoId' in element ? <VideoCard {...element} /> : null
          )
          .filter(Boolean)
      );

    case 'Tiles':
      return React.Children.toArray(
        item.elements
          ?.map((element) =>
            'image' in element && element.image ? (
              <ImageTile
                image={element.image}
                title={element.title}
                link={`/${element.internalLink?.url}`}
              />
            ) : null
          )
          .filter(Boolean)
      );
    case 'Images with description':
      return React.Children.toArray(
        item.images?.map((image) => {
          return <LargeCard image={image} />;
        })
      );
  }
};
