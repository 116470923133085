import { Avatar } from 'antd';
import { StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';

import * as styles from './WidgetFormQuote.module.less';

export const WidgetFormQuote: React.FunctionComponent = () => {
  return (
    <div className={styles.container}>
      <div>
        <Avatar
          size={{ xs: 59, sm: 59, md: 90, lg: 102, xl: 102, xxl: 102 }}
          src={
            <StaticImage
              src="./img/eduard-appelhans.jpeg"
              alt="eduard-appelhans"
            />
          }
        />
      </div>
      <div className={styles.textContainer}>
        <p className={styles.quote}>
          "Als Geschäftsführer stehe ich persönlich für die hohe Qualität
          unserer Produkte und die Expertise unserer Mitarbeiter ein."
        </p>
        <span>EDUARD APPELHANS,</span>
        <span>Geschäftsführer</span>
      </div>
    </div>
  );
};
