import Cookies from 'js-cookie';
import * as React from 'react';

import { Button } from '..';
import { trackFormSuccess, trackWidgetStep } from '../../utils/helpers';
import { WidgetFormQuote } from '../WidgetFormQuote';
import { WidgetSteps } from '../WidgetSteps';
import { WidgetSuccessMessage } from '../WidgetSuccessMessage';

import * as styles from './FensterTurenWidget.module.less';

import {
  AmountOfWindows,
  Contstruction,
  GlazingBars,
  Material,
  PersonalInformation,
  Property,
  RenovationNewBuilding,
  ShippingDate,
} from '.';

export const FensterWidget: React.FunctionComponent = () => {
  const [buildingType, setBuildingType] = React.useState('');
  const [importantWindows, setImportantWindows] = React.useState<string[]>([]);
  const [windowsMaterial, setWindowsMaterial] = React.useState('');
  const [specialWindow, setSpecialWindow] = React.useState<string[]>([]);
  const [glazingBars, setGlazingBars] = React.useState('');
  const [numberOfWindows, setNumberOfWindows] = React.useState(2);
  const [executionPeriod, setExecutionPeriod] = React.useState('');

  const [activeStep, setActiveStep] = React.useState(1);
  const [submitted, setSubmitted] = React.useState(false);

  React.useEffect(() => {
    trackWidgetStep('fenster', activeStep);
    window.location.hash = `step${activeStep}`;
  }, [activeStep]);

  const numberOfSteps = 7;

  const windowSteps = {
    1: buildingType,
    2: importantWindows,
    3: windowsMaterial,
    4: specialWindow,
    5: glazingBars,
    6: numberOfWindows,
    7: ShippingDate,
  };

  const windowsScreens = {
    1: (
      <RenovationNewBuilding
        onSelect={setBuildingType}
        selectedItem={buildingType}
      />
    ),
    2: (
      <Property
        onSelect={setImportantWindows}
        selectedItems={importantWindows}
      />
    ),
    3: (
      <Material
        text="Aus welchem Material sollen Ihre Fenster und Türen sein?"
        onSelect={setWindowsMaterial}
        selectedItem={windowsMaterial}
      />
    ),
    4: (
      <Contstruction onSelect={setSpecialWindow} selectedItem={specialWindow} />
    ),
    5: <GlazingBars onSelect={setGlazingBars} selectedItem={glazingBars} />,
    6: (
      <AmountOfWindows
        product="Fenster"
        value={numberOfWindows}
        onValueChange={setNumberOfWindows}
      />
    ),
    7: (
      <ShippingDate
        product="Fenster"
        onSelect={setExecutionPeriod}
        selectedItem={executionPeriod}
      />
    ),
  };

  const listOfChoices = [
    { name: 'Gebäudeart', value: buildingType },
    { name: 'Wichtig', value: importantWindows.join(', ') },
    { name: 'Material', value: windowsMaterial },
    { name: 'Spezialfenster', value: specialWindow.join(', ') },
    { name: 'Sprossen', value: glazingBars },
    { name: 'Anzahl', value: numberOfWindows },
    { name: 'Ausführungszeitraum', value: executionPeriod },
  ];

  const handleFinish = (values) => {
    fetch('/.netlify/functions/SubmitWidget', {
      method: 'POST',
      body: JSON.stringify({
        ...values,
        formName: 'Fenster',
        gclid: Cookies.get('gclid'),
        details: listOfChoices.reduce(
          (acc, curr) => ({
            ...acc,
            [curr.name]: curr.value,
          }),
          {}
        ),
      }),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.statusText);
        } else {
          setSubmitted(true);
          trackFormSuccess('New-Widget-Fenster');
          return res;
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const formSection = (
    <PersonalInformation
      onFormSubmit={handleFinish}
      listOfChoices={listOfChoices}
    />
  );

  const showPrevButton = activeStep !== 1;
  const showNextButton = activeStep <= numberOfSteps;

  const buttonsBlock = (
    <div className={styles.buttonsBlock}>
      {showPrevButton && (
        <Button
          variant="secondary"
          onClick={() => {
            setActiveStep((currentStep) => currentStep - 1);
          }}
        >
          Zurück
        </Button>
      )}
      {showNextButton && (
        <Button
          variant="primary"
          onClick={() => {
            const isMultipleSelectionStep = activeStep === 2;

            const setepValue = windowSteps[activeStep];

            const stepHasValue = isMultipleSelectionStep
              ? setepValue.length !== 0
              : setepValue !== '';

            if (!stepHasValue) return;

            setActiveStep((currentStep) => currentStep + 1);
          }}
        >
          Weiter
        </Button>
      )}
    </div>
  );

  return (
    <section
      className={`${styles.container} ${
        activeStep == 8 ? styles.fullWidth : ''
      }`}
    >
      <div>
        {activeStep !== 8 && (
          <WidgetSteps activeStep={activeStep} numberOfSteps={numberOfSteps} />
        )}
        {windowsScreens[activeStep]}
        {activeStep === 8 && !submitted && formSection}
        {submitted && <WidgetSuccessMessage />}
        {activeStep !== 8 && buttonsBlock}
      </div>
      {activeStep == 8 && <WidgetFormQuote />}
    </section>
  );
};
