import * as React from 'react';

import * as styles from './Table.module.less';

interface ITableProps {
  title?: string;
  data: string;
  /**
   * To apply custom css to the component
   */
  className?: string;
}

export const Table = ({
  data,
  title,
  className,
}: ITableProps): React.ReactElement => {
  return (
    <div
      className={`${title ? styles.withTitle : ''} ${
        className ? className : ''
      }`}
      data-testid="table"
    >
      {title && <h2>{title}</h2>}
      <div
        className={`${styles.wrapper}`}
        dangerouslySetInnerHTML={{ __html: data }}
      />
    </div>
  );
};
