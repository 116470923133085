import * as React from 'react';

import * as styles from './ListCard.module.less';

interface IListCardProps {
  title: string;
  list: { name: string; value: string }[];
}

export const ListCard: React.FunctionComponent<IListCardProps> = (props) => {
  return (
    <div className={styles.listCardContainer}>
      <h3>{props.title}</h3>
      <ul>
        {props.list.map(({ name, value }) => (
          <li key={name}>
            <b>{name}</b>
            <br />
            {value}
          </li>
        ))}
      </ul>
    </div>
  );
};
