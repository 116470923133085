// extracted by mini-css-extract-plugin
export var container = "Testimonial-module--container--4TM2i";
export var almondContainer = "Testimonial-module--almondContainer--3RA4t";
export var subContainer = "Testimonial-module--subContainer--_eXv3";
export var almondSub = "Testimonial-module--almondSub--2Y4_F";
export var content = "Testimonial-module--content--3nYjv";
export var almondContent = "Testimonial-module--almondContent--3EGRb";
export var personalInfos = "Testimonial-module--personalInfos--fCdBt";
export var almondPersonalInfos = "Testimonial-module--almondPersonalInfos--1s8NG";
export var faded = "Testimonial-module--faded--2-xk2";
export var selectedImage = "Testimonial-module--selectedImage--niC1r";
export var reviewImage = "Testimonial-module--reviewImage--16n5q";