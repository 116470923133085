import * as React from 'react';

import { WidgetCardsContainer } from '../../WidgetCardsContainer';
import * as styles from '../FensterTurenWidget.module.less';
import iconOldBuilding from '../img/pictogram-altbau-64.svg';
import iconNewBuilding from '../img/pictogram-neubau-64.svg';

interface IRenovationNewBuildingProps {
  onSelect: (element: string) => void;
  selectedItem: string;
}

export const RenovationNewBuilding: React.FunctionComponent<IRenovationNewBuildingProps> = ({
  onSelect,
  selectedItem,
}) => {
  const onItemClick = (titles: string[]) => {
    onSelect(titles[0] || '');
  };

  return (
    <div>
      <div className={styles.question}>
        Bauen Sie neu oder modernisieren Sie?
      </div>
      <WidgetCardsContainer
        elements={[
          {
            title: 'Neubau',
            icon: iconNewBuilding,
            selected: 'Neubau' === selectedItem,
          },
          {
            title: 'Altbau',
            icon: iconOldBuilding,
            selected: 'Altbau' === selectedItem,
          },
        ]}
        selectedItems={selectedItem ? [selectedItem] : []}
        setSelectedItems={onItemClick}
      />
    </div>
  );
};
