import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import * as React from 'react';

import { ICustomGatsbyImageData } from '../../../utils/types';
import { Link } from '../../Clickable';

import * as styles from './HomeHero.module.less';

interface IHomeHeroProps {
  title: string;
  subTitle?: string;
  link?: {
    title: string;
    url: string;
  };
  image?: ICustomGatsbyImageData;
}

export const HomeHero = ({
  title,
  subTitle = '',
  link,
  image,
}: IHomeHeroProps): JSX.Element => {
  // to color the last word
  const newTitle = title.substring(0, title.lastIndexOf(' '));
  const coloredWord = title.substring(title.lastIndexOf(' '));
  const featuredImage = image && getImage(image.gatsbyImageData);

  return (
    <div className={styles.container} data-testid="homeHero">
      <div className={styles.overlayContainer}>
        <div className={styles.overlay} />
        {featuredImage && image && (
          <GatsbyImage
            image={featuredImage}
            alt={image.title}
            data-testid="hero-image"
          />
        )}
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.content}>
          <h2>{subTitle}</h2>
          <h1>
            {newTitle}
            <span>{coloredWord}</span>
          </h1>
          {link && (
            <Link href={link.url} className={styles.link} isDecorated>
              {link.title}
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};
