import * as React from 'react';

import { TrustElements } from '..';
import { ICustomGatsbyImageDataAsset } from '../../utils/types';

import * as styles from './WidgetTrustElements.module.less';
import checkMark from './img/checkmark-grey.png';

interface IWidgerTrustElements {
  elements: ICustomGatsbyImageDataAsset[];
}

export const WidgetTrustElements = ({
  elements,
}: IWidgerTrustElements): JSX.Element => {
  return (
    <div className={styles.container}>
      <ul>
        <li>
          <img src={checkMark} alt="check-mark" />
          Tradition seit 1880
        </li>
        <li>
          <img src={checkMark} alt="check-mark" />
          Fenster & Türen nach Maß
        </li>
        <li>
          <img src={checkMark} alt="check-mark" />
          Produziert in Deutschland
        </li>
      </ul>
      <TrustElements elements={elements} />
    </div>
  );
};
