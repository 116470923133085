import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import * as React from 'react';

import { ICustomGatsbyImageData } from '../../../utils/types';

import * as styles from './ImageTile.module.less';
import plusIcon from './plus-icon.svg';

interface IImageTileProps {
  image: ICustomGatsbyImageData;
  title: string;
  link?: string;
  onClick?: () => void;
}

export const ImageTile: React.FunctionComponent<IImageTileProps> = (props) => {
  const image = getImage(props.image.gatsbyImageData);

  return image ? (
    props.link ? (
      <Link
        className={styles.container}
        to={props?.link}
        data-testid="image-tile-container"
      >
        <GatsbyImage image={image} alt={props.image.title} />
        <div>
          <span className={styles.title}>{props.title}</span>
          <img src={plusIcon} alt="" />
        </div>
      </Link>
    ) : (
      <div
        className={styles.container}
        onClick={props.onClick}
        data-testid="image-tile-container"
      >
        <GatsbyImage image={image} alt={props.image.title} />
        <div>
          <span className={styles.title}>{props.title}</span>
          <img src={plusIcon} alt="" />
        </div>
      </div>
    )
  ) : null;
};
