import * as React from 'react';

import { Link } from '../../Clickable';
import * as styles from '../shared/card.module.less';

import playIcon from './img/play-icon.svg';

export interface IVideoCardProps {
  videoId: string;
  title: string;
  description: { description: string } | null;
  slug?: string;
  internalLink: { title: string; url: string } | null;
  setVisible?: (state: boolean) => void;
  setVideoId?: (id: string) => void;
}

export const VideoCard = ({
  videoId,
  title,
  description,
  internalLink,
  setVisible,
  setVideoId,
}: IVideoCardProps): JSX.Element => {
  const thumbnail = `https://i3.ytimg.com/vi_webp/${videoId}/sddefault.webp`;

  return (
    <div className={styles.container} data-testid="video-card">
      <div className={styles.image}>
        <img src={thumbnail} alt={title} loading="lazy" />
        {/* This div is to provide a black overlay over the thumbnail  */}
        <div className={styles.imageOverlay}>
          <img
            src={playIcon}
            alt=""
            onClick={() => {
              if (setVisible && setVideoId) {
                setVisible(true);
                setVideoId(videoId);
              }
            }}
          />
        </div>
      </div>

      <div className={styles.contentContainer}>
        {title && <h3 className={styles.titleWithMargin}>{title}</h3>}
        <p>{description?.description}</p>
        <Link
          href={
            internalLink
              ? `/${internalLink.url}`
              : `https://youtu.be/${videoId}`
          }
          isDecorated
          isExternal={!internalLink}
        >
          {internalLink ? internalLink.title : title}
        </Link>
      </div>
    </div>
  );
};
