import * as React from 'react';

import { WidgetCardsContainer } from '../../WidgetCardsContainer';
import * as styles from '../FensterTurenWidget.module.less';
import iconDoor from '../img/pictogram-tur-64.svg';
import iconDoorWindow from '../img/pictogram-tur-fenster-64.svg';

interface IQuestionScreenProps {
  onSelect: (element: string) => void;
  selectedItem: string;
  onYesClick: () => void;
  onNoClick: () => void;
}

export const QuestionScreen: React.FunctionComponent<IQuestionScreenProps> = ({
  onSelect,
  selectedItem,
  onYesClick,
  onNoClick,
}) => {
  const onItemClick = (titles: string[]) => {
    onSelect(titles[0] || '');
  };
  return (
    <div>
      <div className={styles.question}>Benötigen Sie auch Fenster?</div>
      <WidgetCardsContainer
        elements={[
          {
            title: 'Ja, auch Fenster',
            icon: iconDoorWindow,
            selected: 'Ja, auch Fenster' === selectedItem,
            onClick: onYesClick,
          },
          {
            title: 'Nein, nur eine Tür',
            icon: iconDoor,
            selected: 'Nein, nur eine Tür' === selectedItem,
            onClick: onNoClick,
          },
        ]}
        selectedItems={selectedItem ? [selectedItem] : []}
        setSelectedItems={onItemClick}
      />
    </div>
  );
};
