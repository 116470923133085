import * as React from 'react';

import { Link } from '..';

import * as styles from './WidgetSuccessMessage.module.less';
import icon from './img/success-icon.svg';

export const WidgetSuccessMessage = (): JSX.Element => {
  return (
    <div className={styles.container}>
      <img src={icon} alt="succes-icon" />
      <h2>
        Das hat geklappt <br />
        Vielen Dank für Ihre Anfrage!
      </h2>
      <p>
        Einer unserer Fachleute wird sich so schnell wie möglich telefonisch
        oder per E-Mail mit Ihnen in Verbindung setzen.
      </p>
      <Link href="/" variant="primary">
        Home
      </Link>
    </div>
  );
};
