// extracted by mini-css-extract-plugin
export var sliderContainer = "Slider-module--sliderContainer--1gJIF";
export var decoratedContainer = "Slider-module--decoratedContainer--3WJwh";
export var content = "Slider-module--content--J0Oz5";
export var title = "Slider-module--title--1afoX";
export var upperCase = "Slider-module--upperCase--2Kbwo";
export var medium = "Slider-module--medium--2nKtd";
export var large = "Slider-module--large--1ur27";
export var xlarge = "Slider-module--xlarge--3BIAZ";
export var decoratedTitle = "Slider-module--decoratedTitle--SwHO6";
export var intersected = "Slider-module--intersected--jz6wI";