import * as React from 'react';

import { WidgetCardsContainer } from '../../WidgetCardsContainer';
import * as styles from '../FensterTurenWidget.module.less';
import iconConsultation from '../img/pictogram-beratung-64.svg';
import iconWood from '../img/pictogram-wood-64.svg';
import iconWoodAlu from '../img/pictogram-wood-alu-64.svg';

interface IMaterialProps {
  text: string;
  onSelect: (element: string) => void;
  selectedItem: string;
}

export const Material: React.FunctionComponent<IMaterialProps> = ({
  text,
  onSelect,
  selectedItem,
}) => {
  const onItemClick = (titles: string[]) => {
    onSelect(titles[0] || '');
  };

  return (
    <div>
      <div className={styles.question}>{text}</div>
      <WidgetCardsContainer
        elements={[
          {
            title: 'Holz',
            icon: iconWood,
            selected: 'Holz' === selectedItem,
          },
          {
            title: 'HolzAlu',
            icon: iconWoodAlu,
            selected: 'HolzAlu' === selectedItem,
          },
          {
            title: 'suche Beratung',
            icon: iconConsultation,
            selected: 'suche Beratung' === selectedItem,
          },
        ]}
        selectedItems={selectedItem ? [selectedItem] : []}
        setSelectedItems={onItemClick}
      />
    </div>
  );
};
