import { Tag } from 'antd';
import * as React from 'react';

import * as styles from './TagsFilter.module.less';

const { CheckableTag } = Tag;

interface ITagsList {
  label: string;
  value: string;
}

interface ITagsFilterProps {
  tagsList: ITagsList[];
  allLabel?: string;
  children: (selectedTags: string[]) => JSX.Element;
}

export const TagsFilter: React.FunctionComponent<ITagsFilterProps> = (
  props
) => {
  const allLabel = props.allLabel || 'Alle';
  const allValue = 'all';
  const [checkedTags, setCheckedTags] = React.useState([allValue]);

  return (
    <>
      <div className={styles.container} data-testid="tagsFilter">
        {[{ label: allLabel, value: allValue }, ...props.tagsList].map(
          (tag) => (
            <CheckableTag
              key={tag.value}
              checked={checkedTags.includes(tag.value)}
              onChange={(checked) => {
                if (checked) {
                  setCheckedTags((state) =>
                    tag.value === allValue
                      ? [allValue]
                      : [...state, tag.value].filter((t) => t !== allValue)
                  );
                } else {
                  setCheckedTags((state) => {
                    if (state.length === 1) return ['all'];
                    return state.filter((t) => t !== tag.value);
                  });
                }
              }}
            >
              {tag.label}
            </CheckableTag>
          )
        )}
      </div>
      {props.children(checkedTags)}
    </>
  );
};
