import * as React from 'react';

import * as styles from '../shared/clickable.module.less';

interface IButtonProps {
  variant: 'primary' | 'secondary';
  isDecorated?: boolean;
  children: string;
  onClick?: React.MouseEventHandler;
  type?: 'button' | 'reset' | 'submit';
  isAnchor?: boolean;
  href?: string;
}

export const Button = ({
  variant = 'primary',
  isDecorated,
  children,
  onClick,
  type = 'button',
  isAnchor = false,
  href,
}: IButtonProps & React.HTMLProps<HTMLButtonElement>): React.ReactElement => {
  if (isAnchor)
    return (
      <a
        className={`${styles.clickable} ${styles[variant]} ${isDecorated ? styles.decorated : ''
          }`}
        href={href}
      >
        {children}
      </a>
    );

  return (
    <button
      type={type}
      className={`${styles.clickable} ${styles[variant]} ${isDecorated ? styles.decorated : ''
        }`}
      onClick={onClick && onClick}
    >
      {children}
    </button>
  );
};
