import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import * as React from 'react';

import { ICustomGatsbyImageData } from '../../../utils/types';

import * as styles from './NumberedCard.module.less';

export interface INumberedCardProps {
  title: string;
  description: {
    description: string;
  };
  image: ICustomGatsbyImageData;
  number?: number;
}

export const NumberedCard = ({
  title,
  image,
  description,
  number,
}: INumberedCardProps): React.ReactElement => {
  const featuredImage = getImage(image.gatsbyImageData);
  return (
    <div className={styles.container}>
      <div data-number={number}>
        {featuredImage && (
          <GatsbyImage image={featuredImage} alt={image.title} />
        )}
      </div>
      <h3>{title}</h3>
      <p>{description?.description}</p>
    </div>
  );
};
