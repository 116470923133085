export const translation: {
  [id: string]: {
    De: string;
    En: string;
  };
} = {
  hello: {
    De: 'hallo',
    En: 'hello',
  },
  international: {
    De: 'International',
    En: 'German',
  },
  contact: {
    De: 'Kontaktieren Sie uns',
    En: 'Contact us',
  },
  menu: {
    De: 'Menü',
    En: 'Menu',
  },
  question: {
    De: 'Fragen?',
    En: 'Questions?',
  },
  clock: {
    De: 'Uhr',
    En: 'Clock',
  },
  cookie: {
    De: 'Cookie-Einstellungen',
    En: 'Cookie Settings',
  },
  catalogTitle: {
    De: 'Unseren Katalog bestellen',
    En: 'Order our catalogue',
  },
  catalogDescription: {
    De:
      'Erhalten Sie unseren Gesamtkatalog mit vielen Inspirationen kostenfrei per Post oder auch per E-Mail zugeschickt.',
    En:
      'Receive our complete catalogue, with lots of inspiration, free of charge by post or email.',
  },
  printCatalogTitle: {
    De: 'Print Katalog',
    En: 'Print Catalogue',
  },
  digitalCatalogTitle: {
    De: 'Digitaler Katalog',
    En: 'Digital Catalogue',
  },
  catalogMobilePanelTitle: {
    De: 'Katalog jetzt anfordern',
    En: 'Request catalog now',
  },
  deliveryDates: {
    De: 'liefertermine',
    En: 'Delivery Dates',
  },
  wood: {
    De: 'holz',
    En: 'wood',
  },
  aluminum: {
    De: 'alu',
    En: 'aluminum',
  },
  fireresistance: {
    De: 'Brandschutzfenster',
    En: 'fireresistant window',
  },
  requiredFieldsReminder: {
    De: 'Pflichtfelder bitte ausfüllen',
    En: 'Please fill out mandatory fields',
  },
  salutation: {
    De: 'Anrede',
    En: 'Salutation',
  },
  emailAddress: {
    De: 'E-Mail-Adresse',
    En: 'E-Mail-Address',
  },
  woman: {
    De: 'Frau',
    En: 'Woman',
  },
  man: {
    De: 'Herr',
    En: 'Man',
  },
  firstname: {
    De: 'Vorname',
    En: 'First name',
  },
  lastname: {
    De: 'Nachname',
    En: 'Last name',
  },
  company: {
    De: 'Unternehmen',
    En: 'Company',
  },
  postCode: {
    De: 'PLZ',
    En: 'PostCode',
  },
  phone: {
    De: 'Telefon',
    En: 'Phone',
  },
  street: {
    De: 'Straße',
    En: 'Street',
  },
  houseNumber: {
    De: 'Hausnummer',
    En: 'House number',
  },
  location: {
    De: 'Ort',
    En: 'Location',
  },
  comment: {
    De: 'Kommentar',
    En: 'Comment',
  },
  numberMessage: {
    De: 'Bitte nur Nummern eingeben!',
    En: 'Please only enter numbers!',
  },
  companyMessage: {
    De: 'Bitte geben Sie Ihren Unternehmen ein!',
    En: 'Please enter your company!',
  },
  genderMessage: {
    De: 'Bitte wählen Sie Ihr Geschlecht!',
    En: 'Please choose your gender!',
  },
  firstNameMessage: {
    De: 'Bitte geben Sie Ihren Vornamen ein!',
    En: 'Please enter your first name!',
  },
  lastNameMessage: {
    De: 'Bitte geben Sie Ihren Nachnamen ein!',
    En: 'Please enter your address!',
  },
  streetMessage: {
    De: 'Bitte geben Sie Ihre Adresse ein!',
    En: 'Please enter your address!',
  },
  houseNumberMessage: {
    De: 'Bitte geben Sie Ihre Hausnummer ein!',
    En: 'Please enter your house number!',
  },
  postCodeMessage: {
    De: 'Bitte geben Sie Ihre PLZ ein!',
    En: 'Please enter your postcode!',
  },
  postCodeMessage1: {
    De: 'Bitte geben Sie Ihre Postleitzahl ein!',
    En: 'Please enter your zip code!',
  },
  locationMessage: {
    De: 'Bitte geben Sie Ihre Ort ein!',
    En: 'Please enter your location!',
  },
  emptyEmailMessage: {
    De: 'Bitte geben Sie Ihre E-Mail Adresse ein!',
    En: 'Please enter your e-mail address!',
  },
  validEmailMessage: {
    De:
      'Bitte geben Sie eine gültige E-Mail Adresse ein (IE:example@gmail.com)',
    En: 'Please enter a valid email address (IE:example@gmail.com)',
  },
  privacyPolicyMessage: {
    De:
      'Bitte überprüfen Sie die Datenschutzerklärung und stimmen Sie der Datenverarbeitung zu!',
    En: 'Please check the privacy policy and agree to the data processing!',
  },
  acceptPrivarcyPolicyMsg1: {
    De: 'Ich habe die',
    En: 'I have the',
  },
  acceptPrivarcyPolicyMsg2: {
    De: 'Datenschutzerklärung',
    En: 'Data protection',
  },
  acceptPrivarcyPolicyMsg3: {
    De: 'gelesen und willige in die Datenverarbeitung ein.',
    En: 'read and agree to the data processing.',
  },
  requestCatalog: {
    De: 'Katalog anfordern',
    En: 'Request catalogue',
  },
  errorMessage: {
    De: 'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut',
    En: 'There has been an error. Please try again later',
  },
  printSuccessMessage: {
    De:
      ' Vielen Dank für Ihr Interesse. Sie erhalten Ihren Katalog schnellstmöglich per Post von uns.',
    En:
      'Thanks for your interest. You will receive your catalog from us by post as soon as possible.',
  },
  digitalMessage: {
    De:
      'Vielen Dank für Ihr Interesse. Sie erhalten in Kürze eine E-Mail mit dem Downloadlink zu Ihrem Katalog.',
    En:
      'Thanks for your interest. You will shortly receive an email with the download link to your catalogue.',
  },
  pageNotFoundMessage: {
    De: 'ups! 404(seite)',
    En: 'oops! 404(page)',
  },
  pageNotFoundMessage1: {
    De: 'nicht gefunden',
    En: 'not found',
  },
  goToHome: {
    De: 'Zurück zur Startseite',
    En: 'Back to home page',
  },
  from: {
    De: 'von',
    En: 'from',
  },
  all: {
    De: 'Alle',
    En: 'All',
  },

  sendRequest: {
    De: 'Anfrage verschicken',
    En: 'Send Request',
  },
};
