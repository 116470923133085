import { INLINES } from '@contentful/rich-text-types';
import { Collapse, Tabs } from 'antd';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import * as React from 'react';

import { Table } from '../';
import arrowIcon from '../../images/arrow-icon.svg';
import { ICustomGatsbyImageData } from '../../utils/types';

import * as styles from './InfoTabs.module.less';

const { TabPane } = Tabs;
const { Panel } = Collapse;

export interface IInfoTabsProps {
  title: string;
  tabs: {
    title: string;
    elements: {
      title: string;
      textContent: { raw: string } | null;
      table: {
        content: {
          content: string;
        };
      } | null;
      smallText: string | null;
      cardsList:
        | {
            title: string;
            image: ICustomGatsbyImageData | null;
            tagList: { slug: string; title: string }[] | null;
            description: { description: string };
          }[]
        | null;
    }[];
  }[];
}

export const InfoTabs = ({ title, tabs }: IInfoTabsProps): JSX.Element => {
  const [activeKey, setActiveKey] = React.useState('0');

  const renderOptions = {
    renderNode: {
      [INLINES.HYPERLINK]: (node, children) => {
        const { uri } = node.data;
        return (
          <a href={uri} rel="noreferrer noopener">
            {children}
          </a>
        );
      },
    },
  };

  const onKeyChange = (key) => {
    setActiveKey(key);
  };

  const OperationsSlot = {
    left: parseInt(activeKey) !== 0 && (
      <button
        className={`${styles.extraButton} ${styles.rotated} `}
        aria-label="Left button"
        onClick={() => onKeyChange(String(parseInt(activeKey) - 1))}
      />
    ),
    right: parseInt(activeKey) !== tabs.length - 1 && (
      <button
        className={styles.extraButton}
        aria-label="Right button"
        onClick={() => onKeyChange(String(parseInt(activeKey) + 1))}
      />
    ),
  };

  return (
    <div className={styles.container} data-testid="infoTabs">
      <h2>{title}</h2>
      <Tabs
        className={styles.tabs}
        defaultActiveKey="1"
        activeKey={activeKey}
        onChange={onKeyChange}
        tabBarExtraContent={OperationsSlot}
        moreIcon={null}
      >
        {tabs.map(({ title, elements }, index) => (
          <TabPane tab={title} key={index}>
            <Collapse
              className={styles.accordion}
              accordion
              defaultActiveKey={['0']}
              expandIconPosition="right"
              expandIcon={({ isActive }) => (
                <img
                  className={`${isActive ? 'rotated' : ''}`}
                  src={arrowIcon}
                  alt="expand-icon"
                />
              )}
              ghost
            >
              {elements &&
                React.Children.toArray(
                  elements.map(
                    (
                      { title, textContent, table, smallText, cardsList },
                      index
                    ) => (
                      <Panel header={title} key={index}>
                        <div
                          className={
                            cardsList ? styles.cardContainer : styles.content
                          }
                        >
                          {React.Children.toArray(
                            cardsList?.map(
                              ({ title, image, tagList, description }) => {
                                const featuredImage =
                                  image && getImage(image.gatsbyImageData);
                                return (
                                  <div data-testid="card-item">
                                    {featuredImage && (
                                      <GatsbyImage
                                        image={featuredImage}
                                        alt={image.title}
                                      />
                                    )}

                                    <h4>{title}</h4>
                                    {tagList && (
                                      <ul>
                                        {tagList?.map(({ title, slug }) => (
                                          <li key={slug}>
                                            <span key={slug}>{title}</span>
                                          </li>
                                        ))}
                                      </ul>
                                    )}

                                    <p>{description?.description}</p>
                                  </div>
                                );
                              }
                            )
                          )}
                          {textContent &&
                            renderRichText(
                              { ...textContent, references: [] },
                              renderOptions
                            )}
                          {table && (
                            <Table
                              className={styles.table}
                              data={table.content.content}
                            />
                          )}
                          {smallText && <span>{smallText}</span>}
                        </div>
                      </Panel>
                    )
                  )
                )}
            </Collapse>
          </TabPane>
        ))}
      </Tabs>
    </div>
  );
};
