import * as React from 'react';

import { ListCard } from '../../ListCard';
import { IWidgetFormValues, WidgetForm } from '../../widgetForm';
import * as styles from '../FensterTurenWidget.module.less';

interface IPersonalInformationProps {
  listOfChoices: Array<{ name: string; value }>;
  onFormSubmit: (values: IWidgetFormValues) => void;
  showCompanyField?: boolean;
}

export const PersonalInformation: React.FunctionComponent<IPersonalInformationProps> = ({
  listOfChoices,
  onFormSubmit,
  showCompanyField,
}) => {
  return (
    <div className={styles.personalInformationContainer}>
      <div>
        <h2>
          Damit wir Ihnen passgenaue Informationen zu Ihrer Anfrage schicken
          können, benötigen wir zuletzt noch ausgewählte persönliche Angaben
        </h2>
        <ListCard list={listOfChoices} title="Ihre Auswahl:" />
      </div>
      <WidgetForm onSubmit={onFormSubmit} showComapnyField={showCompanyField} />
    </div>
  );
};
