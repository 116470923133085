import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import {
  RenderRichTextData,
  renderRichText,
} from 'gatsby-source-contentful/rich-text';
import * as React from 'react';

import {
  Accordion,
  Benefits,
  Button,
  Catalogue,
  Facts,
  Highlight,
  IconGrid,
  InfoTabs,
  Paragraph,
  PartnerImage,
  PreFooter,
  ProductDetails,
  Section,
  ServiceTools,
  Slider,
  Steps,
  Table,
  Team,
  Testimonial,
  TrustElements,
  Video,
  VideosContainer,
  isUrlOrMailTo,
  sliderElementRenderer,
} from '../';
import { IContentfulAsset } from '../../utils/fragments/assetFragment';
import { RichTextElements } from '../../utils/fragments/types';
import { FilteredElements } from '../../utils/helpers';
import {
  BrandschutzWidget,
  ESchiebefensterWidget,
} from '../BrandschutzESchiebefensterWidget';
import { FensterWidget, TurenWidget } from '../FensterTurenWidget';
import { MapBlock } from '../MapBlock';

import * as styles from './RichTextRender.module.less';

export const RichTextRender = ({
  content,
}: {
  // TODO: properly type this
  content: RenderRichTextData<RichTextElements>;
}): React.ReactElement => {
  // Create an array containing all the referenced entries and assets

  const assets = new Map(
    content?.references?.map((ref) => [ref.contentful_id, ref])
  );

  const renderOptions = {
    renderNode: {
      [BLOCKS.EMBEDDED_ENTRY]: (node) => {
        const asset = assets.get(node?.data?.target?.sys?.id);

        // Render the EMBEDDED_ENTRY
        if (!asset) return;
        switch (asset.__typename) {
          case 'ContentfulVideoBlock':
            // If the entry is a video
            return (
              <div id={asset.slug} className={styles.blockContainer}>
                <Video
                  id={asset.videoId}
                  details={{
                    title: asset.title,
                    description: asset.description?.description,
                  }}
                />
              </div>
            );
          case 'ContentfulReviewBlock':
            // If the entry is a testimonial
            return (
              <div id={asset.slug} className={styles.blockContainer}>
                <Testimonial
                  title={asset.title}
                  entry={asset.reviews}
                  isPartner={asset?.isPartner}
                />
              </div>
            );
          case 'ContentfulImageBlock':
            // If the entry is an image block
            return (
              <div
                id={asset.slug}
                className={styles.blockContainer}
                data-testid="imageBlock"
              >
                <div className={styles.imagesContainer}>
                  {React.Children.toArray(
                    asset.images.map((image) => {
                      const actualImage = getImage(image.gatsbyImageData);
                      return (
                        actualImage && (
                          <GatsbyImage
                            className={styles.image}
                            image={actualImage}
                            alt={image.title}
                          />
                        )
                      );
                    })
                  )}
                </div>
              </div>
            );
          case 'ContentfulTable':
            // If the entry is an image block
            if (asset.isCentered) {
              return (
                <div id={asset.slug} className={styles.blockContainer}>
                  <Table title={asset.title} data={asset.content.content} />
                </div>
              );
            } else {
              return <Table data={asset.content.content} />;
            }
          case 'ContentfulTextBlock':
            return (
              <div id={asset.slug} className={styles.blockContainer}>
                <Paragraph
                  title={asset.title}
                  text={asset.content.content}
                  sideBySide={asset.sideBySide}
                  key={asset.slug}
                />
              </div>
            );
          case 'ContentfulHighlight':
            return (
              <div id={asset.slug} className={styles.blockContainer}>
                <Highlight
                  sectionTitle={asset.subHeading || ''}
                  title={asset.title}
                  paragraph={asset.content.content}
                  link={
                    asset.link && {
                      url: asset.link.url,
                      text: asset.link.title,
                    }
                  }
                  img={asset.featuredImage}
                  orientation={asset.orientation}
                  teint={asset.teint}
                  key={asset.slug}
                />
              </div>
            );
          case 'ContentfulTrustBlock':
            return (
              <div id={asset.slug} className={styles.blockContainer}>
                <TrustElements elements={asset.elements} key={asset.slug} />
              </div>
            );
          case 'ContentfulSection':
            return (
              <div id={asset.slug} className={styles.blockContainer}>
                <Section
                  title={asset.title}
                  description={asset.description?.description}
                  links={asset.links}
                  image={asset.image}
                  sideBySide={asset.sideBySIde}
                  key={asset.slug}
                />
              </div>
            );
          case 'ContentfulBenefitsBlock':
            return (
              <div id={asset.slug} className={styles.blockContainer}>
                <Benefits
                  key={asset.slug}
                  title={asset.title}
                  elements={asset.elements}
                />
              </div>
            );
          case 'ContentfulTeamBlock':
            return (
              <div id={asset.slug} className={styles.blockContainer}>
                <Team
                  key={asset.slug}
                  title={asset.title}
                  members={asset.members}
                />
              </div>
            );
          case 'ContentfulStepsBlock':
            return (
              <div id={asset.slug} className={styles.blockContainer}>
                <Steps
                  key={asset.slug}
                  title={asset.title}
                  elements={asset.elements}
                />
              </div>
            );
          case 'ContentfulSliderBlock':
            return (
              <div id={asset.slug} className={styles.blockContainer}>
                <Slider
                  title={asset.title}
                  key={asset.slug}
                  titleSize={asset.titleSize}
                  paragraph={asset.paragraph?.paragraph}
                  titleIsUpperCase
                  titleIsDecorated
                >
                  {sliderElementRenderer(asset)}
                </Slider>
              </div>
            );
          case 'ContentfulPreFooterBlock':
            return (
              <div id={asset.slug} className={styles.blockContainer}>
                <PreFooter key={asset.slug} items={asset.items} />
              </div>
            );
          case 'ContentfulIconGridBlock':
          case 'ContentfulIconsGridBlock':
            return (
              <div id={asset.slug} className={styles.blockContainer}>
                <IconGrid
                  key={asset.slug}
                  title={asset.title}
                  elements={asset.elements}
                />
              </div>
            );
          case 'ContentfulAccordionBlock':
            return (
              <div id={asset.slug} className={styles.blockContainer}>
                <Accordion
                  key={asset.slug}
                  title={asset.title}
                  description={asset.description}
                  elements={asset.elements}
                  tanned={asset.tanned}
                />
              </div>
            );
          case 'ContentfulFactsBlock':
            return (
              <div id={asset.slug} className={styles.blockContainer}>
                <Facts
                  key={asset.slug}
                  title={asset.title}
                  elements={asset.elements}
                />
              </div>
            );
          case 'ContentfulCatalogue':
            return (
              <div id={asset.slug} className={styles.blockContainer}>
                <Catalogue
                  title={asset.title}
                  slug={asset.slug}
                  isDigitalOnly={asset.isDigitalOnly}
                  subHeading={asset.description?.subHeading}
                  icons={asset.icons}
                  featuredImage={asset.featuredImage}
                />
              </div>
            );
          case 'ContentfulVideosContainer':
            return (
              <div id={asset.slug} className={styles.blockContainer}>
                <VideosContainer
                  key={asset.slug}
                  title={asset.title}
                  elements={asset.elements}
                />
              </div>
            );
          case 'ContentfulProductDetailsBlock':
            return (
              <div id={asset.slug} className={styles.blockContainer}>
                <ProductDetails
                  title={asset.title}
                  details={asset.details}
                  image={asset.image}
                  isDecorated={asset.isDecorated}
                  subtitle={asset.subtitle}
                />
              </div>
            );

          case 'ContentfulServiceToolsBlock':
            return (
              <div id={asset.slug} className={styles.blockContainer}>
                <ServiceTools
                  title={asset.title}
                  paragraph={asset.paragraph}
                  elements={asset.elements}
                />
              </div>
            );
          case 'ContentfulPartnershipImageBlock':
            return (
              <div id={asset.slug} className={styles.blockContainer}>
                <PartnerImage
                  description={asset.description}
                  image={asset.image}
                />
              </div>
            );
          case 'ContentfulInfoTabsBlock':
            return (
              <div id={asset.slug} className={styles.blockContainer}>
                <InfoTabs title={asset.title} tabs={asset.tabs} />
              </div>
            );
          case 'ContentfulMapBlock':
            return (
              <div className={styles.blockContainer}>
                <MapBlock address={asset.address} title={asset?.title} />
              </div>
            );

          case 'ContentfulWidgetBlock':
            return (
              <div
                id={asset.slug}
                className={styles.blockContainer}
                data-testid="widgetBlock"
              >
                {
                  {
                    'Fenster widget': <FensterWidget />,
                    'Brandschutz widget': <BrandschutzWidget />,
                    'Turen widget': <TurenWidget />,
                    'E-Schiebefenster widget': <ESchiebefensterWidget />,
                  }[asset.widgetType]
                }
              </div>
            );
          case 'ContentfulCardsContainer': {
            return (
              <div id={asset.slug || ''} className={styles.blockContainer}>
                {FilteredElements(asset.elements)}
              </div>
            );
          }
        }
      },

      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        // Render the EMBEDDED_ASSET
        const asset = assets.get(node.data.target.sys.id) as
          | IContentfulAsset
          | undefined;
        if (!asset) return;
        const image = getImage(asset.gatsbyImageData);
        return (
          image && (
            <div
              className={styles.assetContainer}
              data-testid="rich-text-image"
            >
              <GatsbyImage
                className={styles.inlineImage}
                image={image}
                alt={asset.title}
              />
            </div>
          )
        );
      },
      [INLINES.HYPERLINK]: (node, children) => {
        const { uri } = node.data;
        const href = isUrlOrMailTo(uri) ? uri : `/${uri}`;
        const regex = /\/button\//;
        const isButton = children && regex.test(children[0]);

        if (isButton) {
          return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button variant='primary' href={href} isAnchor>
                {children[0].replace(regex, '')}
              </Button>
            </div>
          )
        }

        return <a href={href}>{children}</a>
      }
    },
  };

  return (
    <div className={styles.container}>
      {renderRichText(content, renderOptions)}
    </div>
  );
};
