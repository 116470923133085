import * as React from 'react';

import { Link } from '../';
import { IContentfulTileElement } from '../../../utils/fragments/tileElementFragment';

import * as styles from './Tile.module.less';

export interface ITileProps extends IContentfulTileElement {
  onClick?: (event: React.SyntheticEvent) => void;
}

export const Tile = ({
  icon,
  description,
  link,
  title,
  hoverIcon,
  onClick,
}: ITileProps): React.ReactElement => {
  const [hoveredIcon, setHoveredIcon] = React.useState(icon);

  const onHover = () => {
    setHoveredIcon(hoverIcon);
  };

  const onHoverReset = () => {
    setHoveredIcon(icon);
  };

  const isAnchorLink = link?.startsWith('#');
  const isExternal =
    link?.startsWith('http://') || link?.startsWith('https://');
  const href = isAnchorLink || isExternal ? link : `/${link}`;

  return link && href ? (
    <Link
      className={styles.container}
      href={href}
      isExternal={isExternal}
      onMouseLeave={onHoverReset}
      onMouseEnter={onHover}
      data-testid="tile"
      variant="primary"
    >
      <img src={hoveredIcon.file.url} alt={hoveredIcon.title} loading="lazy" />
      <div>
        <span data-testid="tile-title">{title}</span>
        {description && <p>{description.description}</p>}
      </div>
    </Link>
  ) : (
    <div
      className={styles.container}
      onMouseLeave={onHoverReset}
      onMouseEnter={onHover}
      onClick={onClick}
      data-testid="tile"
    >
      <img src={hoveredIcon.file.url} alt={hoveredIcon.title} loading="lazy" />
      <div>
        <span data-testid="tile-title">{title}</span>
        {description && <p>{description.description}</p>}
      </div>
    </div>
  );
};
