import { Link } from 'gatsby';
import * as React from 'react';

import * as styles from './NextPrev.module.less';
import nextIcon from './img/next-icon.svg';

export interface INextPrev {
  title: string;
  slug: string;
}

interface INextPrevProps {
  sectionName?: string;
  prevPost?: INextPrev;
  nextPost?: INextPrev;
}

export const NextPrev = ({
  sectionName = 'article',
  prevPost,
  nextPost,
}: INextPrevProps): JSX.Element => {
  return (
    <div
      className={`${styles.container} ${prevPost ? styles.withPrev : ''}`}
      data-testid="nextPrev"
    >
      {prevPost && (
        <Link className={styles.prev} to={prevPost.slug}>
          <img src={nextIcon} alt="navigation-icon" />
          <div>
            <h2>Prev {sectionName}</h2>
            <h3>{prevPost.title}</h3>
          </div>
        </Link>
      )}
      {nextPost && (
        <Link to={nextPost.slug}>
          <div>
            <h2>Next {sectionName}</h2>
            <h3>{nextPost.title}</h3>
          </div>
          <img src={nextIcon} alt="navigation-icon" />
        </Link>
      )}
    </div>
  );
};
