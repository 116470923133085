import * as React from 'react';

import { WidgetCardsContainer } from '../../WidgetCardsContainer';
import * as styles from '../FensterTurenWidget.module.less';
import iconOneThreeMonths from '../img/pictogram-1-3-monate-64.svg';
import iconThreeSixMonths from '../img/pictogram-3-6-monate-64.svg';
import iconRightAway from '../img/pictogram-sofort-64.svg';
import iconAfterSixMonths from '../img/pictogram-spater-6-monate-64.svg';

interface IShippingDate {
  onSelect: (element: string) => void;
  product: string;
  selectedItem: string;
}

export const ShippingDate: React.FunctionComponent<IShippingDate> = ({
  onSelect,
  selectedItem,
  product,
}) => {
  const onItemClick = (titles: string[]) => {
    onSelect(titles[0] || '');
  };

  return (
    <div>
      <div className={styles.question}>Wann benötigen Sie die {product}?</div>
      <WidgetCardsContainer
        elements={[
          {
            title: 'Sofort',
            icon: iconRightAway,
            selected: 'Sofort' === selectedItem,
          },
          {
            title: '1-3 Monate',
            icon: iconOneThreeMonths,
            selected: '1-3 Monate' === selectedItem,
          },
          {
            title: '3-6 Monate',
            icon: iconThreeSixMonths,
            selected: '3-6 Monate' === selectedItem,
          },
          {
            title: 'Später als 6 Monate',
            icon: iconAfterSixMonths,
            selected: 'Später als 6 Monate' === selectedItem,
          },
        ]}
        selectedItems={selectedItem ? [selectedItem] : []}
        setSelectedItems={onItemClick}
      />
    </div>
  );
};
