import * as React from 'react';

import * as styles from '../../FensterTurenWidget/FensterTurenWidget.module.less';
import iconFire30 from '../../FensterTurenWidget/img/pictogram-feuer-30-64.svg';
import iconFire60 from '../../FensterTurenWidget/img/pictogram-feuer-60-64.svg';
import iconFire90 from '../../FensterTurenWidget/img/pictogram-feuer-90-64.svg';
import { WidgetCardsContainer } from '../../WidgetCardsContainer';

interface IResistanceProps {
  onSelect: (element: string) => void;
  selectedItem: string;
}

export const Resistance: React.FunctionComponent<IResistanceProps> = ({
  onSelect,
  selectedItem,
}) => {
  const onItemClick = (titles: string[]) => {
    onSelect(titles[0] || '');
  };

  return (
    <div>
      <div className={styles.question}>
        Welche Feuerwiderstandsklassen benötigen Sie?
      </div>
      {/* TODO: USE THE CORRECT ICONS */}
      <WidgetCardsContainer
        elements={[
          {
            title: 'EI 30',
            icon: iconFire30,
            selected: 'EI 30' === selectedItem,
          },
          {
            title: 'EI 60',
            icon: iconFire60,
            selected: 'EI 60' === selectedItem,
          },
          {
            title: 'EI 90',
            icon: iconFire90,
            selected: 'EI 90' === selectedItem,
          },
        ]}
        selectedItems={selectedItem ? [selectedItem] : []}
        setSelectedItems={onItemClick}
      />
    </div>
  );
};
