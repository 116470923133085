import * as React from 'react';

import { IWidgetCardProps, WidgetCard } from './WidgetCard';
import * as styles from './WidgetCardsContainer.module.less';

interface IWidgetCardsContainerProps {
  elements: IWidgetCardProps[];
  multipleSelect?: boolean;
  selectedItems: string[];
  setSelectedItems: (state: string[]) => void;
}

export const WidgetCardsContainer = ({
  elements,
  multipleSelect = false,
  selectedItems,
  setSelectedItems,
}: IWidgetCardsContainerProps): JSX.Element => {
  const singleSelector = (title) => {
    const element = elements.find((item) => item.title === title);
    if (element && selectedItems.includes(element.title)) {
      setSelectedItems([]);
    } else element && setSelectedItems([element.title]);
  };

  const multipleSelector = (title) => {
    const element = elements.find((item) => item.title === title);
    if (element && selectedItems.includes(element.title)) {
      setSelectedItems(selectedItems.filter((el) => el !== title));
    } else element && setSelectedItems([...selectedItems, element.title]);
  };

  return (
    <div className={styles.container}>
      {elements.map((element) => {
        const onClick = element.onClick
          ? element.onClick
          : multipleSelect
          ? multipleSelector
          : singleSelector;
        return (
          <WidgetCard
            key={element.title}
            title={element.title}
            icon={element.icon}
            onClick={onClick}
            selected={selectedItems.includes(element.title)}
          />
        );
      })}
    </div>
  );
};
