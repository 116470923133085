import * as React from 'react';

import * as styles from './Facts.module.less';

interface IFactsProps {
  title: string;
  elements: {
    value: string;
    title: string;
    slug: string;
    description: {
      description: string;
    };
  }[];
}

export const Facts: React.FunctionComponent<IFactsProps> = ({
  title,
  elements,
}) => {
  let newTitle;
  let coloredWord;
  if (title.split(' ').length > 1) {
    newTitle = title.substring(0, title.lastIndexOf(' '));
    coloredWord = title.substring(title.lastIndexOf(' '));
  } else {
    newTitle = title;
  }

  return (
    <div className={styles.container} data-testid="facts">
      <h2>
        {newTitle}
        {coloredWord && <span>{coloredWord}</span>}
      </h2>
      <div>
        <div className={styles.elementsWrapper}>
          {elements.map(({ title, slug, value, description }) => (
            <div
              className={styles.element}
              key={slug}
              data-testid="fact-element"
            >
              <span>{value}</span>
              <div>
                <h3>{title}</h3>
                <p>{description?.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
