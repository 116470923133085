import * as React from 'react';

import { WidgetCardsContainer } from '../../WidgetCardsContainer';
import * as styles from '../FensterTurenWidget.module.less';
import iconConsultation from '../img/pictogram-beratung-64.svg';
import iconDesign from '../img/pictogram-design-64.svg';
import iconEnergyEfficiency from '../img/pictogram-energieeffizienz-64.svg';
import iconSecurity from '../img/pictogram-sicherheit-64.svg';

interface IPropertyProps {
  onSelect: (element: string[]) => void;
  selectedItems: string[];
}

export const Property: React.FunctionComponent<IPropertyProps> = ({
  onSelect,
  selectedItems,
}) => {
  const onItemClick = (titles: string[]) => {
    onSelect(titles);
  };

  return (
    <div>
      <div className={styles.question}>Was ist Ihnen besonders wichtig?</div>
      <WidgetCardsContainer
        elements={[
          {
            title: 'Sicherheit',
            icon: iconSecurity,
            selected: selectedItems.includes('Sicherheit'),
          },
          {
            title: 'Energieeffizienz',
            icon: iconEnergyEfficiency,
            selected: selectedItems.includes('Energieeffizienz'),
          },
          {
            title: 'Design',
            icon: iconDesign,
            selected: selectedItems.includes('Design'),
          },
          {
            title: 'suche Beratung',
            icon: iconConsultation,
            selected: selectedItems.includes('suche Beratung'),
          },
        ]}
        selectedItems={selectedItems}
        setSelectedItems={onItemClick}
        multipleSelect
      />
    </div>
  );
};
