import { Form, Input } from 'antd';
import * as React from 'react';

import { Button } from '../Clickable';
import successIcon from '../WidgetSuccessMessage/img/success-icon.svg';

import * as styles from './DeleteMetricaAccount.module.less';

export const DeleteMetricaAccount: React.FunctionComponent = () => {
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const [showError, setShowError] = React.useState(false);
  function handleSubmit(values) {
    setShowError(false);
    fetch('/.netlify/functions/DeleteMetricaAccount', {
      method: 'POST',
      body: JSON.stringify({
        ...values,
      })
    }).then((res) => {
      if (res.ok) {
        setIsSubmitted(true);
      } else {
        throw new Error(res.statusText);
      }
    }, () => {
      setShowError(true);
    });
  }

  return (
    <div className={styles.container}>
      <p>
        Sie können ihren Account entweder in den Einstellungen der installierten Metrica App löschen oder sie senden uns über das Formular ihren Nutzernamen und wir übernehmen die Löschung des Accounts für sie.
        Antrag auf Löschen des Metrica Accounts:
      </p>
      {isSubmitted ? (
        <img src={successIcon} alt="success-icon" />
      ) : (
        <Form onFinish={handleSubmit}>
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                message: 'Bitte geben Sie Ihren Nachnamen ein!',
              },
            ]}
          >
            <Input name="name" placeholder="name*" />
          </Form.Item>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: 'Bitte geben Sie Ihre E-Mail Adresse ein!',
              },
              {
                type: 'email',
                message:
                  'Bitte geben Sie eine gültige E-Mail Adresse ein (IE:example@gmail.com)',
              },
            ]}
          >
            <Input name="name" placeholder="E-Mail*" />
          </Form.Item>
          <Form.Item>
            <Button type='submit' variant='primary'>
              Absenden
            </Button>
          </Form.Item>
          {showError && <p className={styles.error}>Es ist ein Fehler aufgetreten. Bitte überprüfen Sie Ihre Daten und versuchen Sie es erneut</p>}
        </Form>
      )}
    </div>
  );
}
