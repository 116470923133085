import { Pagination } from 'antd';
import * as React from 'react';

import { Card, ICardProps } from '../';

import * as styles from './CardsContainer.module.less';

interface ICardsContainerProps {
  elements: ICardProps[];
  perPage?: number;
}

export const CardsContainer = ({
  perPage = 9,
  elements,
}: ICardsContainerProps): JSX.Element => {
  const [current, setCurrent] = React.useState(1);
  const [minIndex, setMinIndex] = React.useState(0);
  const [maxIndex, setMaxIndex] = React.useState(perPage);

  const handleChange = (pageNumber) => {
    setCurrent(pageNumber);
    setMinIndex((pageNumber - 1) * perPage);
    setMaxIndex(pageNumber * perPage);
  };

  return (
    <div className={styles.container} data-testid="cardsContainer">
      <div className={styles.wrapper}>
        {React.Children.toArray(
          elements.map(
            ({ title, description, image, tags, date, internalLink }, index) =>
              index >= minIndex &&
              index < maxIndex && (
                <div className={styles.cardElement}>
                  <Card
                    title={title}
                    description={description}
                    image={image}
                    tags={tags}
                    internalLink={internalLink}
                    date={date}
                  />
                </div>
              )
          )
        )}
      </div>
      <Pagination
        current={current}
        total={elements.length}
        pageSize={perPage}
        className={styles.pagination}
        onChange={handleChange}
        hideOnSinglePage={elements.length < 9}
      />
    </div>
  );
};
