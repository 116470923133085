import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import * as React from 'react';

import { ColorTitle } from '../../utils/helpers';
import { ICustomGatsbyImageData } from '../../utils/types';

import * as styles from './ServiceTools.module.less';

interface IServiceToolsProps {
  title: string;
  paragraph?: {
    paragraph: string;
  };
  elements: {
    title: string;
    slug: string;
    description: {
      description: string;
    };
    image: ICustomGatsbyImageData;
  }[];
}

export const ServiceTools = ({
  title,
  paragraph,
  elements,
}: IServiceToolsProps): JSX.Element => {
  const [selectedImage, setSelectedImage] = React.useState(0);
  const prevScrollY = React.useRef(0);
  const elementSelectedImage = elements[selectedImage].image;
  const featuredImage = getImage(elementSelectedImage.gatsbyImageData);

  const ImageHandler = (index) => {
    setTimeout(() => {
      setSelectedImage(index > 2 ? 2 : index < 1 ? 0 : index);
    }, 800);
  };

  React.useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (prevScrollY.current < currentScrollY) {
        ImageHandler(selectedImage + 1);
      }
      if (prevScrollY.current > currentScrollY) {
        ImageHandler(selectedImage - 1);
      }
      prevScrollY.current = currentScrollY;
    };
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, [selectedImage]);

  return (
    <div className={styles.container} data-testid="serviceTools">
      <div>
        <div className={styles.content}>
          <h2>{ColorTitle(title)}</h2>
          <p>{paragraph?.paragraph}</p>
        </div>
        <div className={styles.wrapper}>
          <div>
            {elements.map(({ title, description, image, slug }, index) => {
              const featuredImage = getImage(image.gatsbyImageData);
              return (
                <div
                  data-testid="service-element"
                  className={styles.element}
                  key={slug}
                >
                  <div
                    className={
                      index === selectedImage
                        ? styles.activeElement
                        : styles.inactiveElement
                    }
                    onMouseEnter={() => setSelectedImage(index)}
                  >
                    <h3>{title}</h3>
                    <p>{description.description}</p>
                  </div>
                  {featuredImage && (
                    <div className={styles.imageContainer}>
                      <GatsbyImage
                        data-testid="element-image"
                        image={featuredImage}
                        alt={title}
                      />
                    </div>
                  )}
                </div>
              );
            })}
          </div>
          {featuredImage && (
            <div className={styles.activeImage}>
              <GatsbyImage
                data-testid="active-image"
                image={featuredImage}
                alt={elements[selectedImage].title}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
