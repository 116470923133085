import Cookies from 'js-cookie';
import * as React from 'react';

import { trackWidgetStep } from '../../utils/helpers';
import { Button } from '../Clickable';
import {
  AmountOfWindows,
  Material,
  PersonalInformation,
  ShippingDate,
} from '../FensterTurenWidget';
import * as styles from '../FensterTurenWidget/FensterTurenWidget.module.less';
import { WidgetFormQuote } from '../WidgetFormQuote';
import { WidgetSteps } from '../WidgetSteps';
import { WidgetSuccessMessage } from '../WidgetSuccessMessage';

export const ESchiebefensterWidget: React.FunctionComponent = () => {
  const [windowsMaterial, setWindowsMaterial] = React.useState('');
  const [numberOfWindows, setNumberOfWindows] = React.useState(2);
  const [executionPeriod, setExecutionPeriod] = React.useState('');
  const [activeStep, setActiveStep] = React.useState(1);
  const [submitted, setSubmitted] = React.useState(false);

  React.useEffect(() => {
    trackWidgetStep('e-schiebefenster', activeStep);
    window.location.hash = `step${activeStep}`;
  }, [activeStep]);

  const numberOfSteps = 3;

  const windowSteps = {
    1: windowsMaterial,
    2: numberOfWindows,
    3: executionPeriod,
  };

  const windowsScreens = {
    1: (
      <Material
        text="Aus welchem Material sollen Ihre E-Schiebefenster bestehen?"
        onSelect={setWindowsMaterial}
        selectedItem={windowsMaterial}
      />
    ),
    2: (
      <AmountOfWindows
        product="E-Schiebefenster"
        value={numberOfWindows}
        onValueChange={setNumberOfWindows}
      />
    ),
    3: (
      <ShippingDate
        product="E-Schiebefenster"
        onSelect={setExecutionPeriod}
        selectedItem={executionPeriod}
      />
    ),
  };

  const listOfChoices = [
    { name: 'Material', value: windowsMaterial },
    { name: 'Anzahl der E-Schiebefenster', value: numberOfWindows },
    { name: 'Ausführungszeitraum', value: executionPeriod },
  ];

  const handleFinish = (values) => {
    fetch('/.netlify/functions/SubmitWidget', {
      method: 'POST',
      body: JSON.stringify({
        ...values,
        formName: 'e-schiebefenster',
        gclid: Cookies.get('gclid'),
        details: listOfChoices.reduce((acc, curr) => {
          const key =
            curr.name === 'Anzahl der E-Schiebefenster' ? 'Anzahl' : curr.name;
          return {
            ...acc,
            [key]: curr.value,
          };
        }, {}),
      }),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.statusText);
        } else {
          setSubmitted(true);
          return res;
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const formSection = (
    <PersonalInformation
      onFormSubmit={handleFinish}
      listOfChoices={listOfChoices}
    />
  );

  const showPrevButton = activeStep !== 1;
  const showNextButton = activeStep <= numberOfSteps;

  const buttonsBlock = (
    <div className={styles.buttonsBlock}>
      {showPrevButton && (
        <Button
          variant="secondary"
          onClick={() => {
            setActiveStep((currentStep) => currentStep - 1);
          }}
        >
          Zurück
        </Button>
      )}
      {showNextButton && (
        <Button
          variant="primary"
          onClick={() => {
            const stepValue = windowSteps[activeStep];

            if (!stepValue) return;

            setActiveStep((currentStep) => currentStep + 1);
          }}
        >
          Weiter
        </Button>
      )}
    </div>
  );

  return (
    <section
      className={`${styles.container} ${
        activeStep == 4 ? styles.fullWidth : ''
      }`}
    >
      <div>
        {submitted ? (
          <WidgetSuccessMessage />
        ) : (
          <>
            {activeStep !== 4 && (
              <WidgetSteps
                activeStep={activeStep}
                numberOfSteps={numberOfSteps}
              />
            )}
            {windowsScreens[activeStep]}
            {activeStep === 4 && formSection}
            {activeStep !== 4 && buttonsBlock}
          </>
        )}
      </div>
      {activeStep === 4 && <WidgetFormQuote />}
    </section>
  );
};
