// extracted by mini-css-extract-plugin
export var container = "ReferencesHero-module--container--NeWPe";
export var breadcrumb = "ReferencesHero-module--breadcrumb--2jsSE";
export var homePageItem = "ReferencesHero-module--homePageItem--3GNbi";
export var breadcrumbItem = "ReferencesHero-module--breadcrumbItem--11wWQ";
export var currentPageItem = "ReferencesHero-module--currentPageItem--ZpkCd";
export var wrapper = "ReferencesHero-module--wrapper--2MPZr";
export var title = "ReferencesHero-module--title--1IORQ";
export var card = "ReferencesHero-module--card--2l1iT";
export var subheading = "ReferencesHero-module--subheading--2SOQ2";
export var tagsBlock = "ReferencesHero-module--tagsBlock--2eiPI";
export var tag = "ReferencesHero-module--tag--2N7hc";
export var cardBody = "ReferencesHero-module--cardBody--3L2Rr";
export var linksBlock = "ReferencesHero-module--linksBlock--1dO7S";
export var link = "ReferencesHero-module--link--m1-Rw";