import * as React from 'react';

import * as styles from './Video.module.less';
import playIcon from './img/play-icon.svg';

interface IVideoProps {
  id: string;
  details?: {
    title: string;
    description?: string;
  };
}

export const Video = ({ id, details }: IVideoProps): JSX.Element => {
  const [isPlaying, setIsPlaying] = React.useState(false);
  const [showVideo, setShowVideo] = React.useState(false);
  const container: React.RefObject<HTMLIFrameElement> = React.createRef();
  // For now this is the solution I found to make the video play when user click the play button
  // but it found out that this only work on desktop because the autoplay is
  // intentionally disabled to all mobile devices. The reason is for the user to save cellular data
  const src = `https://www.youtube.com/embed/${id}`;
  // more about thumbnails https://orbitingweb.com/blog/view-youtube-thumbnail-image/
  const thumbnail = `https://i3.ytimg.com/vi_webp/${id}/sddefault.webp`;
  const url = isPlaying ? `${src}?autoplay=1` : src;

  React.useEffect(() => {
    if (videoObserver && 'IntersectionObserver' in window) {
      if (container && container.current) {
        videoObserver?.observe(container.current);
      }
    } else {
      setShowVideo(true);
    }
  }, [container]);

  function onVideoIntersection(entries) {
    if (!entries || entries.length <= 0) {
      return;
    }

    if (entries[0].isIntersecting) {
      setShowVideo(true);
      videoObserver?.disconnect();
    }
  }

  const videoObserver =
    typeof window !== 'undefined'
      ? new IntersectionObserver(onVideoIntersection, {
          rootMargin: '100px 0px',
          threshold: 0.25,
        })
      : undefined;

  return (
    <div ref={container} className={styles.container} data-testid="video">
      {showVideo && (
        <iframe
          title={details?.title || 'Youtube Video'}
          src={url}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        />
      )}
      {/* 
            Using the isPlaying on the classname to be able add the
            fade transition when user click on the play button 
        */}
      <div
        className={`${styles.overlayContainer} ${
          isPlaying ? styles.overlayHidden : ''
        }`}
      >
        <img
          className={styles.thumbnail}
          src={thumbnail}
          alt={details?.title || ''}
          loading="lazy"
        />
        <div className={styles.overlayFilter}></div>
        <div className={styles.content}>
          {details?.title && <h2>{details?.title}</h2>}
          {details?.description && <p>{details?.description}</p>}
          <img
            src={playIcon}
            alt="play-icon"
            onClick={() => setIsPlaying(true)}
          />
        </div>
      </div>
    </div>
  );
};
