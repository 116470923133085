import instagram from './img/instagram.svg';
import pinterest from './img/pinterest.svg';
import youtube from './img/youtube.svg';

const socialLinks = [
  {
    title: 'Pinterest',
    url: 'https://www.pinterest.de/sorpetaler/',
    logo: {
      title: 'pinterest-logo',
      src: pinterest,
    },
  },
  {
    title: 'Youtube',
    url: 'https://www.youtube.com/c/Sorpetaler/',
    logo: {
      title: 'youtube-logo',
      src: youtube,
    },
  },
  {
    title: 'Instagram',
    url: 'https://www.instagram.com/sorpetaler_fenster/?hl=de',
    logo: {
      title: 'instagram-logo',
      src: instagram,
    },
  },
];

const image = {
  title: 'cardImage',
  gatsbyImageData: {
    images: {
      sources: [
        {
          srcSet:
            'https://images.ctfassets.net/5x2puvkokbjd/4mFadovYv7pYpxhcUEEdbj/e4c199572801620fd6c20fe9e350a222/hero.png?w=397&h=220&q=50&fm=png 397w,\nhttps://images.ctfassets.net/5x2puvkokbjd/4mFadovYv7pYpxhcUEEdbj/e4c199572801620fd6c20fe9e350a222/hero.png?w=793&h=439&q=50&fm=png 793w,\nhttps://images.ctfassets.net/5x2puvkokbjd/4mFadovYv7pYpxhcUEEdbj/e4c199572801620fd6c20fe9e350a222/hero.png?w=1586&h=878&q=50&fm=png 1586w',

          type: 'image/webp',
        },
      ],
      fallback: {
        src:
          'https://images.ctfassets.net/5x2puvkokbjd/1Lx70i5ocQ01B91H2ZJ30Y/c8fdc564bf661b7b8a04847e90b48162/a-0-c-1809-c-49-f-7993-f-874621655-bc-26820_3x.jpg?w=1032&h=1032&fl=progressive&q=50&fm=jpg',
        srcSet:
          'https://images.ctfassets.net/5x2puvkokbjd/1Lx70i5ocQ01B91H2ZJ30Y/c8fdc564bf661b7b8a04847e90b48162/a-0-c-1809-c-49-f-7993-f-874621655-bc-26820_3x.jpg?w=258&h=258&fl=progressive&q=50&fm=jpg 258w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1Lx70i5ocQ01B91H2ZJ30Y/c8fdc564bf661b7b8a04847e90b48162/a-0-c-1809-c-49-f-7993-f-874621655-bc-26820_3x.jpg?w=516&h=516&fl=progressive&q=50&fm=jpg 516w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1Lx70i5ocQ01B91H2ZJ30Y/c8fdc564bf661b7b8a04847e90b48162/a-0-c-1809-c-49-f-7993-f-874621655-bc-26820_3x.jpg?w=1032&h=1032&fl=progressive&q=50&fm=jpg 1032w',
      },
    },
    layout: 'constrained' as const,
    backgroundColor: '#f8f8f8',
    width: 3000,
    height: 3000,
  },
};

const menuLinks = [
  {
    title: 'Fenster & Türen',
    slug: 'fenster-and-turen',
    order: 1,
    subPages: [
      {
        title: 'Holzfenster',
        menuTitle: 'Holzfenster',
        slug: 'holzfenster',
        featuredImage: image,
      },
      {
        title: 'Holz-Alu-Fenster',
        menuTitle: 'Holz-Alu-Fenster',
        slug: 'holz-alu-fenster',
        featuredImage: image,
      },
      {
        title: 'Schiebefenster',
        menuTitle: 'Schiebefenster',
        slug: 'schiebefenster',
        featuredImage: image,
      },
      {
        title: 'Schwingfenster',
        menuTitle: 'Schwingfenster',
        slug: 'schwingfenster',
        featuredImage: image,
      },
      {
        title: 'Denkmalfenster',
        menuTitle: 'Denkmalfenster',
        slug: 'denkmalfenster',
        featuredImage: image,
      },
      {
        title: 'Brandschuzfenfenster',
        menuTitle: 'Brandschuzfenfenster',
        slug: 'brandschuzfenfenster',
        featuredImage: image,
      },
      {
        title: 'Haustüren',
        menuTitle: 'Haustüren',
        slug: 'hausturen',
        featuredImage: image,
      },
      {
        title: 'Terrasentüren',
        menuTitle: 'Terrasentüren',
        slug: 'terrasenturen',
        featuredImage: image,
      },
    ],
  },
  {
    title: 'Technikqualität',
    slug: 'technikqualitat',
    order: 2,
    subPages: [
      {
        title: 'Hölzer',
        menuTitle: 'Hölzer',
        slug: 'holzer',
        featuredImage: image,
      },
      {
        title: 'Holz-Alu-Fenster',
        menuTitle: 'Holz-Alu-Fenster',
        slug: 'holz-alu-fenster',
        featuredImage: image,
      },
      {
        title: 'Farben & Oberflächen',
        menuTitle: 'Farben & Oberflächen',
        slug: 'farben-and-oberflachen',
        featuredImage: image,
      },
      {
        title: 'Wärmeschutz',
        menuTitle: 'Wärmeschutz',
        slug: 'warmeschutz',
        featuredImage: image,
      },
      {
        title: 'Sicherheit',
        menuTitle: 'Sicherheit',
        slug: 'sicherheit',
        featuredImage: image,
      },
      {
        title: 'Nachhatigkeit',
        menuTitle: 'Nachhatigkeit',
        slug: 'nachhatigkeit',
        featuredImage: image,
      },
    ],
  },
  {
    title: 'Referenzen',
    slug: 'referenzen',
    order: 3,
    subPages: [],
  },
  {
    title: 'Support/Service',
    slug: 'supportservice',
    order: 4,
    subPages: [
      { title: 'FAQS', menuTitle: 'FAQS', slug: 'faqs', featuredImage: image },
      {
        title: 'Förderungen',
        menuTitle: 'Förderungen',
        slug: 'forderungen',
        featuredImage: image,
      },
      {
        title: 'Wartung & Pflege',
        menuTitle: 'Wartung & Pflege',
        slug: 'wartung-and-pflege',
        featuredImage: image,
      },
      {
        title: 'Fensterwissen',
        menuTitle: 'Fensterwissen',
        slug: 'fensterwissen',
        featuredImage: image,
      },
    ],
  },
  {
    title: 'Unternehmen',
    slug: 'unternehmen',
    order: 5,
    subPages: [
      {
        title: 'Tradition & Werte',
        menuTitle: 'Tradition & Werte',
        slug: 'tradition-and-werte',
        featuredImage: image,
      },
      {
        title: 'Verantwortung',
        menuTitle: 'Verantwortung',
        slug: 'verantwortung',
        featuredImage: image,
      },
      { title: 'News', menuTitle: 'News', slug: 'news', featuredImage: image },
      {
        title: 'Karriere',
        menuTitle: 'Karriere',
        slug: 'karriere',
        featuredImage: image,
      },
    ],
  },
  {
    title: 'Für Profis',
    slug: 'fur-profis',
    order: 6,
    subPages: [
      {
        title: 'Technik & Systeme',
        menuTitle: 'Technik & Systeme',
        slug: 'technik-and-systeme',
        featuredImage: image,
      },
      {
        title: 'Tools & Services',
        menuTitle: 'Tools & Services',
        slug: 'tools-and-services',
        featuredImage: image,
      },
      {
        title: 'Partner Werden',
        menuTitle: 'Partner Werden',
        slug: 'partner-werden',
        featuredImage: image,
      },
    ],
  },
];

const infoLinks = [
  { title: 'Privacy Policy', url: '#' },
  { title: 'Terms of Service', url: '#' },
];

export const data = {
  socialLinks,
  menuLinks,
  infoLinks,
};
