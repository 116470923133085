import * as React from 'react';

import * as styles from './widgetSteps.module.less';

interface IWidgetStepsProps {
  /**
   * This represents the current active step.
   * The number should not exceed the total number of steps
   */
  activeStep: number;
  /**
   * This represents the total number of steps
   */
  numberOfSteps: number;
}

export const WidgetSteps: React.FunctionComponent<IWidgetStepsProps> = ({
  activeStep,

  numberOfSteps,
}) => {
  const stepsArray = Array(numberOfSteps || 1).fill('');
  return (
    <ul className={styles.container} data-testid="steps">
      {stepsArray.map((_, index) => (
        <li key={index} className={styles.step}>
          <span className={styles.circle}>
            {index < activeStep - 1 && (
              <span
                className={`${styles.circle} ${styles.active}`}
                data-testid="previous-steps"
              ></span>
            )}
            {index === activeStep - 1 && (
              <span
                className={`${styles.circle} ${styles.current}`}
                data-testid="active-step"
              ></span>
            )}
          </span>
          <span className={styles.bar}></span>
        </li>
      ))}
    </ul>
  );
};
