import { ICustomGatsbyImageData } from '../../../utils/types';

import instagramIcon from './img/instagram.svg';
import pinterestIcon from './img/pinterest.svg';
import phoneIcon from './img/small-phone-icon.svg';
import youtubeIcon from './img/youtube.svg';

export const headerInfo: { title: string; icon?: string; url?: string }[] = [
  {
    title: ' +49 2393 9192 0',
    icon: phoneIcon,
  },
  {
    title: 'Kontaktieren Sie uns',
    url: '/kontakt',
  },
];

export const socialLinks: { icon: string; alt: string; url: string }[] = [
  {
    icon: pinterestIcon,
    alt: 'Pinterest icon',
    url: 'https://www.pinterest.de/sorpetaler/',
  },
  {
    icon: youtubeIcon,
    alt: 'Youtube icon',
    url: 'https://www.youtube.com/c/Sorpetaler',
  },
  {
    icon: instagramIcon,
    alt: 'Instagram icon',
    url: 'https://www.instagram.com/sorpetaler_fenster/?hl=de',
  },
];

export const links: {
  title: string;
  slug: string;
  order: number;
  subPages: {
    title: string;
    menuTitle: string;
    slug: string;
    featuredImage: ICustomGatsbyImageData;
  }[];
  btnTitle?: string;
}[] = [
  {
    title: 'Fenster & Türen',
    slug: 'fenster-and-turen',
    order: 1,
    subPages: [
      {
        title: 'Holzfenster',
        menuTitle: 'Holzfenster',
        slug: 'holzfenster',
        featuredImage: {
          title: 'Hero Background',
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=380&h=419&q=100&fm=webp 380w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=512&h=565&q=100&fm=webp 512w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=648&h=715&q=100&fm=webp 648w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=720&h=794&q=100&fm=webp 720w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=959&h=1058&q=100&fm=webp 959w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=2883&h=3180&q=100&fm=webp 2883w',
                  sizes:
                    '(max-width: 768px) 380px, (max-width:1023px) 648px, (max-width: 1439px) 512px, (max-width: 1919px) 720px, 959px',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src:
                  'https://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=2883&h=3180&fl=progressive&q=100&fm=jpg',
                srcSet:
                  'https://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=380&h=419&fl=progressive&q=100&fm=jpg 380w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=512&h=565&fl=progressive&q=100&fm=jpg 512w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=648&h=715&fl=progressive&q=100&fm=jpg 648w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=720&h=794&fl=progressive&q=100&fm=jpg 720w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=959&h=1058&fl=progressive&q=100&fm=jpg 959w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=2883&h=3180&fl=progressive&q=100&fm=jpg 2883w',
                sizes:
                  '(max-width: 768px) 380px, (max-width:1023px) 648px, (max-width: 1439px) 512px, (max-width: 1919px) 720px, 959px',
              },
            },
            layout: 'constrained',
            width: 2883,
            height: 3180,
            placeholder: {
              fallback:
                'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAlgCWAAD/2wBDAAEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQH/2wBDAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQH/wAARCAAWABQDASEAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAgJBgr/xAAjEAACAgMAAQQDAQAAAAAAAAAEBQIDAQYHCAAJEhQRExUW/8QAFwEBAQEBAAAAAAAAAAAAAAAABgUEB//EACURAAMAAQMEAgMBAQAAAAAAAAECAwQFERIABhMhIjEHFEFRM//aAAwDAQACEQMRAD8A6CfGHwK5X1DnuNzc7f0ZW+IcHUTTotlS/wA+scYcCcSahHWvvGMfs2k2W25ybIXE7P1j0j0RhVBjzvAfSF604RLvPSAT7hbaA2Jl2ns4BX2wzCsv6n+QEgVOnOcW11XWYonOMcXQtr+dc4ehdhaRqGDiZORqWsRyb4sasq2w/Cr0RW+KUwWdohiQF8vLiNjTl8uq+p9552HmZGMmm6U8Y2MloUzfO6JsAzMmeJLUj5EiPDkdxMAbdT77R4bIle2U0S2JsxnIEyWb7jSF1uIVbNsQdELKE8Rg5WyGFpuutjTCUrrrI4jGqFUYnoHm9qiWXec8liiUKqWmCxA/rHj7J+zsAP8AAB66VY/cjtGbGABZQdlZ+IB9gL8/oDYDf2R7JJ99Sn4z528n1jaXE96690l3Uq1bZ9ayp2jl/RF4YuwF9GRvV11ounpGakTOg6kAx0URoIjPe7kroXHO9mXSrJDYvxx/znM28da6Hf8Ac+K6Lcyw009VsBFLY3b+csoWsNZ3OK/VtjcC6yNsycxQYvSu85fAV1shnWrrrigCR3mm/mXUNG7djpZwtN1DA0/hGb5mK2fknyYNsWBx52cRmsKznmbJRSAGQSDUKGTk/i+mqas+R+9+tXNDWVfIcaCLO0K2naiSo/NlqmOu8iGYs5baYbrF9g9zVcLv7tCuWLS0ur2xRo9i3Eoq3ZNyXYzJrnbGtA96qsAhoc0NhgD62Z0VjQzbbmyyUKz1zJ+98mzNVMaLo53R68p0ov0tKTUzVHcbOyqiKrEhVCgDpU3Zs8Y+B81leQVXWbReavxBdEZubMiMSqszMzAAkkk9S25V7X63pulBbftHeenht9lxYX8kzZrjGM2SsHgWVZ/YCjM2chbJXQoHqo/GYSj8rZSnBq+O+0VqbrOdW03v/aBDFH7IlpTNmJ11EdQXaTbGS5wlk1sRWwnAmV0J6k6+xdKF+L6cSzRVl0zsvU9VwMS1dfnFM2UssQlpUB4mrNGVBZKSJVVcr/yOxO+zKCrpdV700bSc/MxIdt1ucK1sTzZGs5JFBF/Ga/riTBWLqrlRbZgCN1Zg6atf7Hfjt0ubJu7755NAPFLGzX3A8di1p6NE8QYU2z6LoxSuOZh4rY1QpKOWric/CVeQ6q668yPVmXYOQk0Ua4uyqFHLR8Z29evbHI3Y/wCn1v8Aew+gJv3Zj3tSx0bgatz4pqdwq8veyhsdmAH8BZiP6zH2f//Z',
            },
          },
        },
      },
      {
        title: 'Holz-Alu-Fenster',
        menuTitle: 'Holz-Alu-Fenster',
        slug: 'holz-alu-fenster',
        featuredImage: {
          title: 'Hero Background',
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=380&h=419&q=100&fm=webp 380w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=512&h=565&q=100&fm=webp 512w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=648&h=715&q=100&fm=webp 648w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=720&h=794&q=100&fm=webp 720w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=959&h=1058&q=100&fm=webp 959w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=2883&h=3180&q=100&fm=webp 2883w',
                  sizes:
                    '(max-width: 768px) 380px, (max-width:1023px) 648px, (max-width: 1439px) 512px, (max-width: 1919px) 720px, 959px',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src:
                  'https://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=2883&h=3180&fl=progressive&q=100&fm=jpg',
                srcSet:
                  'https://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=380&h=419&fl=progressive&q=100&fm=jpg 380w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=512&h=565&fl=progressive&q=100&fm=jpg 512w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=648&h=715&fl=progressive&q=100&fm=jpg 648w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=720&h=794&fl=progressive&q=100&fm=jpg 720w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=959&h=1058&fl=progressive&q=100&fm=jpg 959w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=2883&h=3180&fl=progressive&q=100&fm=jpg 2883w',
                sizes:
                  '(max-width: 768px) 380px, (max-width:1023px) 648px, (max-width: 1439px) 512px, (max-width: 1919px) 720px, 959px',
              },
            },
            layout: 'constrained',
            width: 2883,
            height: 3180,
            placeholder: {
              fallback:
                'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAlgCWAAD/2wBDAAEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQH/2wBDAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQH/wAARCAAWABQDASEAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAgJBgr/xAAjEAACAgMAAQQDAQAAAAAAAAAEBQIDAQYHCAAJEhQRExUW/8QAFwEBAQEBAAAAAAAAAAAAAAAABgUEB//EACURAAMAAQMEAgMBAQAAAAAAAAECAwQFERIABhMhIjEHFEFRM//aAAwDAQACEQMRAD8A6CfGHwK5X1DnuNzc7f0ZW+IcHUTTotlS/wA+scYcCcSahHWvvGMfs2k2W25ybIXE7P1j0j0RhVBjzvAfSF604RLvPSAT7hbaA2Jl2ns4BX2wzCsv6n+QEgVOnOcW11XWYonOMcXQtr+dc4ehdhaRqGDiZORqWsRyb4sasq2w/Cr0RW+KUwWdohiQF8vLiNjTl8uq+p9552HmZGMmm6U8Y2MloUzfO6JsAzMmeJLUj5EiPDkdxMAbdT77R4bIle2U0S2JsxnIEyWb7jSF1uIVbNsQdELKE8Rg5WyGFpuutjTCUrrrI4jGqFUYnoHm9qiWXec8liiUKqWmCxA/rHj7J+zsAP8AAB66VY/cjtGbGABZQdlZ+IB9gL8/oDYDf2R7JJ99Sn4z528n1jaXE96690l3Uq1bZ9ayp2jl/RF4YuwF9GRvV11ounpGakTOg6kAx0URoIjPe7kroXHO9mXSrJDYvxx/znM28da6Hf8Ac+K6Lcyw009VsBFLY3b+csoWsNZ3OK/VtjcC6yNsycxQYvSu85fAV1shnWrrrigCR3mm/mXUNG7djpZwtN1DA0/hGb5mK2fknyYNsWBx52cRmsKznmbJRSAGQSDUKGTk/i+mqas+R+9+tXNDWVfIcaCLO0K2naiSo/NlqmOu8iGYs5baYbrF9g9zVcLv7tCuWLS0ur2xRo9i3Eoq3ZNyXYzJrnbGtA96qsAhoc0NhgD62Z0VjQzbbmyyUKz1zJ+98mzNVMaLo53R68p0ov0tKTUzVHcbOyqiKrEhVCgDpU3Zs8Y+B81leQVXWbReavxBdEZubMiMSqszMzAAkkk9S25V7X63pulBbftHeenht9lxYX8kzZrjGM2SsHgWVZ/YCjM2chbJXQoHqo/GYSj8rZSnBq+O+0VqbrOdW03v/aBDFH7IlpTNmJ11EdQXaTbGS5wlk1sRWwnAmV0J6k6+xdKF+L6cSzRVl0zsvU9VwMS1dfnFM2UssQlpUB4mrNGVBZKSJVVcr/yOxO+zKCrpdV700bSc/MxIdt1ucK1sTzZGs5JFBF/Ga/riTBWLqrlRbZgCN1Zg6atf7Hfjt0ubJu7755NAPFLGzX3A8di1p6NE8QYU2z6LoxSuOZh4rY1QpKOWric/CVeQ6q668yPVmXYOQk0Ua4uyqFHLR8Z29evbHI3Y/wCn1v8Aew+gJv3Zj3tSx0bgatz4pqdwq8veyhsdmAH8BZiP6zH2f//Z',
            },
          },
        },
      },
      {
        title: 'Schiebefenster',
        menuTitle: 'Schiebefenster',
        slug: 'schiebefenster',
        featuredImage: {
          title: 'Hero Background',
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=380&h=419&q=100&fm=webp 380w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=512&h=565&q=100&fm=webp 512w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=648&h=715&q=100&fm=webp 648w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=720&h=794&q=100&fm=webp 720w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=959&h=1058&q=100&fm=webp 959w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=2883&h=3180&q=100&fm=webp 2883w',
                  sizes:
                    '(max-width: 768px) 380px, (max-width:1023px) 648px, (max-width: 1439px) 512px, (max-width: 1919px) 720px, 959px',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src:
                  'https://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=2883&h=3180&fl=progressive&q=100&fm=jpg',
                srcSet:
                  'https://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=380&h=419&fl=progressive&q=100&fm=jpg 380w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=512&h=565&fl=progressive&q=100&fm=jpg 512w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=648&h=715&fl=progressive&q=100&fm=jpg 648w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=720&h=794&fl=progressive&q=100&fm=jpg 720w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=959&h=1058&fl=progressive&q=100&fm=jpg 959w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=2883&h=3180&fl=progressive&q=100&fm=jpg 2883w',
                sizes:
                  '(max-width: 768px) 380px, (max-width:1023px) 648px, (max-width: 1439px) 512px, (max-width: 1919px) 720px, 959px',
              },
            },
            layout: 'constrained',
            width: 2883,
            height: 3180,
            placeholder: {
              fallback:
                'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAlgCWAAD/2wBDAAEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQH/2wBDAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQH/wAARCAAWABQDASEAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAgJBgr/xAAjEAACAgMAAQQDAQAAAAAAAAAEBQIDAQYHCAAJEhQRExUW/8QAFwEBAQEBAAAAAAAAAAAAAAAABgUEB//EACURAAMAAQMEAgMBAQAAAAAAAAECAwQFERIABhMhIjEHFEFRM//aAAwDAQACEQMRAD8A6CfGHwK5X1DnuNzc7f0ZW+IcHUTTotlS/wA+scYcCcSahHWvvGMfs2k2W25ybIXE7P1j0j0RhVBjzvAfSF604RLvPSAT7hbaA2Jl2ns4BX2wzCsv6n+QEgVOnOcW11XWYonOMcXQtr+dc4ehdhaRqGDiZORqWsRyb4sasq2w/Cr0RW+KUwWdohiQF8vLiNjTl8uq+p9552HmZGMmm6U8Y2MloUzfO6JsAzMmeJLUj5EiPDkdxMAbdT77R4bIle2U0S2JsxnIEyWb7jSF1uIVbNsQdELKE8Rg5WyGFpuutjTCUrrrI4jGqFUYnoHm9qiWXec8liiUKqWmCxA/rHj7J+zsAP8AAB66VY/cjtGbGABZQdlZ+IB9gL8/oDYDf2R7JJ99Sn4z528n1jaXE96690l3Uq1bZ9ayp2jl/RF4YuwF9GRvV11ounpGakTOg6kAx0URoIjPe7kroXHO9mXSrJDYvxx/znM28da6Hf8Ac+K6Lcyw009VsBFLY3b+csoWsNZ3OK/VtjcC6yNsycxQYvSu85fAV1shnWrrrigCR3mm/mXUNG7djpZwtN1DA0/hGb5mK2fknyYNsWBx52cRmsKznmbJRSAGQSDUKGTk/i+mqas+R+9+tXNDWVfIcaCLO0K2naiSo/NlqmOu8iGYs5baYbrF9g9zVcLv7tCuWLS0ur2xRo9i3Eoq3ZNyXYzJrnbGtA96qsAhoc0NhgD62Z0VjQzbbmyyUKz1zJ+98mzNVMaLo53R68p0ov0tKTUzVHcbOyqiKrEhVCgDpU3Zs8Y+B81leQVXWbReavxBdEZubMiMSqszMzAAkkk9S25V7X63pulBbftHeenht9lxYX8kzZrjGM2SsHgWVZ/YCjM2chbJXQoHqo/GYSj8rZSnBq+O+0VqbrOdW03v/aBDFH7IlpTNmJ11EdQXaTbGS5wlk1sRWwnAmV0J6k6+xdKF+L6cSzRVl0zsvU9VwMS1dfnFM2UssQlpUB4mrNGVBZKSJVVcr/yOxO+zKCrpdV700bSc/MxIdt1ucK1sTzZGs5JFBF/Ga/riTBWLqrlRbZgCN1Zg6atf7Hfjt0ubJu7755NAPFLGzX3A8di1p6NE8QYU2z6LoxSuOZh4rY1QpKOWric/CVeQ6q668yPVmXYOQk0Ua4uyqFHLR8Z29evbHI3Y/wCn1v8Aew+gJv3Zj3tSx0bgatz4pqdwq8veyhsdmAH8BZiP6zH2f//Z',
            },
          },
        },
      },
      {
        title: 'Schwingfenster',
        menuTitle: 'Schwingfenster',
        slug: 'schwingfenster',
        featuredImage: {
          title: 'Hero Background',
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=380&h=419&q=100&fm=webp 380w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=512&h=565&q=100&fm=webp 512w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=648&h=715&q=100&fm=webp 648w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=720&h=794&q=100&fm=webp 720w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=959&h=1058&q=100&fm=webp 959w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=2883&h=3180&q=100&fm=webp 2883w',
                  sizes:
                    '(max-width: 768px) 380px, (max-width:1023px) 648px, (max-width: 1439px) 512px, (max-width: 1919px) 720px, 959px',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src:
                  'https://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=2883&h=3180&fl=progressive&q=100&fm=jpg',
                srcSet:
                  'https://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=380&h=419&fl=progressive&q=100&fm=jpg 380w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=512&h=565&fl=progressive&q=100&fm=jpg 512w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=648&h=715&fl=progressive&q=100&fm=jpg 648w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=720&h=794&fl=progressive&q=100&fm=jpg 720w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=959&h=1058&fl=progressive&q=100&fm=jpg 959w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=2883&h=3180&fl=progressive&q=100&fm=jpg 2883w',
                sizes:
                  '(max-width: 768px) 380px, (max-width:1023px) 648px, (max-width: 1439px) 512px, (max-width: 1919px) 720px, 959px',
              },
            },
            layout: 'constrained',
            width: 2883,
            height: 3180,
            placeholder: {
              fallback:
                'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAlgCWAAD/2wBDAAEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQH/2wBDAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQH/wAARCAAWABQDASEAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAgJBgr/xAAjEAACAgMAAQQDAQAAAAAAAAAEBQIDAQYHCAAJEhQRExUW/8QAFwEBAQEBAAAAAAAAAAAAAAAABgUEB//EACURAAMAAQMEAgMBAQAAAAAAAAECAwQFERIABhMhIjEHFEFRM//aAAwDAQACEQMRAD8A6CfGHwK5X1DnuNzc7f0ZW+IcHUTTotlS/wA+scYcCcSahHWvvGMfs2k2W25ybIXE7P1j0j0RhVBjzvAfSF604RLvPSAT7hbaA2Jl2ns4BX2wzCsv6n+QEgVOnOcW11XWYonOMcXQtr+dc4ehdhaRqGDiZORqWsRyb4sasq2w/Cr0RW+KUwWdohiQF8vLiNjTl8uq+p9552HmZGMmm6U8Y2MloUzfO6JsAzMmeJLUj5EiPDkdxMAbdT77R4bIle2U0S2JsxnIEyWb7jSF1uIVbNsQdELKE8Rg5WyGFpuutjTCUrrrI4jGqFUYnoHm9qiWXec8liiUKqWmCxA/rHj7J+zsAP8AAB66VY/cjtGbGABZQdlZ+IB9gL8/oDYDf2R7JJ99Sn4z528n1jaXE96690l3Uq1bZ9ayp2jl/RF4YuwF9GRvV11ounpGakTOg6kAx0URoIjPe7kroXHO9mXSrJDYvxx/znM28da6Hf8Ac+K6Lcyw009VsBFLY3b+csoWsNZ3OK/VtjcC6yNsycxQYvSu85fAV1shnWrrrigCR3mm/mXUNG7djpZwtN1DA0/hGb5mK2fknyYNsWBx52cRmsKznmbJRSAGQSDUKGTk/i+mqas+R+9+tXNDWVfIcaCLO0K2naiSo/NlqmOu8iGYs5baYbrF9g9zVcLv7tCuWLS0ur2xRo9i3Eoq3ZNyXYzJrnbGtA96qsAhoc0NhgD62Z0VjQzbbmyyUKz1zJ+98mzNVMaLo53R68p0ov0tKTUzVHcbOyqiKrEhVCgDpU3Zs8Y+B81leQVXWbReavxBdEZubMiMSqszMzAAkkk9S25V7X63pulBbftHeenht9lxYX8kzZrjGM2SsHgWVZ/YCjM2chbJXQoHqo/GYSj8rZSnBq+O+0VqbrOdW03v/aBDFH7IlpTNmJ11EdQXaTbGS5wlk1sRWwnAmV0J6k6+xdKF+L6cSzRVl0zsvU9VwMS1dfnFM2UssQlpUB4mrNGVBZKSJVVcr/yOxO+zKCrpdV700bSc/MxIdt1ucK1sTzZGs5JFBF/Ga/riTBWLqrlRbZgCN1Zg6atf7Hfjt0ubJu7755NAPFLGzX3A8di1p6NE8QYU2z6LoxSuOZh4rY1QpKOWric/CVeQ6q668yPVmXYOQk0Ua4uyqFHLR8Z29evbHI3Y/wCn1v8Aew+gJv3Zj3tSx0bgatz4pqdwq8veyhsdmAH8BZiP6zH2f//Z',
            },
          },
        },
      },
      {
        title: 'Denkmalfenster',
        menuTitle: 'Denkmalfenster',
        slug: 'denkmalfenster',
        featuredImage: {
          title: 'Hero Background',
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=380&h=419&q=100&fm=webp 380w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=512&h=565&q=100&fm=webp 512w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=648&h=715&q=100&fm=webp 648w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=720&h=794&q=100&fm=webp 720w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=959&h=1058&q=100&fm=webp 959w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=2883&h=3180&q=100&fm=webp 2883w',
                  sizes:
                    '(max-width: 768px) 380px, (max-width:1023px) 648px, (max-width: 1439px) 512px, (max-width: 1919px) 720px, 959px',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src:
                  'https://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=2883&h=3180&fl=progressive&q=100&fm=jpg',
                srcSet:
                  'https://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=380&h=419&fl=progressive&q=100&fm=jpg 380w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=512&h=565&fl=progressive&q=100&fm=jpg 512w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=648&h=715&fl=progressive&q=100&fm=jpg 648w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=720&h=794&fl=progressive&q=100&fm=jpg 720w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=959&h=1058&fl=progressive&q=100&fm=jpg 959w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=2883&h=3180&fl=progressive&q=100&fm=jpg 2883w',
                sizes:
                  '(max-width: 768px) 380px, (max-width:1023px) 648px, (max-width: 1439px) 512px, (max-width: 1919px) 720px, 959px',
              },
            },
            layout: 'constrained',
            width: 2883,
            height: 3180,
            placeholder: {
              fallback:
                'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAlgCWAAD/2wBDAAEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQH/2wBDAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQH/wAARCAAWABQDASEAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAgJBgr/xAAjEAACAgMAAQQDAQAAAAAAAAAEBQIDAQYHCAAJEhQRExUW/8QAFwEBAQEBAAAAAAAAAAAAAAAABgUEB//EACURAAMAAQMEAgMBAQAAAAAAAAECAwQFERIABhMhIjEHFEFRM//aAAwDAQACEQMRAD8A6CfGHwK5X1DnuNzc7f0ZW+IcHUTTotlS/wA+scYcCcSahHWvvGMfs2k2W25ybIXE7P1j0j0RhVBjzvAfSF604RLvPSAT7hbaA2Jl2ns4BX2wzCsv6n+QEgVOnOcW11XWYonOMcXQtr+dc4ehdhaRqGDiZORqWsRyb4sasq2w/Cr0RW+KUwWdohiQF8vLiNjTl8uq+p9552HmZGMmm6U8Y2MloUzfO6JsAzMmeJLUj5EiPDkdxMAbdT77R4bIle2U0S2JsxnIEyWb7jSF1uIVbNsQdELKE8Rg5WyGFpuutjTCUrrrI4jGqFUYnoHm9qiWXec8liiUKqWmCxA/rHj7J+zsAP8AAB66VY/cjtGbGABZQdlZ+IB9gL8/oDYDf2R7JJ99Sn4z528n1jaXE96690l3Uq1bZ9ayp2jl/RF4YuwF9GRvV11ounpGakTOg6kAx0URoIjPe7kroXHO9mXSrJDYvxx/znM28da6Hf8Ac+K6Lcyw009VsBFLY3b+csoWsNZ3OK/VtjcC6yNsycxQYvSu85fAV1shnWrrrigCR3mm/mXUNG7djpZwtN1DA0/hGb5mK2fknyYNsWBx52cRmsKznmbJRSAGQSDUKGTk/i+mqas+R+9+tXNDWVfIcaCLO0K2naiSo/NlqmOu8iGYs5baYbrF9g9zVcLv7tCuWLS0ur2xRo9i3Eoq3ZNyXYzJrnbGtA96qsAhoc0NhgD62Z0VjQzbbmyyUKz1zJ+98mzNVMaLo53R68p0ov0tKTUzVHcbOyqiKrEhVCgDpU3Zs8Y+B81leQVXWbReavxBdEZubMiMSqszMzAAkkk9S25V7X63pulBbftHeenht9lxYX8kzZrjGM2SsHgWVZ/YCjM2chbJXQoHqo/GYSj8rZSnBq+O+0VqbrOdW03v/aBDFH7IlpTNmJ11EdQXaTbGS5wlk1sRWwnAmV0J6k6+xdKF+L6cSzRVl0zsvU9VwMS1dfnFM2UssQlpUB4mrNGVBZKSJVVcr/yOxO+zKCrpdV700bSc/MxIdt1ucK1sTzZGs5JFBF/Ga/riTBWLqrlRbZgCN1Zg6atf7Hfjt0ubJu7755NAPFLGzX3A8di1p6NE8QYU2z6LoxSuOZh4rY1QpKOWric/CVeQ6q668yPVmXYOQk0Ua4uyqFHLR8Z29evbHI3Y/wCn1v8Aew+gJv3Zj3tSx0bgatz4pqdwq8veyhsdmAH8BZiP6zH2f//Z',
            },
          },
        },
      },
      {
        title: 'Brandschutzfenster',
        menuTitle: 'Brandschutzfenster',
        slug: 'brandschuzfenfenster',
        featuredImage: {
          title: 'Hero Background',
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=380&h=419&q=100&fm=webp 380w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=512&h=565&q=100&fm=webp 512w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=648&h=715&q=100&fm=webp 648w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=720&h=794&q=100&fm=webp 720w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=959&h=1058&q=100&fm=webp 959w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=2883&h=3180&q=100&fm=webp 2883w',
                  sizes:
                    '(max-width: 768px) 380px, (max-width:1023px) 648px, (max-width: 1439px) 512px, (max-width: 1919px) 720px, 959px',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src:
                  'https://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=2883&h=3180&fl=progressive&q=100&fm=jpg',
                srcSet:
                  'https://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=380&h=419&fl=progressive&q=100&fm=jpg 380w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=512&h=565&fl=progressive&q=100&fm=jpg 512w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=648&h=715&fl=progressive&q=100&fm=jpg 648w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=720&h=794&fl=progressive&q=100&fm=jpg 720w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=959&h=1058&fl=progressive&q=100&fm=jpg 959w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=2883&h=3180&fl=progressive&q=100&fm=jpg 2883w',
                sizes:
                  '(max-width: 768px) 380px, (max-width:1023px) 648px, (max-width: 1439px) 512px, (max-width: 1919px) 720px, 959px',
              },
            },
            layout: 'constrained',
            width: 2883,
            height: 3180,
            placeholder: {
              fallback:
                'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAlgCWAAD/2wBDAAEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQH/2wBDAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQH/wAARCAAWABQDASEAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAgJBgr/xAAjEAACAgMAAQQDAQAAAAAAAAAEBQIDAQYHCAAJEhQRExUW/8QAFwEBAQEBAAAAAAAAAAAAAAAABgUEB//EACURAAMAAQMEAgMBAQAAAAAAAAECAwQFERIABhMhIjEHFEFRM//aAAwDAQACEQMRAD8A6CfGHwK5X1DnuNzc7f0ZW+IcHUTTotlS/wA+scYcCcSahHWvvGMfs2k2W25ybIXE7P1j0j0RhVBjzvAfSF604RLvPSAT7hbaA2Jl2ns4BX2wzCsv6n+QEgVOnOcW11XWYonOMcXQtr+dc4ehdhaRqGDiZORqWsRyb4sasq2w/Cr0RW+KUwWdohiQF8vLiNjTl8uq+p9552HmZGMmm6U8Y2MloUzfO6JsAzMmeJLUj5EiPDkdxMAbdT77R4bIle2U0S2JsxnIEyWb7jSF1uIVbNsQdELKE8Rg5WyGFpuutjTCUrrrI4jGqFUYnoHm9qiWXec8liiUKqWmCxA/rHj7J+zsAP8AAB66VY/cjtGbGABZQdlZ+IB9gL8/oDYDf2R7JJ99Sn4z528n1jaXE96690l3Uq1bZ9ayp2jl/RF4YuwF9GRvV11ounpGakTOg6kAx0URoIjPe7kroXHO9mXSrJDYvxx/znM28da6Hf8Ac+K6Lcyw009VsBFLY3b+csoWsNZ3OK/VtjcC6yNsycxQYvSu85fAV1shnWrrrigCR3mm/mXUNG7djpZwtN1DA0/hGb5mK2fknyYNsWBx52cRmsKznmbJRSAGQSDUKGTk/i+mqas+R+9+tXNDWVfIcaCLO0K2naiSo/NlqmOu8iGYs5baYbrF9g9zVcLv7tCuWLS0ur2xRo9i3Eoq3ZNyXYzJrnbGtA96qsAhoc0NhgD62Z0VjQzbbmyyUKz1zJ+98mzNVMaLo53R68p0ov0tKTUzVHcbOyqiKrEhVCgDpU3Zs8Y+B81leQVXWbReavxBdEZubMiMSqszMzAAkkk9S25V7X63pulBbftHeenht9lxYX8kzZrjGM2SsHgWVZ/YCjM2chbJXQoHqo/GYSj8rZSnBq+O+0VqbrOdW03v/aBDFH7IlpTNmJ11EdQXaTbGS5wlk1sRWwnAmV0J6k6+xdKF+L6cSzRVl0zsvU9VwMS1dfnFM2UssQlpUB4mrNGVBZKSJVVcr/yOxO+zKCrpdV700bSc/MxIdt1ucK1sTzZGs5JFBF/Ga/riTBWLqrlRbZgCN1Zg6atf7Hfjt0ubJu7755NAPFLGzX3A8di1p6NE8QYU2z6LoxSuOZh4rY1QpKOWric/CVeQ6q668yPVmXYOQk0Ua4uyqFHLR8Z29evbHI3Y/wCn1v8Aew+gJv3Zj3tSx0bgatz4pqdwq8veyhsdmAH8BZiP6zH2f//Z',
            },
          },
        },
      },
      {
        title: 'Haustüren',
        menuTitle: 'Haustüren',
        slug: 'hausturen',
        featuredImage: {
          title: 'Hero Background',
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=380&h=419&q=100&fm=webp 380w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=512&h=565&q=100&fm=webp 512w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=648&h=715&q=100&fm=webp 648w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=720&h=794&q=100&fm=webp 720w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=959&h=1058&q=100&fm=webp 959w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=2883&h=3180&q=100&fm=webp 2883w',
                  sizes:
                    '(max-width: 768px) 380px, (max-width:1023px) 648px, (max-width: 1439px) 512px, (max-width: 1919px) 720px, 959px',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src:
                  'https://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=2883&h=3180&fl=progressive&q=100&fm=jpg',
                srcSet:
                  'https://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=380&h=419&fl=progressive&q=100&fm=jpg 380w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=512&h=565&fl=progressive&q=100&fm=jpg 512w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=648&h=715&fl=progressive&q=100&fm=jpg 648w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=720&h=794&fl=progressive&q=100&fm=jpg 720w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=959&h=1058&fl=progressive&q=100&fm=jpg 959w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=2883&h=3180&fl=progressive&q=100&fm=jpg 2883w',
                sizes:
                  '(max-width: 768px) 380px, (max-width:1023px) 648px, (max-width: 1439px) 512px, (max-width: 1919px) 720px, 959px',
              },
            },
            layout: 'constrained',
            width: 2883,
            height: 3180,
            placeholder: {
              fallback:
                'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAlgCWAAD/2wBDAAEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQH/2wBDAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQH/wAARCAAWABQDASEAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAgJBgr/xAAjEAACAgMAAQQDAQAAAAAAAAAEBQIDAQYHCAAJEhQRExUW/8QAFwEBAQEBAAAAAAAAAAAAAAAABgUEB//EACURAAMAAQMEAgMBAQAAAAAAAAECAwQFERIABhMhIjEHFEFRM//aAAwDAQACEQMRAD8A6CfGHwK5X1DnuNzc7f0ZW+IcHUTTotlS/wA+scYcCcSahHWvvGMfs2k2W25ybIXE7P1j0j0RhVBjzvAfSF604RLvPSAT7hbaA2Jl2ns4BX2wzCsv6n+QEgVOnOcW11XWYonOMcXQtr+dc4ehdhaRqGDiZORqWsRyb4sasq2w/Cr0RW+KUwWdohiQF8vLiNjTl8uq+p9552HmZGMmm6U8Y2MloUzfO6JsAzMmeJLUj5EiPDkdxMAbdT77R4bIle2U0S2JsxnIEyWb7jSF1uIVbNsQdELKE8Rg5WyGFpuutjTCUrrrI4jGqFUYnoHm9qiWXec8liiUKqWmCxA/rHj7J+zsAP8AAB66VY/cjtGbGABZQdlZ+IB9gL8/oDYDf2R7JJ99Sn4z528n1jaXE96690l3Uq1bZ9ayp2jl/RF4YuwF9GRvV11ounpGakTOg6kAx0URoIjPe7kroXHO9mXSrJDYvxx/znM28da6Hf8Ac+K6Lcyw009VsBFLY3b+csoWsNZ3OK/VtjcC6yNsycxQYvSu85fAV1shnWrrrigCR3mm/mXUNG7djpZwtN1DA0/hGb5mK2fknyYNsWBx52cRmsKznmbJRSAGQSDUKGTk/i+mqas+R+9+tXNDWVfIcaCLO0K2naiSo/NlqmOu8iGYs5baYbrF9g9zVcLv7tCuWLS0ur2xRo9i3Eoq3ZNyXYzJrnbGtA96qsAhoc0NhgD62Z0VjQzbbmyyUKz1zJ+98mzNVMaLo53R68p0ov0tKTUzVHcbOyqiKrEhVCgDpU3Zs8Y+B81leQVXWbReavxBdEZubMiMSqszMzAAkkk9S25V7X63pulBbftHeenht9lxYX8kzZrjGM2SsHgWVZ/YCjM2chbJXQoHqo/GYSj8rZSnBq+O+0VqbrOdW03v/aBDFH7IlpTNmJ11EdQXaTbGS5wlk1sRWwnAmV0J6k6+xdKF+L6cSzRVl0zsvU9VwMS1dfnFM2UssQlpUB4mrNGVBZKSJVVcr/yOxO+zKCrpdV700bSc/MxIdt1ucK1sTzZGs5JFBF/Ga/riTBWLqrlRbZgCN1Zg6atf7Hfjt0ubJu7755NAPFLGzX3A8di1p6NE8QYU2z6LoxSuOZh4rY1QpKOWric/CVeQ6q668yPVmXYOQk0Ua4uyqFHLR8Z29evbHI3Y/wCn1v8Aew+gJv3Zj3tSx0bgatz4pqdwq8veyhsdmAH8BZiP6zH2f//Z',
            },
          },
        },
      },
      {
        title: 'Terrasentüren',
        menuTitle: 'Terrasentüren',
        slug: 'terrasenturen',
        featuredImage: {
          title: 'Hero Background',
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=380&h=419&q=100&fm=webp 380w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=512&h=565&q=100&fm=webp 512w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=648&h=715&q=100&fm=webp 648w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=720&h=794&q=100&fm=webp 720w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=959&h=1058&q=100&fm=webp 959w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=2883&h=3180&q=100&fm=webp 2883w',
                  sizes:
                    '(max-width: 768px) 380px, (max-width:1023px) 648px, (max-width: 1439px) 512px, (max-width: 1919px) 720px, 959px',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src:
                  'https://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=2883&h=3180&fl=progressive&q=100&fm=jpg',
                srcSet:
                  'https://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=380&h=419&fl=progressive&q=100&fm=jpg 380w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=512&h=565&fl=progressive&q=100&fm=jpg 512w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=648&h=715&fl=progressive&q=100&fm=jpg 648w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=720&h=794&fl=progressive&q=100&fm=jpg 720w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=959&h=1058&fl=progressive&q=100&fm=jpg 959w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=2883&h=3180&fl=progressive&q=100&fm=jpg 2883w',
                sizes:
                  '(max-width: 768px) 380px, (max-width:1023px) 648px, (max-width: 1439px) 512px, (max-width: 1919px) 720px, 959px',
              },
            },
            layout: 'constrained',
            width: 2883,
            height: 3180,
            placeholder: {
              fallback:
                'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAlgCWAAD/2wBDAAEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQH/2wBDAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQH/wAARCAAWABQDASEAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAgJBgr/xAAjEAACAgMAAQQDAQAAAAAAAAAEBQIDAQYHCAAJEhQRExUW/8QAFwEBAQEBAAAAAAAAAAAAAAAABgUEB//EACURAAMAAQMEAgMBAQAAAAAAAAECAwQFERIABhMhIjEHFEFRM//aAAwDAQACEQMRAD8A6CfGHwK5X1DnuNzc7f0ZW+IcHUTTotlS/wA+scYcCcSahHWvvGMfs2k2W25ybIXE7P1j0j0RhVBjzvAfSF604RLvPSAT7hbaA2Jl2ns4BX2wzCsv6n+QEgVOnOcW11XWYonOMcXQtr+dc4ehdhaRqGDiZORqWsRyb4sasq2w/Cr0RW+KUwWdohiQF8vLiNjTl8uq+p9552HmZGMmm6U8Y2MloUzfO6JsAzMmeJLUj5EiPDkdxMAbdT77R4bIle2U0S2JsxnIEyWb7jSF1uIVbNsQdELKE8Rg5WyGFpuutjTCUrrrI4jGqFUYnoHm9qiWXec8liiUKqWmCxA/rHj7J+zsAP8AAB66VY/cjtGbGABZQdlZ+IB9gL8/oDYDf2R7JJ99Sn4z528n1jaXE96690l3Uq1bZ9ayp2jl/RF4YuwF9GRvV11ounpGakTOg6kAx0URoIjPe7kroXHO9mXSrJDYvxx/znM28da6Hf8Ac+K6Lcyw009VsBFLY3b+csoWsNZ3OK/VtjcC6yNsycxQYvSu85fAV1shnWrrrigCR3mm/mXUNG7djpZwtN1DA0/hGb5mK2fknyYNsWBx52cRmsKznmbJRSAGQSDUKGTk/i+mqas+R+9+tXNDWVfIcaCLO0K2naiSo/NlqmOu8iGYs5baYbrF9g9zVcLv7tCuWLS0ur2xRo9i3Eoq3ZNyXYzJrnbGtA96qsAhoc0NhgD62Z0VjQzbbmyyUKz1zJ+98mzNVMaLo53R68p0ov0tKTUzVHcbOyqiKrEhVCgDpU3Zs8Y+B81leQVXWbReavxBdEZubMiMSqszMzAAkkk9S25V7X63pulBbftHeenht9lxYX8kzZrjGM2SsHgWVZ/YCjM2chbJXQoHqo/GYSj8rZSnBq+O+0VqbrOdW03v/aBDFH7IlpTNmJ11EdQXaTbGS5wlk1sRWwnAmV0J6k6+xdKF+L6cSzRVl0zsvU9VwMS1dfnFM2UssQlpUB4mrNGVBZKSJVVcr/yOxO+zKCrpdV700bSc/MxIdt1ucK1sTzZGs5JFBF/Ga/riTBWLqrlRbZgCN1Zg6atf7Hfjt0ubJu7755NAPFLGzX3A8di1p6NE8QYU2z6LoxSuOZh4rY1QpKOWric/CVeQ6q668yPVmXYOQk0Ua4uyqFHLR8Z29evbHI3Y/wCn1v8Aew+gJv3Zj3tSx0bgatz4pqdwq8veyhsdmAH8BZiP6zH2f//Z',
            },
          },
        },
      },
    ],
    btnTitle: 'Alle Produkte',
  },
  {
    title: 'Technik/Qualitat',
    slug: 'technikqualitat',
    order: 2,
    subPages: [
      {
        title: 'Hölzer',
        menuTitle: 'Hölzer',
        slug: 'holzer',
        featuredImage: {
          title: 'Hero Background',
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=380&h=419&q=100&fm=webp 380w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=512&h=565&q=100&fm=webp 512w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=648&h=715&q=100&fm=webp 648w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=720&h=794&q=100&fm=webp 720w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=959&h=1058&q=100&fm=webp 959w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=2883&h=3180&q=100&fm=webp 2883w',
                  sizes:
                    '(max-width: 768px) 380px, (max-width:1023px) 648px, (max-width: 1439px) 512px, (max-width: 1919px) 720px, 959px',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src:
                  'https://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=2883&h=3180&fl=progressive&q=100&fm=jpg',
                srcSet:
                  'https://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=380&h=419&fl=progressive&q=100&fm=jpg 380w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=512&h=565&fl=progressive&q=100&fm=jpg 512w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=648&h=715&fl=progressive&q=100&fm=jpg 648w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=720&h=794&fl=progressive&q=100&fm=jpg 720w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=959&h=1058&fl=progressive&q=100&fm=jpg 959w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=2883&h=3180&fl=progressive&q=100&fm=jpg 2883w',
                sizes:
                  '(max-width: 768px) 380px, (max-width:1023px) 648px, (max-width: 1439px) 512px, (max-width: 1919px) 720px, 959px',
              },
            },
            layout: 'constrained',
            width: 2883,
            height: 3180,
            placeholder: {
              fallback:
                'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAlgCWAAD/2wBDAAEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQH/2wBDAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQH/wAARCAAWABQDASEAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAgJBgr/xAAjEAACAgMAAQQDAQAAAAAAAAAEBQIDAQYHCAAJEhQRExUW/8QAFwEBAQEBAAAAAAAAAAAAAAAABgUEB//EACURAAMAAQMEAgMBAQAAAAAAAAECAwQFERIABhMhIjEHFEFRM//aAAwDAQACEQMRAD8A6CfGHwK5X1DnuNzc7f0ZW+IcHUTTotlS/wA+scYcCcSahHWvvGMfs2k2W25ybIXE7P1j0j0RhVBjzvAfSF604RLvPSAT7hbaA2Jl2ns4BX2wzCsv6n+QEgVOnOcW11XWYonOMcXQtr+dc4ehdhaRqGDiZORqWsRyb4sasq2w/Cr0RW+KUwWdohiQF8vLiNjTl8uq+p9552HmZGMmm6U8Y2MloUzfO6JsAzMmeJLUj5EiPDkdxMAbdT77R4bIle2U0S2JsxnIEyWb7jSF1uIVbNsQdELKE8Rg5WyGFpuutjTCUrrrI4jGqFUYnoHm9qiWXec8liiUKqWmCxA/rHj7J+zsAP8AAB66VY/cjtGbGABZQdlZ+IB9gL8/oDYDf2R7JJ99Sn4z528n1jaXE96690l3Uq1bZ9ayp2jl/RF4YuwF9GRvV11ounpGakTOg6kAx0URoIjPe7kroXHO9mXSrJDYvxx/znM28da6Hf8Ac+K6Lcyw009VsBFLY3b+csoWsNZ3OK/VtjcC6yNsycxQYvSu85fAV1shnWrrrigCR3mm/mXUNG7djpZwtN1DA0/hGb5mK2fknyYNsWBx52cRmsKznmbJRSAGQSDUKGTk/i+mqas+R+9+tXNDWVfIcaCLO0K2naiSo/NlqmOu8iGYs5baYbrF9g9zVcLv7tCuWLS0ur2xRo9i3Eoq3ZNyXYzJrnbGtA96qsAhoc0NhgD62Z0VjQzbbmyyUKz1zJ+98mzNVMaLo53R68p0ov0tKTUzVHcbOyqiKrEhVCgDpU3Zs8Y+B81leQVXWbReavxBdEZubMiMSqszMzAAkkk9S25V7X63pulBbftHeenht9lxYX8kzZrjGM2SsHgWVZ/YCjM2chbJXQoHqo/GYSj8rZSnBq+O+0VqbrOdW03v/aBDFH7IlpTNmJ11EdQXaTbGS5wlk1sRWwnAmV0J6k6+xdKF+L6cSzRVl0zsvU9VwMS1dfnFM2UssQlpUB4mrNGVBZKSJVVcr/yOxO+zKCrpdV700bSc/MxIdt1ucK1sTzZGs5JFBF/Ga/riTBWLqrlRbZgCN1Zg6atf7Hfjt0ubJu7755NAPFLGzX3A8di1p6NE8QYU2z6LoxSuOZh4rY1QpKOWric/CVeQ6q668yPVmXYOQk0Ua4uyqFHLR8Z29evbHI3Y/wCn1v8Aew+gJv3Zj3tSx0bgatz4pqdwq8veyhsdmAH8BZiP6zH2f//Z',
            },
          },
        },
      },
      {
        title: 'Farben & Oberflächen',
        menuTitle: 'Farben & Oberflächen',
        slug: 'farben-and-oberflachen',
        featuredImage: {
          title: 'Hero Background',
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=380&h=419&q=100&fm=webp 380w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=512&h=565&q=100&fm=webp 512w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=648&h=715&q=100&fm=webp 648w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=720&h=794&q=100&fm=webp 720w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=959&h=1058&q=100&fm=webp 959w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=2883&h=3180&q=100&fm=webp 2883w',
                  sizes:
                    '(max-width: 768px) 380px, (max-width:1023px) 648px, (max-width: 1439px) 512px, (max-width: 1919px) 720px, 959px',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src:
                  'https://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=2883&h=3180&fl=progressive&q=100&fm=jpg',
                srcSet:
                  'https://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=380&h=419&fl=progressive&q=100&fm=jpg 380w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=512&h=565&fl=progressive&q=100&fm=jpg 512w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=648&h=715&fl=progressive&q=100&fm=jpg 648w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=720&h=794&fl=progressive&q=100&fm=jpg 720w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=959&h=1058&fl=progressive&q=100&fm=jpg 959w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=2883&h=3180&fl=progressive&q=100&fm=jpg 2883w',
                sizes:
                  '(max-width: 768px) 380px, (max-width:1023px) 648px, (max-width: 1439px) 512px, (max-width: 1919px) 720px, 959px',
              },
            },
            layout: 'constrained',
            width: 2883,
            height: 3180,
            placeholder: {
              fallback:
                'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAlgCWAAD/2wBDAAEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQH/2wBDAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQH/wAARCAAWABQDASEAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAgJBgr/xAAjEAACAgMAAQQDAQAAAAAAAAAEBQIDAQYHCAAJEhQRExUW/8QAFwEBAQEBAAAAAAAAAAAAAAAABgUEB//EACURAAMAAQMEAgMBAQAAAAAAAAECAwQFERIABhMhIjEHFEFRM//aAAwDAQACEQMRAD8A6CfGHwK5X1DnuNzc7f0ZW+IcHUTTotlS/wA+scYcCcSahHWvvGMfs2k2W25ybIXE7P1j0j0RhVBjzvAfSF604RLvPSAT7hbaA2Jl2ns4BX2wzCsv6n+QEgVOnOcW11XWYonOMcXQtr+dc4ehdhaRqGDiZORqWsRyb4sasq2w/Cr0RW+KUwWdohiQF8vLiNjTl8uq+p9552HmZGMmm6U8Y2MloUzfO6JsAzMmeJLUj5EiPDkdxMAbdT77R4bIle2U0S2JsxnIEyWb7jSF1uIVbNsQdELKE8Rg5WyGFpuutjTCUrrrI4jGqFUYnoHm9qiWXec8liiUKqWmCxA/rHj7J+zsAP8AAB66VY/cjtGbGABZQdlZ+IB9gL8/oDYDf2R7JJ99Sn4z528n1jaXE96690l3Uq1bZ9ayp2jl/RF4YuwF9GRvV11ounpGakTOg6kAx0URoIjPe7kroXHO9mXSrJDYvxx/znM28da6Hf8Ac+K6Lcyw009VsBFLY3b+csoWsNZ3OK/VtjcC6yNsycxQYvSu85fAV1shnWrrrigCR3mm/mXUNG7djpZwtN1DA0/hGb5mK2fknyYNsWBx52cRmsKznmbJRSAGQSDUKGTk/i+mqas+R+9+tXNDWVfIcaCLO0K2naiSo/NlqmOu8iGYs5baYbrF9g9zVcLv7tCuWLS0ur2xRo9i3Eoq3ZNyXYzJrnbGtA96qsAhoc0NhgD62Z0VjQzbbmyyUKz1zJ+98mzNVMaLo53R68p0ov0tKTUzVHcbOyqiKrEhVCgDpU3Zs8Y+B81leQVXWbReavxBdEZubMiMSqszMzAAkkk9S25V7X63pulBbftHeenht9lxYX8kzZrjGM2SsHgWVZ/YCjM2chbJXQoHqo/GYSj8rZSnBq+O+0VqbrOdW03v/aBDFH7IlpTNmJ11EdQXaTbGS5wlk1sRWwnAmV0J6k6+xdKF+L6cSzRVl0zsvU9VwMS1dfnFM2UssQlpUB4mrNGVBZKSJVVcr/yOxO+zKCrpdV700bSc/MxIdt1ucK1sTzZGs5JFBF/Ga/riTBWLqrlRbZgCN1Zg6atf7Hfjt0ubJu7755NAPFLGzX3A8di1p6NE8QYU2z6LoxSuOZh4rY1QpKOWric/CVeQ6q668yPVmXYOQk0Ua4uyqFHLR8Z29evbHI3Y/wCn1v8Aew+gJv3Zj3tSx0bgatz4pqdwq8veyhsdmAH8BZiP6zH2f//Z',
            },
          },
        },
      },
      {
        title: 'Sicherheit',
        menuTitle: 'Sicherheit',
        slug: 'sicherheit',
        featuredImage: {
          title: 'Hero Background',
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=380&h=419&q=100&fm=webp 380w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=512&h=565&q=100&fm=webp 512w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=648&h=715&q=100&fm=webp 648w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=720&h=794&q=100&fm=webp 720w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=959&h=1058&q=100&fm=webp 959w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=2883&h=3180&q=100&fm=webp 2883w',
                  sizes:
                    '(max-width: 768px) 380px, (max-width:1023px) 648px, (max-width: 1439px) 512px, (max-width: 1919px) 720px, 959px',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src:
                  'https://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=2883&h=3180&fl=progressive&q=100&fm=jpg',
                srcSet:
                  'https://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=380&h=419&fl=progressive&q=100&fm=jpg 380w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=512&h=565&fl=progressive&q=100&fm=jpg 512w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=648&h=715&fl=progressive&q=100&fm=jpg 648w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=720&h=794&fl=progressive&q=100&fm=jpg 720w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=959&h=1058&fl=progressive&q=100&fm=jpg 959w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=2883&h=3180&fl=progressive&q=100&fm=jpg 2883w',
                sizes:
                  '(max-width: 768px) 380px, (max-width:1023px) 648px, (max-width: 1439px) 512px, (max-width: 1919px) 720px, 959px',
              },
            },
            layout: 'constrained',
            width: 2883,
            height: 3180,
            placeholder: {
              fallback:
                'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAlgCWAAD/2wBDAAEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQH/2wBDAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQH/wAARCAAWABQDASEAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAgJBgr/xAAjEAACAgMAAQQDAQAAAAAAAAAEBQIDAQYHCAAJEhQRExUW/8QAFwEBAQEBAAAAAAAAAAAAAAAABgUEB//EACURAAMAAQMEAgMBAQAAAAAAAAECAwQFERIABhMhIjEHFEFRM//aAAwDAQACEQMRAD8A6CfGHwK5X1DnuNzc7f0ZW+IcHUTTotlS/wA+scYcCcSahHWvvGMfs2k2W25ybIXE7P1j0j0RhVBjzvAfSF604RLvPSAT7hbaA2Jl2ns4BX2wzCsv6n+QEgVOnOcW11XWYonOMcXQtr+dc4ehdhaRqGDiZORqWsRyb4sasq2w/Cr0RW+KUwWdohiQF8vLiNjTl8uq+p9552HmZGMmm6U8Y2MloUzfO6JsAzMmeJLUj5EiPDkdxMAbdT77R4bIle2U0S2JsxnIEyWb7jSF1uIVbNsQdELKE8Rg5WyGFpuutjTCUrrrI4jGqFUYnoHm9qiWXec8liiUKqWmCxA/rHj7J+zsAP8AAB66VY/cjtGbGABZQdlZ+IB9gL8/oDYDf2R7JJ99Sn4z528n1jaXE96690l3Uq1bZ9ayp2jl/RF4YuwF9GRvV11ounpGakTOg6kAx0URoIjPe7kroXHO9mXSrJDYvxx/znM28da6Hf8Ac+K6Lcyw009VsBFLY3b+csoWsNZ3OK/VtjcC6yNsycxQYvSu85fAV1shnWrrrigCR3mm/mXUNG7djpZwtN1DA0/hGb5mK2fknyYNsWBx52cRmsKznmbJRSAGQSDUKGTk/i+mqas+R+9+tXNDWVfIcaCLO0K2naiSo/NlqmOu8iGYs5baYbrF9g9zVcLv7tCuWLS0ur2xRo9i3Eoq3ZNyXYzJrnbGtA96qsAhoc0NhgD62Z0VjQzbbmyyUKz1zJ+98mzNVMaLo53R68p0ov0tKTUzVHcbOyqiKrEhVCgDpU3Zs8Y+B81leQVXWbReavxBdEZubMiMSqszMzAAkkk9S25V7X63pulBbftHeenht9lxYX8kzZrjGM2SsHgWVZ/YCjM2chbJXQoHqo/GYSj8rZSnBq+O+0VqbrOdW03v/aBDFH7IlpTNmJ11EdQXaTbGS5wlk1sRWwnAmV0J6k6+xdKF+L6cSzRVl0zsvU9VwMS1dfnFM2UssQlpUB4mrNGVBZKSJVVcr/yOxO+zKCrpdV700bSc/MxIdt1ucK1sTzZGs5JFBF/Ga/riTBWLqrlRbZgCN1Zg6atf7Hfjt0ubJu7755NAPFLGzX3A8di1p6NE8QYU2z6LoxSuOZh4rY1QpKOWric/CVeQ6q668yPVmXYOQk0Ua4uyqFHLR8Z29evbHI3Y/wCn1v8Aew+gJv3Zj3tSx0bgatz4pqdwq8veyhsdmAH8BZiP6zH2f//Z',
            },
          },
        },
      },
      {
        title: 'Nachhatigkeit',
        menuTitle: 'Nachhatigkeit',
        slug: 'nachhatigkeit',
        featuredImage: {
          title: 'Hero Background',
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=380&h=419&q=100&fm=webp 380w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=512&h=565&q=100&fm=webp 512w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=648&h=715&q=100&fm=webp 648w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=720&h=794&q=100&fm=webp 720w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=959&h=1058&q=100&fm=webp 959w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=2883&h=3180&q=100&fm=webp 2883w',
                  sizes:
                    '(max-width: 768px) 380px, (max-width:1023px) 648px, (max-width: 1439px) 512px, (max-width: 1919px) 720px, 959px',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src:
                  'https://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=2883&h=3180&fl=progressive&q=100&fm=jpg',
                srcSet:
                  'https://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=380&h=419&fl=progressive&q=100&fm=jpg 380w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=512&h=565&fl=progressive&q=100&fm=jpg 512w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=648&h=715&fl=progressive&q=100&fm=jpg 648w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=720&h=794&fl=progressive&q=100&fm=jpg 720w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=959&h=1058&fl=progressive&q=100&fm=jpg 959w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=2883&h=3180&fl=progressive&q=100&fm=jpg 2883w',
                sizes:
                  '(max-width: 768px) 380px, (max-width:1023px) 648px, (max-width: 1439px) 512px, (max-width: 1919px) 720px, 959px',
              },
            },
            layout: 'constrained',
            width: 2883,
            height: 3180,
            placeholder: {
              fallback:
                'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAlgCWAAD/2wBDAAEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQH/2wBDAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQH/wAARCAAWABQDASEAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAgJBgr/xAAjEAACAgMAAQQDAQAAAAAAAAAEBQIDAQYHCAAJEhQRExUW/8QAFwEBAQEBAAAAAAAAAAAAAAAABgUEB//EACURAAMAAQMEAgMBAQAAAAAAAAECAwQFERIABhMhIjEHFEFRM//aAAwDAQACEQMRAD8A6CfGHwK5X1DnuNzc7f0ZW+IcHUTTotlS/wA+scYcCcSahHWvvGMfs2k2W25ybIXE7P1j0j0RhVBjzvAfSF604RLvPSAT7hbaA2Jl2ns4BX2wzCsv6n+QEgVOnOcW11XWYonOMcXQtr+dc4ehdhaRqGDiZORqWsRyb4sasq2w/Cr0RW+KUwWdohiQF8vLiNjTl8uq+p9552HmZGMmm6U8Y2MloUzfO6JsAzMmeJLUj5EiPDkdxMAbdT77R4bIle2U0S2JsxnIEyWb7jSF1uIVbNsQdELKE8Rg5WyGFpuutjTCUrrrI4jGqFUYnoHm9qiWXec8liiUKqWmCxA/rHj7J+zsAP8AAB66VY/cjtGbGABZQdlZ+IB9gL8/oDYDf2R7JJ99Sn4z528n1jaXE96690l3Uq1bZ9ayp2jl/RF4YuwF9GRvV11ounpGakTOg6kAx0URoIjPe7kroXHO9mXSrJDYvxx/znM28da6Hf8Ac+K6Lcyw009VsBFLY3b+csoWsNZ3OK/VtjcC6yNsycxQYvSu85fAV1shnWrrrigCR3mm/mXUNG7djpZwtN1DA0/hGb5mK2fknyYNsWBx52cRmsKznmbJRSAGQSDUKGTk/i+mqas+R+9+tXNDWVfIcaCLO0K2naiSo/NlqmOu8iGYs5baYbrF9g9zVcLv7tCuWLS0ur2xRo9i3Eoq3ZNyXYzJrnbGtA96qsAhoc0NhgD62Z0VjQzbbmyyUKz1zJ+98mzNVMaLo53R68p0ov0tKTUzVHcbOyqiKrEhVCgDpU3Zs8Y+B81leQVXWbReavxBdEZubMiMSqszMzAAkkk9S25V7X63pulBbftHeenht9lxYX8kzZrjGM2SsHgWVZ/YCjM2chbJXQoHqo/GYSj8rZSnBq+O+0VqbrOdW03v/aBDFH7IlpTNmJ11EdQXaTbGS5wlk1sRWwnAmV0J6k6+xdKF+L6cSzRVl0zsvU9VwMS1dfnFM2UssQlpUB4mrNGVBZKSJVVcr/yOxO+zKCrpdV700bSc/MxIdt1ucK1sTzZGs5JFBF/Ga/riTBWLqrlRbZgCN1Zg6atf7Hfjt0ubJu7755NAPFLGzX3A8di1p6NE8QYU2z6LoxSuOZh4rY1QpKOWric/CVeQ6q668yPVmXYOQk0Ua4uyqFHLR8Z29evbHI3Y/wCn1v8Aew+gJv3Zj3tSx0bgatz4pqdwq8veyhsdmAH8BZiP6zH2f//Z',
            },
          },
        },
      },
    ],
  },
  {
    title: 'Referenzen',
    slug: 'referenzen',
    order: 3,
    subPages: [],
  },
  {
    title: 'Support/Service',
    slug: 'supportservice',
    order: 4,
    subPages: [
      {
        title: 'FAQS',
        menuTitle: 'FAQS',
        slug: 'faqs',
        featuredImage: {
          title: 'Hero Background',
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=380&h=419&q=100&fm=webp 380w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=512&h=565&q=100&fm=webp 512w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=648&h=715&q=100&fm=webp 648w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=720&h=794&q=100&fm=webp 720w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=959&h=1058&q=100&fm=webp 959w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=2883&h=3180&q=100&fm=webp 2883w',
                  sizes:
                    '(max-width: 768px) 380px, (max-width:1023px) 648px, (max-width: 1439px) 512px, (max-width: 1919px) 720px, 959px',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src:
                  'https://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=2883&h=3180&fl=progressive&q=100&fm=jpg',
                srcSet:
                  'https://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=380&h=419&fl=progressive&q=100&fm=jpg 380w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=512&h=565&fl=progressive&q=100&fm=jpg 512w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=648&h=715&fl=progressive&q=100&fm=jpg 648w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=720&h=794&fl=progressive&q=100&fm=jpg 720w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=959&h=1058&fl=progressive&q=100&fm=jpg 959w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=2883&h=3180&fl=progressive&q=100&fm=jpg 2883w',
                sizes:
                  '(max-width: 768px) 380px, (max-width:1023px) 648px, (max-width: 1439px) 512px, (max-width: 1919px) 720px, 959px',
              },
            },
            layout: 'constrained',
            width: 2883,
            height: 3180,
            placeholder: {
              fallback:
                'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAlgCWAAD/2wBDAAEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQH/2wBDAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQH/wAARCAAWABQDASEAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAgJBgr/xAAjEAACAgMAAQQDAQAAAAAAAAAEBQIDAQYHCAAJEhQRExUW/8QAFwEBAQEBAAAAAAAAAAAAAAAABgUEB//EACURAAMAAQMEAgMBAQAAAAAAAAECAwQFERIABhMhIjEHFEFRM//aAAwDAQACEQMRAD8A6CfGHwK5X1DnuNzc7f0ZW+IcHUTTotlS/wA+scYcCcSahHWvvGMfs2k2W25ybIXE7P1j0j0RhVBjzvAfSF604RLvPSAT7hbaA2Jl2ns4BX2wzCsv6n+QEgVOnOcW11XWYonOMcXQtr+dc4ehdhaRqGDiZORqWsRyb4sasq2w/Cr0RW+KUwWdohiQF8vLiNjTl8uq+p9552HmZGMmm6U8Y2MloUzfO6JsAzMmeJLUj5EiPDkdxMAbdT77R4bIle2U0S2JsxnIEyWb7jSF1uIVbNsQdELKE8Rg5WyGFpuutjTCUrrrI4jGqFUYnoHm9qiWXec8liiUKqWmCxA/rHj7J+zsAP8AAB66VY/cjtGbGABZQdlZ+IB9gL8/oDYDf2R7JJ99Sn4z528n1jaXE96690l3Uq1bZ9ayp2jl/RF4YuwF9GRvV11ounpGakTOg6kAx0URoIjPe7kroXHO9mXSrJDYvxx/znM28da6Hf8Ac+K6Lcyw009VsBFLY3b+csoWsNZ3OK/VtjcC6yNsycxQYvSu85fAV1shnWrrrigCR3mm/mXUNG7djpZwtN1DA0/hGb5mK2fknyYNsWBx52cRmsKznmbJRSAGQSDUKGTk/i+mqas+R+9+tXNDWVfIcaCLO0K2naiSo/NlqmOu8iGYs5baYbrF9g9zVcLv7tCuWLS0ur2xRo9i3Eoq3ZNyXYzJrnbGtA96qsAhoc0NhgD62Z0VjQzbbmyyUKz1zJ+98mzNVMaLo53R68p0ov0tKTUzVHcbOyqiKrEhVCgDpU3Zs8Y+B81leQVXWbReavxBdEZubMiMSqszMzAAkkk9S25V7X63pulBbftHeenht9lxYX8kzZrjGM2SsHgWVZ/YCjM2chbJXQoHqo/GYSj8rZSnBq+O+0VqbrOdW03v/aBDFH7IlpTNmJ11EdQXaTbGS5wlk1sRWwnAmV0J6k6+xdKF+L6cSzRVl0zsvU9VwMS1dfnFM2UssQlpUB4mrNGVBZKSJVVcr/yOxO+zKCrpdV700bSc/MxIdt1ucK1sTzZGs5JFBF/Ga/riTBWLqrlRbZgCN1Zg6atf7Hfjt0ubJu7755NAPFLGzX3A8di1p6NE8QYU2z6LoxSuOZh4rY1QpKOWric/CVeQ6q668yPVmXYOQk0Ua4uyqFHLR8Z29evbHI3Y/wCn1v8Aew+gJv3Zj3tSx0bgatz4pqdwq8veyhsdmAH8BZiP6zH2f//Z',
            },
          },
        },
      },
      {
        title: 'Förderungen',
        menuTitle: 'Förderungen',
        slug: 'forderungen',
        featuredImage: {
          title: 'Hero Background',
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=380&h=419&q=100&fm=webp 380w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=512&h=565&q=100&fm=webp 512w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=648&h=715&q=100&fm=webp 648w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=720&h=794&q=100&fm=webp 720w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=959&h=1058&q=100&fm=webp 959w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=2883&h=3180&q=100&fm=webp 2883w',
                  sizes:
                    '(max-width: 768px) 380px, (max-width:1023px) 648px, (max-width: 1439px) 512px, (max-width: 1919px) 720px, 959px',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src:
                  'https://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=2883&h=3180&fl=progressive&q=100&fm=jpg',
                srcSet:
                  'https://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=380&h=419&fl=progressive&q=100&fm=jpg 380w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=512&h=565&fl=progressive&q=100&fm=jpg 512w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=648&h=715&fl=progressive&q=100&fm=jpg 648w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=720&h=794&fl=progressive&q=100&fm=jpg 720w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=959&h=1058&fl=progressive&q=100&fm=jpg 959w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=2883&h=3180&fl=progressive&q=100&fm=jpg 2883w',
                sizes:
                  '(max-width: 768px) 380px, (max-width:1023px) 648px, (max-width: 1439px) 512px, (max-width: 1919px) 720px, 959px',
              },
            },
            layout: 'constrained',
            width: 2883,
            height: 3180,
            placeholder: {
              fallback:
                'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAlgCWAAD/2wBDAAEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQH/2wBDAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQH/wAARCAAWABQDASEAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAgJBgr/xAAjEAACAgMAAQQDAQAAAAAAAAAEBQIDAQYHCAAJEhQRExUW/8QAFwEBAQEBAAAAAAAAAAAAAAAABgUEB//EACURAAMAAQMEAgMBAQAAAAAAAAECAwQFERIABhMhIjEHFEFRM//aAAwDAQACEQMRAD8A6CfGHwK5X1DnuNzc7f0ZW+IcHUTTotlS/wA+scYcCcSahHWvvGMfs2k2W25ybIXE7P1j0j0RhVBjzvAfSF604RLvPSAT7hbaA2Jl2ns4BX2wzCsv6n+QEgVOnOcW11XWYonOMcXQtr+dc4ehdhaRqGDiZORqWsRyb4sasq2w/Cr0RW+KUwWdohiQF8vLiNjTl8uq+p9552HmZGMmm6U8Y2MloUzfO6JsAzMmeJLUj5EiPDkdxMAbdT77R4bIle2U0S2JsxnIEyWb7jSF1uIVbNsQdELKE8Rg5WyGFpuutjTCUrrrI4jGqFUYnoHm9qiWXec8liiUKqWmCxA/rHj7J+zsAP8AAB66VY/cjtGbGABZQdlZ+IB9gL8/oDYDf2R7JJ99Sn4z528n1jaXE96690l3Uq1bZ9ayp2jl/RF4YuwF9GRvV11ounpGakTOg6kAx0URoIjPe7kroXHO9mXSrJDYvxx/znM28da6Hf8Ac+K6Lcyw009VsBFLY3b+csoWsNZ3OK/VtjcC6yNsycxQYvSu85fAV1shnWrrrigCR3mm/mXUNG7djpZwtN1DA0/hGb5mK2fknyYNsWBx52cRmsKznmbJRSAGQSDUKGTk/i+mqas+R+9+tXNDWVfIcaCLO0K2naiSo/NlqmOu8iGYs5baYbrF9g9zVcLv7tCuWLS0ur2xRo9i3Eoq3ZNyXYzJrnbGtA96qsAhoc0NhgD62Z0VjQzbbmyyUKz1zJ+98mzNVMaLo53R68p0ov0tKTUzVHcbOyqiKrEhVCgDpU3Zs8Y+B81leQVXWbReavxBdEZubMiMSqszMzAAkkk9S25V7X63pulBbftHeenht9lxYX8kzZrjGM2SsHgWVZ/YCjM2chbJXQoHqo/GYSj8rZSnBq+O+0VqbrOdW03v/aBDFH7IlpTNmJ11EdQXaTbGS5wlk1sRWwnAmV0J6k6+xdKF+L6cSzRVl0zsvU9VwMS1dfnFM2UssQlpUB4mrNGVBZKSJVVcr/yOxO+zKCrpdV700bSc/MxIdt1ucK1sTzZGs5JFBF/Ga/riTBWLqrlRbZgCN1Zg6atf7Hfjt0ubJu7755NAPFLGzX3A8di1p6NE8QYU2z6LoxSuOZh4rY1QpKOWric/CVeQ6q668yPVmXYOQk0Ua4uyqFHLR8Z29evbHI3Y/wCn1v8Aew+gJv3Zj3tSx0bgatz4pqdwq8veyhsdmAH8BZiP6zH2f//Z',
            },
          },
        },
      },
      {
        title: 'Wartung & Pflege',
        menuTitle: 'Wartung & Pflege',
        slug: 'wartung-and-pflege',
        featuredImage: {
          title: 'Hero Background',
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=380&h=419&q=100&fm=webp 380w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=512&h=565&q=100&fm=webp 512w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=648&h=715&q=100&fm=webp 648w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=720&h=794&q=100&fm=webp 720w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=959&h=1058&q=100&fm=webp 959w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=2883&h=3180&q=100&fm=webp 2883w',
                  sizes:
                    '(max-width: 768px) 380px, (max-width:1023px) 648px, (max-width: 1439px) 512px, (max-width: 1919px) 720px, 959px',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src:
                  'https://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=2883&h=3180&fl=progressive&q=100&fm=jpg',
                srcSet:
                  'https://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=380&h=419&fl=progressive&q=100&fm=jpg 380w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=512&h=565&fl=progressive&q=100&fm=jpg 512w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=648&h=715&fl=progressive&q=100&fm=jpg 648w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=720&h=794&fl=progressive&q=100&fm=jpg 720w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=959&h=1058&fl=progressive&q=100&fm=jpg 959w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=2883&h=3180&fl=progressive&q=100&fm=jpg 2883w',
                sizes:
                  '(max-width: 768px) 380px, (max-width:1023px) 648px, (max-width: 1439px) 512px, (max-width: 1919px) 720px, 959px',
              },
            },
            layout: 'constrained',
            width: 2883,
            height: 3180,
            placeholder: {
              fallback:
                'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAlgCWAAD/2wBDAAEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQH/2wBDAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQH/wAARCAAWABQDASEAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAgJBgr/xAAjEAACAgMAAQQDAQAAAAAAAAAEBQIDAQYHCAAJEhQRExUW/8QAFwEBAQEBAAAAAAAAAAAAAAAABgUEB//EACURAAMAAQMEAgMBAQAAAAAAAAECAwQFERIABhMhIjEHFEFRM//aAAwDAQACEQMRAD8A6CfGHwK5X1DnuNzc7f0ZW+IcHUTTotlS/wA+scYcCcSahHWvvGMfs2k2W25ybIXE7P1j0j0RhVBjzvAfSF604RLvPSAT7hbaA2Jl2ns4BX2wzCsv6n+QEgVOnOcW11XWYonOMcXQtr+dc4ehdhaRqGDiZORqWsRyb4sasq2w/Cr0RW+KUwWdohiQF8vLiNjTl8uq+p9552HmZGMmm6U8Y2MloUzfO6JsAzMmeJLUj5EiPDkdxMAbdT77R4bIle2U0S2JsxnIEyWb7jSF1uIVbNsQdELKE8Rg5WyGFpuutjTCUrrrI4jGqFUYnoHm9qiWXec8liiUKqWmCxA/rHj7J+zsAP8AAB66VY/cjtGbGABZQdlZ+IB9gL8/oDYDf2R7JJ99Sn4z528n1jaXE96690l3Uq1bZ9ayp2jl/RF4YuwF9GRvV11ounpGakTOg6kAx0URoIjPe7kroXHO9mXSrJDYvxx/znM28da6Hf8Ac+K6Lcyw009VsBFLY3b+csoWsNZ3OK/VtjcC6yNsycxQYvSu85fAV1shnWrrrigCR3mm/mXUNG7djpZwtN1DA0/hGb5mK2fknyYNsWBx52cRmsKznmbJRSAGQSDUKGTk/i+mqas+R+9+tXNDWVfIcaCLO0K2naiSo/NlqmOu8iGYs5baYbrF9g9zVcLv7tCuWLS0ur2xRo9i3Eoq3ZNyXYzJrnbGtA96qsAhoc0NhgD62Z0VjQzbbmyyUKz1zJ+98mzNVMaLo53R68p0ov0tKTUzVHcbOyqiKrEhVCgDpU3Zs8Y+B81leQVXWbReavxBdEZubMiMSqszMzAAkkk9S25V7X63pulBbftHeenht9lxYX8kzZrjGM2SsHgWVZ/YCjM2chbJXQoHqo/GYSj8rZSnBq+O+0VqbrOdW03v/aBDFH7IlpTNmJ11EdQXaTbGS5wlk1sRWwnAmV0J6k6+xdKF+L6cSzRVl0zsvU9VwMS1dfnFM2UssQlpUB4mrNGVBZKSJVVcr/yOxO+zKCrpdV700bSc/MxIdt1ucK1sTzZGs5JFBF/Ga/riTBWLqrlRbZgCN1Zg6atf7Hfjt0ubJu7755NAPFLGzX3A8di1p6NE8QYU2z6LoxSuOZh4rY1QpKOWric/CVeQ6q668yPVmXYOQk0Ua4uyqFHLR8Z29evbHI3Y/wCn1v8Aew+gJv3Zj3tSx0bgatz4pqdwq8veyhsdmAH8BZiP6zH2f//Z',
            },
          },
        },
      },
      {
        title: 'Fensterwissen',
        menuTitle: 'Fensterwissen',
        slug: 'fensterwissen',
        featuredImage: {
          title: 'Hero Background',
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=380&h=419&q=100&fm=webp 380w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=512&h=565&q=100&fm=webp 512w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=648&h=715&q=100&fm=webp 648w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=720&h=794&q=100&fm=webp 720w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=959&h=1058&q=100&fm=webp 959w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=2883&h=3180&q=100&fm=webp 2883w',
                  sizes:
                    '(max-width: 768px) 380px, (max-width:1023px) 648px, (max-width: 1439px) 512px, (max-width: 1919px) 720px, 959px',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src:
                  'https://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=2883&h=3180&fl=progressive&q=100&fm=jpg',
                srcSet:
                  'https://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=380&h=419&fl=progressive&q=100&fm=jpg 380w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=512&h=565&fl=progressive&q=100&fm=jpg 512w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=648&h=715&fl=progressive&q=100&fm=jpg 648w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=720&h=794&fl=progressive&q=100&fm=jpg 720w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=959&h=1058&fl=progressive&q=100&fm=jpg 959w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=2883&h=3180&fl=progressive&q=100&fm=jpg 2883w',
                sizes:
                  '(max-width: 768px) 380px, (max-width:1023px) 648px, (max-width: 1439px) 512px, (max-width: 1919px) 720px, 959px',
              },
            },
            layout: 'constrained',
            width: 2883,
            height: 3180,
            placeholder: {
              fallback:
                'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAlgCWAAD/2wBDAAEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQH/2wBDAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQH/wAARCAAWABQDASEAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAgJBgr/xAAjEAACAgMAAQQDAQAAAAAAAAAEBQIDAQYHCAAJEhQRExUW/8QAFwEBAQEBAAAAAAAAAAAAAAAABgUEB//EACURAAMAAQMEAgMBAQAAAAAAAAECAwQFERIABhMhIjEHFEFRM//aAAwDAQACEQMRAD8A6CfGHwK5X1DnuNzc7f0ZW+IcHUTTotlS/wA+scYcCcSahHWvvGMfs2k2W25ybIXE7P1j0j0RhVBjzvAfSF604RLvPSAT7hbaA2Jl2ns4BX2wzCsv6n+QEgVOnOcW11XWYonOMcXQtr+dc4ehdhaRqGDiZORqWsRyb4sasq2w/Cr0RW+KUwWdohiQF8vLiNjTl8uq+p9552HmZGMmm6U8Y2MloUzfO6JsAzMmeJLUj5EiPDkdxMAbdT77R4bIle2U0S2JsxnIEyWb7jSF1uIVbNsQdELKE8Rg5WyGFpuutjTCUrrrI4jGqFUYnoHm9qiWXec8liiUKqWmCxA/rHj7J+zsAP8AAB66VY/cjtGbGABZQdlZ+IB9gL8/oDYDf2R7JJ99Sn4z528n1jaXE96690l3Uq1bZ9ayp2jl/RF4YuwF9GRvV11ounpGakTOg6kAx0URoIjPe7kroXHO9mXSrJDYvxx/znM28da6Hf8Ac+K6Lcyw009VsBFLY3b+csoWsNZ3OK/VtjcC6yNsycxQYvSu85fAV1shnWrrrigCR3mm/mXUNG7djpZwtN1DA0/hGb5mK2fknyYNsWBx52cRmsKznmbJRSAGQSDUKGTk/i+mqas+R+9+tXNDWVfIcaCLO0K2naiSo/NlqmOu8iGYs5baYbrF9g9zVcLv7tCuWLS0ur2xRo9i3Eoq3ZNyXYzJrnbGtA96qsAhoc0NhgD62Z0VjQzbbmyyUKz1zJ+98mzNVMaLo53R68p0ov0tKTUzVHcbOyqiKrEhVCgDpU3Zs8Y+B81leQVXWbReavxBdEZubMiMSqszMzAAkkk9S25V7X63pulBbftHeenht9lxYX8kzZrjGM2SsHgWVZ/YCjM2chbJXQoHqo/GYSj8rZSnBq+O+0VqbrOdW03v/aBDFH7IlpTNmJ11EdQXaTbGS5wlk1sRWwnAmV0J6k6+xdKF+L6cSzRVl0zsvU9VwMS1dfnFM2UssQlpUB4mrNGVBZKSJVVcr/yOxO+zKCrpdV700bSc/MxIdt1ucK1sTzZGs5JFBF/Ga/riTBWLqrlRbZgCN1Zg6atf7Hfjt0ubJu7755NAPFLGzX3A8di1p6NE8QYU2z6LoxSuOZh4rY1QpKOWric/CVeQ6q668yPVmXYOQk0Ua4uyqFHLR8Z29evbHI3Y/wCn1v8Aew+gJv3Zj3tSx0bgatz4pqdwq8veyhsdmAH8BZiP6zH2f//Z',
            },
          },
        },
      },
    ],
  },
  {
    title: 'Unternehmen',
    slug: 'unternehmen',
    order: 5,
    subPages: [
      {
        title: 'Tradition & Werte',
        menuTitle: 'Tradition & Werte',
        slug: 'tradition-and-werte',
        featuredImage: {
          title: 'Hero Background',
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=380&h=419&q=100&fm=webp 380w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=512&h=565&q=100&fm=webp 512w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=648&h=715&q=100&fm=webp 648w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=720&h=794&q=100&fm=webp 720w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=959&h=1058&q=100&fm=webp 959w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=2883&h=3180&q=100&fm=webp 2883w',
                  sizes:
                    '(max-width: 768px) 380px, (max-width:1023px) 648px, (max-width: 1439px) 512px, (max-width: 1919px) 720px, 959px',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src:
                  'https://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=2883&h=3180&fl=progressive&q=100&fm=jpg',
                srcSet:
                  'https://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=380&h=419&fl=progressive&q=100&fm=jpg 380w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=512&h=565&fl=progressive&q=100&fm=jpg 512w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=648&h=715&fl=progressive&q=100&fm=jpg 648w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=720&h=794&fl=progressive&q=100&fm=jpg 720w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=959&h=1058&fl=progressive&q=100&fm=jpg 959w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=2883&h=3180&fl=progressive&q=100&fm=jpg 2883w',
                sizes:
                  '(max-width: 768px) 380px, (max-width:1023px) 648px, (max-width: 1439px) 512px, (max-width: 1919px) 720px, 959px',
              },
            },
            layout: 'constrained',
            width: 2883,
            height: 3180,
            placeholder: {
              fallback:
                'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAlgCWAAD/2wBDAAEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQH/2wBDAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQH/wAARCAAWABQDASEAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAgJBgr/xAAjEAACAgMAAQQDAQAAAAAAAAAEBQIDAQYHCAAJEhQRExUW/8QAFwEBAQEBAAAAAAAAAAAAAAAABgUEB//EACURAAMAAQMEAgMBAQAAAAAAAAECAwQFERIABhMhIjEHFEFRM//aAAwDAQACEQMRAD8A6CfGHwK5X1DnuNzc7f0ZW+IcHUTTotlS/wA+scYcCcSahHWvvGMfs2k2W25ybIXE7P1j0j0RhVBjzvAfSF604RLvPSAT7hbaA2Jl2ns4BX2wzCsv6n+QEgVOnOcW11XWYonOMcXQtr+dc4ehdhaRqGDiZORqWsRyb4sasq2w/Cr0RW+KUwWdohiQF8vLiNjTl8uq+p9552HmZGMmm6U8Y2MloUzfO6JsAzMmeJLUj5EiPDkdxMAbdT77R4bIle2U0S2JsxnIEyWb7jSF1uIVbNsQdELKE8Rg5WyGFpuutjTCUrrrI4jGqFUYnoHm9qiWXec8liiUKqWmCxA/rHj7J+zsAP8AAB66VY/cjtGbGABZQdlZ+IB9gL8/oDYDf2R7JJ99Sn4z528n1jaXE96690l3Uq1bZ9ayp2jl/RF4YuwF9GRvV11ounpGakTOg6kAx0URoIjPe7kroXHO9mXSrJDYvxx/znM28da6Hf8Ac+K6Lcyw009VsBFLY3b+csoWsNZ3OK/VtjcC6yNsycxQYvSu85fAV1shnWrrrigCR3mm/mXUNG7djpZwtN1DA0/hGb5mK2fknyYNsWBx52cRmsKznmbJRSAGQSDUKGTk/i+mqas+R+9+tXNDWVfIcaCLO0K2naiSo/NlqmOu8iGYs5baYbrF9g9zVcLv7tCuWLS0ur2xRo9i3Eoq3ZNyXYzJrnbGtA96qsAhoc0NhgD62Z0VjQzbbmyyUKz1zJ+98mzNVMaLo53R68p0ov0tKTUzVHcbOyqiKrEhVCgDpU3Zs8Y+B81leQVXWbReavxBdEZubMiMSqszMzAAkkk9S25V7X63pulBbftHeenht9lxYX8kzZrjGM2SsHgWVZ/YCjM2chbJXQoHqo/GYSj8rZSnBq+O+0VqbrOdW03v/aBDFH7IlpTNmJ11EdQXaTbGS5wlk1sRWwnAmV0J6k6+xdKF+L6cSzRVl0zsvU9VwMS1dfnFM2UssQlpUB4mrNGVBZKSJVVcr/yOxO+zKCrpdV700bSc/MxIdt1ucK1sTzZGs5JFBF/Ga/riTBWLqrlRbZgCN1Zg6atf7Hfjt0ubJu7755NAPFLGzX3A8di1p6NE8QYU2z6LoxSuOZh4rY1QpKOWric/CVeQ6q668yPVmXYOQk0Ua4uyqFHLR8Z29evbHI3Y/wCn1v8Aew+gJv3Zj3tSx0bgatz4pqdwq8veyhsdmAH8BZiP6zH2f//Z',
            },
          },
        },
      },
      {
        title: 'Verantwortung',
        menuTitle: 'Verantwortung',
        slug: 'verantwortung',
        featuredImage: {
          title: 'Hero Background',
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=380&h=419&q=100&fm=webp 380w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=512&h=565&q=100&fm=webp 512w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=648&h=715&q=100&fm=webp 648w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=720&h=794&q=100&fm=webp 720w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=959&h=1058&q=100&fm=webp 959w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=2883&h=3180&q=100&fm=webp 2883w',
                  sizes:
                    '(max-width: 768px) 380px, (max-width:1023px) 648px, (max-width: 1439px) 512px, (max-width: 1919px) 720px, 959px',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src:
                  'https://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=2883&h=3180&fl=progressive&q=100&fm=jpg',
                srcSet:
                  'https://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=380&h=419&fl=progressive&q=100&fm=jpg 380w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=512&h=565&fl=progressive&q=100&fm=jpg 512w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=648&h=715&fl=progressive&q=100&fm=jpg 648w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=720&h=794&fl=progressive&q=100&fm=jpg 720w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=959&h=1058&fl=progressive&q=100&fm=jpg 959w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=2883&h=3180&fl=progressive&q=100&fm=jpg 2883w',
                sizes:
                  '(max-width: 768px) 380px, (max-width:1023px) 648px, (max-width: 1439px) 512px, (max-width: 1919px) 720px, 959px',
              },
            },
            layout: 'constrained',
            width: 2883,
            height: 3180,
            placeholder: {
              fallback:
                'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAlgCWAAD/2wBDAAEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQH/2wBDAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQH/wAARCAAWABQDASEAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAgJBgr/xAAjEAACAgMAAQQDAQAAAAAAAAAEBQIDAQYHCAAJEhQRExUW/8QAFwEBAQEBAAAAAAAAAAAAAAAABgUEB//EACURAAMAAQMEAgMBAQAAAAAAAAECAwQFERIABhMhIjEHFEFRM//aAAwDAQACEQMRAD8A6CfGHwK5X1DnuNzc7f0ZW+IcHUTTotlS/wA+scYcCcSahHWvvGMfs2k2W25ybIXE7P1j0j0RhVBjzvAfSF604RLvPSAT7hbaA2Jl2ns4BX2wzCsv6n+QEgVOnOcW11XWYonOMcXQtr+dc4ehdhaRqGDiZORqWsRyb4sasq2w/Cr0RW+KUwWdohiQF8vLiNjTl8uq+p9552HmZGMmm6U8Y2MloUzfO6JsAzMmeJLUj5EiPDkdxMAbdT77R4bIle2U0S2JsxnIEyWb7jSF1uIVbNsQdELKE8Rg5WyGFpuutjTCUrrrI4jGqFUYnoHm9qiWXec8liiUKqWmCxA/rHj7J+zsAP8AAB66VY/cjtGbGABZQdlZ+IB9gL8/oDYDf2R7JJ99Sn4z528n1jaXE96690l3Uq1bZ9ayp2jl/RF4YuwF9GRvV11ounpGakTOg6kAx0URoIjPe7kroXHO9mXSrJDYvxx/znM28da6Hf8Ac+K6Lcyw009VsBFLY3b+csoWsNZ3OK/VtjcC6yNsycxQYvSu85fAV1shnWrrrigCR3mm/mXUNG7djpZwtN1DA0/hGb5mK2fknyYNsWBx52cRmsKznmbJRSAGQSDUKGTk/i+mqas+R+9+tXNDWVfIcaCLO0K2naiSo/NlqmOu8iGYs5baYbrF9g9zVcLv7tCuWLS0ur2xRo9i3Eoq3ZNyXYzJrnbGtA96qsAhoc0NhgD62Z0VjQzbbmyyUKz1zJ+98mzNVMaLo53R68p0ov0tKTUzVHcbOyqiKrEhVCgDpU3Zs8Y+B81leQVXWbReavxBdEZubMiMSqszMzAAkkk9S25V7X63pulBbftHeenht9lxYX8kzZrjGM2SsHgWVZ/YCjM2chbJXQoHqo/GYSj8rZSnBq+O+0VqbrOdW03v/aBDFH7IlpTNmJ11EdQXaTbGS5wlk1sRWwnAmV0J6k6+xdKF+L6cSzRVl0zsvU9VwMS1dfnFM2UssQlpUB4mrNGVBZKSJVVcr/yOxO+zKCrpdV700bSc/MxIdt1ucK1sTzZGs5JFBF/Ga/riTBWLqrlRbZgCN1Zg6atf7Hfjt0ubJu7755NAPFLGzX3A8di1p6NE8QYU2z6LoxSuOZh4rY1QpKOWric/CVeQ6q668yPVmXYOQk0Ua4uyqFHLR8Z29evbHI3Y/wCn1v8Aew+gJv3Zj3tSx0bgatz4pqdwq8veyhsdmAH8BZiP6zH2f//Z',
            },
          },
        },
      },
      {
        title: 'News',
        menuTitle: 'News',
        slug: 'news',
        featuredImage: {
          title: 'Hero Background',
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=380&h=419&q=100&fm=webp 380w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=512&h=565&q=100&fm=webp 512w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=648&h=715&q=100&fm=webp 648w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=720&h=794&q=100&fm=webp 720w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=959&h=1058&q=100&fm=webp 959w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=2883&h=3180&q=100&fm=webp 2883w',
                  sizes:
                    '(max-width: 768px) 380px, (max-width:1023px) 648px, (max-width: 1439px) 512px, (max-width: 1919px) 720px, 959px',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src:
                  'https://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=2883&h=3180&fl=progressive&q=100&fm=jpg',
                srcSet:
                  'https://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=380&h=419&fl=progressive&q=100&fm=jpg 380w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=512&h=565&fl=progressive&q=100&fm=jpg 512w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=648&h=715&fl=progressive&q=100&fm=jpg 648w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=720&h=794&fl=progressive&q=100&fm=jpg 720w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=959&h=1058&fl=progressive&q=100&fm=jpg 959w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=2883&h=3180&fl=progressive&q=100&fm=jpg 2883w',
                sizes:
                  '(max-width: 768px) 380px, (max-width:1023px) 648px, (max-width: 1439px) 512px, (max-width: 1919px) 720px, 959px',
              },
            },
            layout: 'constrained',
            width: 2883,
            height: 3180,
            placeholder: {
              fallback:
                'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAlgCWAAD/2wBDAAEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQH/2wBDAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQH/wAARCAAWABQDASEAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAgJBgr/xAAjEAACAgMAAQQDAQAAAAAAAAAEBQIDAQYHCAAJEhQRExUW/8QAFwEBAQEBAAAAAAAAAAAAAAAABgUEB//EACURAAMAAQMEAgMBAQAAAAAAAAECAwQFERIABhMhIjEHFEFRM//aAAwDAQACEQMRAD8A6CfGHwK5X1DnuNzc7f0ZW+IcHUTTotlS/wA+scYcCcSahHWvvGMfs2k2W25ybIXE7P1j0j0RhVBjzvAfSF604RLvPSAT7hbaA2Jl2ns4BX2wzCsv6n+QEgVOnOcW11XWYonOMcXQtr+dc4ehdhaRqGDiZORqWsRyb4sasq2w/Cr0RW+KUwWdohiQF8vLiNjTl8uq+p9552HmZGMmm6U8Y2MloUzfO6JsAzMmeJLUj5EiPDkdxMAbdT77R4bIle2U0S2JsxnIEyWb7jSF1uIVbNsQdELKE8Rg5WyGFpuutjTCUrrrI4jGqFUYnoHm9qiWXec8liiUKqWmCxA/rHj7J+zsAP8AAB66VY/cjtGbGABZQdlZ+IB9gL8/oDYDf2R7JJ99Sn4z528n1jaXE96690l3Uq1bZ9ayp2jl/RF4YuwF9GRvV11ounpGakTOg6kAx0URoIjPe7kroXHO9mXSrJDYvxx/znM28da6Hf8Ac+K6Lcyw009VsBFLY3b+csoWsNZ3OK/VtjcC6yNsycxQYvSu85fAV1shnWrrrigCR3mm/mXUNG7djpZwtN1DA0/hGb5mK2fknyYNsWBx52cRmsKznmbJRSAGQSDUKGTk/i+mqas+R+9+tXNDWVfIcaCLO0K2naiSo/NlqmOu8iGYs5baYbrF9g9zVcLv7tCuWLS0ur2xRo9i3Eoq3ZNyXYzJrnbGtA96qsAhoc0NhgD62Z0VjQzbbmyyUKz1zJ+98mzNVMaLo53R68p0ov0tKTUzVHcbOyqiKrEhVCgDpU3Zs8Y+B81leQVXWbReavxBdEZubMiMSqszMzAAkkk9S25V7X63pulBbftHeenht9lxYX8kzZrjGM2SsHgWVZ/YCjM2chbJXQoHqo/GYSj8rZSnBq+O+0VqbrOdW03v/aBDFH7IlpTNmJ11EdQXaTbGS5wlk1sRWwnAmV0J6k6+xdKF+L6cSzRVl0zsvU9VwMS1dfnFM2UssQlpUB4mrNGVBZKSJVVcr/yOxO+zKCrpdV700bSc/MxIdt1ucK1sTzZGs5JFBF/Ga/riTBWLqrlRbZgCN1Zg6atf7Hfjt0ubJu7755NAPFLGzX3A8di1p6NE8QYU2z6LoxSuOZh4rY1QpKOWric/CVeQ6q668yPVmXYOQk0Ua4uyqFHLR8Z29evbHI3Y/wCn1v8Aew+gJv3Zj3tSx0bgatz4pqdwq8veyhsdmAH8BZiP6zH2f//Z',
            },
          },
        },
      },
      {
        title: 'Karriere',
        menuTitle: 'Karriere',
        slug: 'karriere',
        featuredImage: {
          title: 'Hero Background',
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=380&h=419&q=100&fm=webp 380w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=512&h=565&q=100&fm=webp 512w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=648&h=715&q=100&fm=webp 648w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=720&h=794&q=100&fm=webp 720w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=959&h=1058&q=100&fm=webp 959w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=2883&h=3180&q=100&fm=webp 2883w',
                  sizes:
                    '(max-width: 768px) 380px, (max-width:1023px) 648px, (max-width: 1439px) 512px, (max-width: 1919px) 720px, 959px',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src:
                  'https://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=2883&h=3180&fl=progressive&q=100&fm=jpg',
                srcSet:
                  'https://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=380&h=419&fl=progressive&q=100&fm=jpg 380w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=512&h=565&fl=progressive&q=100&fm=jpg 512w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=648&h=715&fl=progressive&q=100&fm=jpg 648w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=720&h=794&fl=progressive&q=100&fm=jpg 720w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=959&h=1058&fl=progressive&q=100&fm=jpg 959w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=2883&h=3180&fl=progressive&q=100&fm=jpg 2883w',
                sizes:
                  '(max-width: 768px) 380px, (max-width:1023px) 648px, (max-width: 1439px) 512px, (max-width: 1919px) 720px, 959px',
              },
            },
            layout: 'constrained',
            width: 2883,
            height: 3180,
            placeholder: {
              fallback:
                'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAlgCWAAD/2wBDAAEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQH/2wBDAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQH/wAARCAAWABQDASEAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAgJBgr/xAAjEAACAgMAAQQDAQAAAAAAAAAEBQIDAQYHCAAJEhQRExUW/8QAFwEBAQEBAAAAAAAAAAAAAAAABgUEB//EACURAAMAAQMEAgMBAQAAAAAAAAECAwQFERIABhMhIjEHFEFRM//aAAwDAQACEQMRAD8A6CfGHwK5X1DnuNzc7f0ZW+IcHUTTotlS/wA+scYcCcSahHWvvGMfs2k2W25ybIXE7P1j0j0RhVBjzvAfSF604RLvPSAT7hbaA2Jl2ns4BX2wzCsv6n+QEgVOnOcW11XWYonOMcXQtr+dc4ehdhaRqGDiZORqWsRyb4sasq2w/Cr0RW+KUwWdohiQF8vLiNjTl8uq+p9552HmZGMmm6U8Y2MloUzfO6JsAzMmeJLUj5EiPDkdxMAbdT77R4bIle2U0S2JsxnIEyWb7jSF1uIVbNsQdELKE8Rg5WyGFpuutjTCUrrrI4jGqFUYnoHm9qiWXec8liiUKqWmCxA/rHj7J+zsAP8AAB66VY/cjtGbGABZQdlZ+IB9gL8/oDYDf2R7JJ99Sn4z528n1jaXE96690l3Uq1bZ9ayp2jl/RF4YuwF9GRvV11ounpGakTOg6kAx0URoIjPe7kroXHO9mXSrJDYvxx/znM28da6Hf8Ac+K6Lcyw009VsBFLY3b+csoWsNZ3OK/VtjcC6yNsycxQYvSu85fAV1shnWrrrigCR3mm/mXUNG7djpZwtN1DA0/hGb5mK2fknyYNsWBx52cRmsKznmbJRSAGQSDUKGTk/i+mqas+R+9+tXNDWVfIcaCLO0K2naiSo/NlqmOu8iGYs5baYbrF9g9zVcLv7tCuWLS0ur2xRo9i3Eoq3ZNyXYzJrnbGtA96qsAhoc0NhgD62Z0VjQzbbmyyUKz1zJ+98mzNVMaLo53R68p0ov0tKTUzVHcbOyqiKrEhVCgDpU3Zs8Y+B81leQVXWbReavxBdEZubMiMSqszMzAAkkk9S25V7X63pulBbftHeenht9lxYX8kzZrjGM2SsHgWVZ/YCjM2chbJXQoHqo/GYSj8rZSnBq+O+0VqbrOdW03v/aBDFH7IlpTNmJ11EdQXaTbGS5wlk1sRWwnAmV0J6k6+xdKF+L6cSzRVl0zsvU9VwMS1dfnFM2UssQlpUB4mrNGVBZKSJVVcr/yOxO+zKCrpdV700bSc/MxIdt1ucK1sTzZGs5JFBF/Ga/riTBWLqrlRbZgCN1Zg6atf7Hfjt0ubJu7755NAPFLGzX3A8di1p6NE8QYU2z6LoxSuOZh4rY1QpKOWric/CVeQ6q668yPVmXYOQk0Ua4uyqFHLR8Z29evbHI3Y/wCn1v8Aew+gJv3Zj3tSx0bgatz4pqdwq8veyhsdmAH8BZiP6zH2f//Z',
            },
          },
        },
      },
    ],
  },
  {
    title: 'Für Profis',
    slug: 'fur-profis',
    order: 6,
    subPages: [
      {
        title: 'Technik & Systeme',
        menuTitle: 'Technik & Systeme',
        slug: 'technik-and-systeme',
        featuredImage: {
          title: 'Hero Background',
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=380&h=419&q=100&fm=webp 380w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=512&h=565&q=100&fm=webp 512w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=648&h=715&q=100&fm=webp 648w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=720&h=794&q=100&fm=webp 720w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=959&h=1058&q=100&fm=webp 959w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=2883&h=3180&q=100&fm=webp 2883w',
                  sizes:
                    '(max-width: 768px) 380px, (max-width:1023px) 648px, (max-width: 1439px) 512px, (max-width: 1919px) 720px, 959px',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src:
                  'https://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=2883&h=3180&fl=progressive&q=100&fm=jpg',
                srcSet:
                  'https://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=380&h=419&fl=progressive&q=100&fm=jpg 380w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=512&h=565&fl=progressive&q=100&fm=jpg 512w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=648&h=715&fl=progressive&q=100&fm=jpg 648w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=720&h=794&fl=progressive&q=100&fm=jpg 720w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=959&h=1058&fl=progressive&q=100&fm=jpg 959w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=2883&h=3180&fl=progressive&q=100&fm=jpg 2883w',
                sizes:
                  '(max-width: 768px) 380px, (max-width:1023px) 648px, (max-width: 1439px) 512px, (max-width: 1919px) 720px, 959px',
              },
            },
            layout: 'constrained',
            width: 2883,
            height: 3180,
            placeholder: {
              fallback:
                'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAlgCWAAD/2wBDAAEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQH/2wBDAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQH/wAARCAAWABQDASEAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAgJBgr/xAAjEAACAgMAAQQDAQAAAAAAAAAEBQIDAQYHCAAJEhQRExUW/8QAFwEBAQEBAAAAAAAAAAAAAAAABgUEB//EACURAAMAAQMEAgMBAQAAAAAAAAECAwQFERIABhMhIjEHFEFRM//aAAwDAQACEQMRAD8A6CfGHwK5X1DnuNzc7f0ZW+IcHUTTotlS/wA+scYcCcSahHWvvGMfs2k2W25ybIXE7P1j0j0RhVBjzvAfSF604RLvPSAT7hbaA2Jl2ns4BX2wzCsv6n+QEgVOnOcW11XWYonOMcXQtr+dc4ehdhaRqGDiZORqWsRyb4sasq2w/Cr0RW+KUwWdohiQF8vLiNjTl8uq+p9552HmZGMmm6U8Y2MloUzfO6JsAzMmeJLUj5EiPDkdxMAbdT77R4bIle2U0S2JsxnIEyWb7jSF1uIVbNsQdELKE8Rg5WyGFpuutjTCUrrrI4jGqFUYnoHm9qiWXec8liiUKqWmCxA/rHj7J+zsAP8AAB66VY/cjtGbGABZQdlZ+IB9gL8/oDYDf2R7JJ99Sn4z528n1jaXE96690l3Uq1bZ9ayp2jl/RF4YuwF9GRvV11ounpGakTOg6kAx0URoIjPe7kroXHO9mXSrJDYvxx/znM28da6Hf8Ac+K6Lcyw009VsBFLY3b+csoWsNZ3OK/VtjcC6yNsycxQYvSu85fAV1shnWrrrigCR3mm/mXUNG7djpZwtN1DA0/hGb5mK2fknyYNsWBx52cRmsKznmbJRSAGQSDUKGTk/i+mqas+R+9+tXNDWVfIcaCLO0K2naiSo/NlqmOu8iGYs5baYbrF9g9zVcLv7tCuWLS0ur2xRo9i3Eoq3ZNyXYzJrnbGtA96qsAhoc0NhgD62Z0VjQzbbmyyUKz1zJ+98mzNVMaLo53R68p0ov0tKTUzVHcbOyqiKrEhVCgDpU3Zs8Y+B81leQVXWbReavxBdEZubMiMSqszMzAAkkk9S25V7X63pulBbftHeenht9lxYX8kzZrjGM2SsHgWVZ/YCjM2chbJXQoHqo/GYSj8rZSnBq+O+0VqbrOdW03v/aBDFH7IlpTNmJ11EdQXaTbGS5wlk1sRWwnAmV0J6k6+xdKF+L6cSzRVl0zsvU9VwMS1dfnFM2UssQlpUB4mrNGVBZKSJVVcr/yOxO+zKCrpdV700bSc/MxIdt1ucK1sTzZGs5JFBF/Ga/riTBWLqrlRbZgCN1Zg6atf7Hfjt0ubJu7755NAPFLGzX3A8di1p6NE8QYU2z6LoxSuOZh4rY1QpKOWric/CVeQ6q668yPVmXYOQk0Ua4uyqFHLR8Z29evbHI3Y/wCn1v8Aew+gJv3Zj3tSx0bgatz4pqdwq8veyhsdmAH8BZiP6zH2f//Z',
            },
          },
        },
      },
      {
        title: 'Tools & Services',
        menuTitle: 'Tools & Services',
        slug: 'tools-and-services',
        featuredImage: {
          title: 'Hero Background',
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=380&h=419&q=100&fm=webp 380w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=512&h=565&q=100&fm=webp 512w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=648&h=715&q=100&fm=webp 648w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=720&h=794&q=100&fm=webp 720w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=959&h=1058&q=100&fm=webp 959w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=2883&h=3180&q=100&fm=webp 2883w',
                  sizes:
                    '(max-width: 768px) 380px, (max-width:1023px) 648px, (max-width: 1439px) 512px, (max-width: 1919px) 720px, 959px',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src:
                  'https://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=2883&h=3180&fl=progressive&q=100&fm=jpg',
                srcSet:
                  'https://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=380&h=419&fl=progressive&q=100&fm=jpg 380w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=512&h=565&fl=progressive&q=100&fm=jpg 512w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=648&h=715&fl=progressive&q=100&fm=jpg 648w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=720&h=794&fl=progressive&q=100&fm=jpg 720w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=959&h=1058&fl=progressive&q=100&fm=jpg 959w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=2883&h=3180&fl=progressive&q=100&fm=jpg 2883w',
                sizes:
                  '(max-width: 768px) 380px, (max-width:1023px) 648px, (max-width: 1439px) 512px, (max-width: 1919px) 720px, 959px',
              },
            },
            layout: 'constrained',
            width: 2883,
            height: 3180,
            placeholder: {
              fallback:
                'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAlgCWAAD/2wBDAAEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQH/2wBDAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQH/wAARCAAWABQDASEAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAgJBgr/xAAjEAACAgMAAQQDAQAAAAAAAAAEBQIDAQYHCAAJEhQRExUW/8QAFwEBAQEBAAAAAAAAAAAAAAAABgUEB//EACURAAMAAQMEAgMBAQAAAAAAAAECAwQFERIABhMhIjEHFEFRM//aAAwDAQACEQMRAD8A6CfGHwK5X1DnuNzc7f0ZW+IcHUTTotlS/wA+scYcCcSahHWvvGMfs2k2W25ybIXE7P1j0j0RhVBjzvAfSF604RLvPSAT7hbaA2Jl2ns4BX2wzCsv6n+QEgVOnOcW11XWYonOMcXQtr+dc4ehdhaRqGDiZORqWsRyb4sasq2w/Cr0RW+KUwWdohiQF8vLiNjTl8uq+p9552HmZGMmm6U8Y2MloUzfO6JsAzMmeJLUj5EiPDkdxMAbdT77R4bIle2U0S2JsxnIEyWb7jSF1uIVbNsQdELKE8Rg5WyGFpuutjTCUrrrI4jGqFUYnoHm9qiWXec8liiUKqWmCxA/rHj7J+zsAP8AAB66VY/cjtGbGABZQdlZ+IB9gL8/oDYDf2R7JJ99Sn4z528n1jaXE96690l3Uq1bZ9ayp2jl/RF4YuwF9GRvV11ounpGakTOg6kAx0URoIjPe7kroXHO9mXSrJDYvxx/znM28da6Hf8Ac+K6Lcyw009VsBFLY3b+csoWsNZ3OK/VtjcC6yNsycxQYvSu85fAV1shnWrrrigCR3mm/mXUNG7djpZwtN1DA0/hGb5mK2fknyYNsWBx52cRmsKznmbJRSAGQSDUKGTk/i+mqas+R+9+tXNDWVfIcaCLO0K2naiSo/NlqmOu8iGYs5baYbrF9g9zVcLv7tCuWLS0ur2xRo9i3Eoq3ZNyXYzJrnbGtA96qsAhoc0NhgD62Z0VjQzbbmyyUKz1zJ+98mzNVMaLo53R68p0ov0tKTUzVHcbOyqiKrEhVCgDpU3Zs8Y+B81leQVXWbReavxBdEZubMiMSqszMzAAkkk9S25V7X63pulBbftHeenht9lxYX8kzZrjGM2SsHgWVZ/YCjM2chbJXQoHqo/GYSj8rZSnBq+O+0VqbrOdW03v/aBDFH7IlpTNmJ11EdQXaTbGS5wlk1sRWwnAmV0J6k6+xdKF+L6cSzRVl0zsvU9VwMS1dfnFM2UssQlpUB4mrNGVBZKSJVVcr/yOxO+zKCrpdV700bSc/MxIdt1ucK1sTzZGs5JFBF/Ga/riTBWLqrlRbZgCN1Zg6atf7Hfjt0ubJu7755NAPFLGzX3A8di1p6NE8QYU2z6LoxSuOZh4rY1QpKOWric/CVeQ6q668yPVmXYOQk0Ua4uyqFHLR8Z29evbHI3Y/wCn1v8Aew+gJv3Zj3tSx0bgatz4pqdwq8veyhsdmAH8BZiP6zH2f//Z',
            },
          },
        },
      },
      {
        title: 'Partner Werden',
        menuTitle: 'Partner Werden',
        slug: 'partner-werden',
        featuredImage: {
          title: 'Hero Background',
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=380&h=419&q=100&fm=webp 380w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=512&h=565&q=100&fm=webp 512w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=648&h=715&q=100&fm=webp 648w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=720&h=794&q=100&fm=webp 720w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=959&h=1058&q=100&fm=webp 959w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=2883&h=3180&q=100&fm=webp 2883w',
                  sizes:
                    '(max-width: 768px) 380px, (max-width:1023px) 648px, (max-width: 1439px) 512px, (max-width: 1919px) 720px, 959px',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src:
                  'https://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=2883&h=3180&fl=progressive&q=100&fm=jpg',
                srcSet:
                  'https://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=380&h=419&fl=progressive&q=100&fm=jpg 380w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=512&h=565&fl=progressive&q=100&fm=jpg 512w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=648&h=715&fl=progressive&q=100&fm=jpg 648w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=720&h=794&fl=progressive&q=100&fm=jpg 720w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=959&h=1058&fl=progressive&q=100&fm=jpg 959w,\nhttps://images.ctfassets.net/5x2puvkokbjd/1KJrg3BJSye9UvY8Nz8Hqa/eab1dcacc6355de4a80cae3463d00218/70-b-0-c-1-c-3-b-1-fc-0080-ac-3139-fb-6-cdec-723_3x.jpg?w=2883&h=3180&fl=progressive&q=100&fm=jpg 2883w',
                sizes:
                  '(max-width: 768px) 380px, (max-width:1023px) 648px, (max-width: 1439px) 512px, (max-width: 1919px) 720px, 959px',
              },
            },
            layout: 'constrained',
            width: 2883,
            height: 3180,
            placeholder: {
              fallback:
                'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAlgCWAAD/2wBDAAEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQH/2wBDAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQH/wAARCAAWABQDASEAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAgJBgr/xAAjEAACAgMAAQQDAQAAAAAAAAAEBQIDAQYHCAAJEhQRExUW/8QAFwEBAQEBAAAAAAAAAAAAAAAABgUEB//EACURAAMAAQMEAgMBAQAAAAAAAAECAwQFERIABhMhIjEHFEFRM//aAAwDAQACEQMRAD8A6CfGHwK5X1DnuNzc7f0ZW+IcHUTTotlS/wA+scYcCcSahHWvvGMfs2k2W25ybIXE7P1j0j0RhVBjzvAfSF604RLvPSAT7hbaA2Jl2ns4BX2wzCsv6n+QEgVOnOcW11XWYonOMcXQtr+dc4ehdhaRqGDiZORqWsRyb4sasq2w/Cr0RW+KUwWdohiQF8vLiNjTl8uq+p9552HmZGMmm6U8Y2MloUzfO6JsAzMmeJLUj5EiPDkdxMAbdT77R4bIle2U0S2JsxnIEyWb7jSF1uIVbNsQdELKE8Rg5WyGFpuutjTCUrrrI4jGqFUYnoHm9qiWXec8liiUKqWmCxA/rHj7J+zsAP8AAB66VY/cjtGbGABZQdlZ+IB9gL8/oDYDf2R7JJ99Sn4z528n1jaXE96690l3Uq1bZ9ayp2jl/RF4YuwF9GRvV11ounpGakTOg6kAx0URoIjPe7kroXHO9mXSrJDYvxx/znM28da6Hf8Ac+K6Lcyw009VsBFLY3b+csoWsNZ3OK/VtjcC6yNsycxQYvSu85fAV1shnWrrrigCR3mm/mXUNG7djpZwtN1DA0/hGb5mK2fknyYNsWBx52cRmsKznmbJRSAGQSDUKGTk/i+mqas+R+9+tXNDWVfIcaCLO0K2naiSo/NlqmOu8iGYs5baYbrF9g9zVcLv7tCuWLS0ur2xRo9i3Eoq3ZNyXYzJrnbGtA96qsAhoc0NhgD62Z0VjQzbbmyyUKz1zJ+98mzNVMaLo53R68p0ov0tKTUzVHcbOyqiKrEhVCgDpU3Zs8Y+B81leQVXWbReavxBdEZubMiMSqszMzAAkkk9S25V7X63pulBbftHeenht9lxYX8kzZrjGM2SsHgWVZ/YCjM2chbJXQoHqo/GYSj8rZSnBq+O+0VqbrOdW03v/aBDFH7IlpTNmJ11EdQXaTbGS5wlk1sRWwnAmV0J6k6+xdKF+L6cSzRVl0zsvU9VwMS1dfnFM2UssQlpUB4mrNGVBZKSJVVcr/yOxO+zKCrpdV700bSc/MxIdt1ucK1sTzZGs5JFBF/Ga/riTBWLqrlRbZgCN1Zg6atf7Hfjt0ubJu7755NAPFLGzX3A8di1p6NE8QYU2z6LoxSuOZh4rY1QpKOWric/CVeQ6q668yPVmXYOQk0Ua4uyqFHLR8Z29evbHI3Y/wCn1v8Aew+gJv3Zj3tSx0bgatz4pqdwq8veyhsdmAH8BZiP6zH2f//Z',
            },
          },
        },
      },
    ],
  },
];
