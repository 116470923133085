import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import * as React from 'react';

import { ICustomGatsbyImageData } from '../../../utils/types';

import * as styles from './SimpleCard.module.less';

export interface ISimpleCardProps {
  name: string;
  position: string;
  image: ICustomGatsbyImageData;
  eMail?: string | null;
  phoneNumber?: string | null;
}

export const SimpleCard = ({
  name,
  position,
  image: imageProp,
  eMail,
  phoneNumber,
}: ISimpleCardProps): JSX.Element => {
  const image = getImage(imageProp.gatsbyImageData);
  return (
    <div className={styles.container}>
      {image && <GatsbyImage image={image} alt={imageProp.title} />}
      <h3>{name}</h3>
      <h4>{position}</h4>
      {eMail && <a href={`mailto:${eMail}`}>{eMail}</a>}
      {phoneNumber && <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>}
    </div>
  );
};
