import GatsbyLink from 'gatsby-link';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import * as React from 'react';

import { IContentfulLink } from '../../../utils/fragments/types';
import { ICustomGatsbyImageData } from '../../../utils/types';
import { Link } from '../../Clickable';
import * as styles from '../shared/card.module.less';

export interface ICardProps {
  title: string;
  description: { description: string };
  image: ICustomGatsbyImageData | null;
  internalLink: IContentfulLink;
  tags?: { title: string }[];
  date?: string;
}

export const Card = ({
  title,
  description,
  image: imageProp,
  internalLink,
  tags,
  date,
}: ICardProps): JSX.Element => {
  const image = imageProp && getImage(imageProp.gatsbyImageData);
  return (
    <div className={styles.container}>
      <div className={styles.image}>
        {image && imageProp && (
          <GatsbyLink to={internalLink.url}>
            <GatsbyImage image={image} alt={imageProp.title} />
          </GatsbyLink>
        )}
      </div>
      <div className={styles.contentContainer}>
        {tags && (
          <ul className={styles.tagsList}>
            {/* TODO: Make the tags clickable */}
            {React.Children.toArray(
              tags.map((item) => (
                <li>
                  <span data-testid="tag-item">{item.title}</span>
                </li>
              ))
            )}
          </ul>
        )}
        {date && (
          <span data-testid="card-date" className={styles.date}>
            {date}
          </span>
        )}
        <h3>{title}</h3>
        <p>{description?.description}</p>
        <Link href={internalLink.url} isDecorated>
          {internalLink.title}
        </Link>
      </div>
    </div>
  );
};
