import * as React from 'react';

import { ISimpleCardProps, SimpleCard } from '..';

import * as styles from './Team.module.less';

interface ITeamProps {
  title: string;
  members: ISimpleCardProps[];
}

export const Team = ({ title, members }: ITeamProps): JSX.Element => {
  return (
    <div className={styles.container} data-testid="team">
      <h2>{title}</h2>
      <div>
        {React.Children.toArray(
          members.map(({ name, position, image, eMail, phoneNumber }) => (
            <SimpleCard
              name={name}
              position={position}
              image={image}
              eMail={eMail}
              phoneNumber={phoneNumber}
            />
          ))
        )}
      </div>
    </div>
  );
};
