import { Modal } from 'antd';
import * as React from 'react';

import { IVideoCardProps, VideoCard } from '..';

import * as styles from './VideosContainer.module.less';

interface IVideosContainerProps {
  title: string;
  elements: IVideoCardProps[];
}

export const VideosContainer = ({
  title,
  elements,
}: IVideosContainerProps): JSX.Element => {
  const [videoID, setVideoId] = React.useState(elements[0].videoId);
  const [visible, setVisible] = React.useState(false);

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <div className={styles.container} data-testid="videoContainer">
      <h2>{title}</h2>
      <div>
        {elements.map((element) => (
          <VideoCard
            key={element.slug}
            videoId={element.videoId}
            title={element.title}
            description={element.description}
            setVideoId={setVideoId}
            setVisible={setVisible}
            internalLink={element.internalLink}
          />
        ))}
      </div>
      {visible && (
        <Modal
          wrapClassName={styles.modalContainer}
          className={styles.modal}
          visible={visible}
          onCancel={handleCancel}
          footer={null}
          centered={true}
          closable={true}
          width={'auto'}
        >
          <iframe
            title="Youtube video"
            src={`https://www.youtube.com/embed/${videoID}`}
            frameBorder="0"
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          />
        </Modal>
      )}
    </div>
  );
};
