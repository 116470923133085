import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import * as React from 'react';

import { ICustomGatsbyImageDataAsset } from '../../utils/types';

import * as styles from './TrustElements.module.less';

export interface ITrustElements {
  title?: string;
  elements: ICustomGatsbyImageDataAsset[];
}

export const TrustElements = ({
  title,
  elements,
}: ITrustElements): JSX.Element => {
  return (
    <div className={styles.container} data-testid="trustElements">
      <div className={styles.content}>
        {title && <h2>{title}</h2>}
        <div className={styles.elementsList}>
          {React.Children.toArray(
            elements.map((element) => {
              const image = getImage(element);
              return (
                image && (
                  <div>
                    <GatsbyImage image={image} alt={element.title} />
                  </div>
                )
              );
            })
          )}
        </div>
      </div>
    </div>
  );
};
