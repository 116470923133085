import * as React from 'react';

import { Link } from '../Clickable';

import { CustomCarousel } from './Carousel/Carousel';
import * as styles from './Slider.module.less';

interface ISliderProps {
  title: string;
  titleSize: 'medium' | 'large' | 'xlarge';
  paragraph?: string;
  children: React.ReactNode;
  link?: {
    title: string;
    url: string;
  };
  titleIsDecorated?: boolean;
  titleIsUpperCase?: boolean;
}

export const Slider = ({
  title,
  paragraph,
  children,
  link,
  titleSize,
  titleIsDecorated,
  titleIsUpperCase,
}: ISliderProps): JSX.Element => {
  // To be able to extract the colored word in the title because there
  // no selector to select the last word in css

  let symbol;
  const newTitle = title.substring(0, title.lastIndexOf(' '));

  let coloredWord = title.substring(title.lastIndexOf(' '));
  // to color the last symbol and deleted it from the colored word
  if (coloredWord.match(/[$-/:-?{-~!"^_`[\]]$/)) {
    symbol = coloredWord.match(/[$-/:-?{-~!"^_`[\]]$/);
    coloredWord = coloredWord.slice(0, -1);
  }

  return (
    <section
      data-testid="slider"
      className={`${styles.sliderContainer} ${
        titleIsUpperCase ? styles.decoratedContainer : ''
      }`}
    >
      <div
        className={`${styles.content} ${
          titleSize === 'xlarge' ? styles.intersected : ''
        }`}
      >
        <h2
          className={`${styles.title} ${styles[titleSize]} ${
            titleIsDecorated ? styles.decoratedTitle : ''
          } ${titleIsUpperCase ? styles.upperCase : ''}  `}
        >
          {titleIsDecorated ? newTitle : title}
          {titleIsDecorated && <span>{coloredWord}</span>}
          {symbol}
        </h2>
        {paragraph && <p>{paragraph}</p>}
      </div>

      <CustomCarousel>{children}</CustomCarousel>
      {link && (
        <Link href={link.url} variant="primary">
          {link.title}
        </Link>
      )}
    </section>
  );
};
