import * as React from 'react';

import { WidgetCardsContainer } from '../../WidgetCardsContainer';
import * as styles from '../FensterTurenWidget.module.less';
import iconConsultation from '../img/pictogram-beratung-64.svg';
import iconGlazingBars from '../img/pictogram-ohne-sprossen-64.svg';
import iconSprout from '../img/pictogram-sprossen-64.svg';

interface IGlazingBarsProps {
  onSelect: (element: string) => void;
  selectedItem: string;
}

export const GlazingBars: React.FunctionComponent<IGlazingBarsProps> = ({
  onSelect,
  selectedItem,
}) => {
  const onItemClick = (titles: string[]) => {
    onSelect(titles[0] || '');
  };

  return (
    <div>
      <div className={styles.question}>Wünschen Sie Fenster mit Sprossen?</div>
      <WidgetCardsContainer
        elements={[
          {
            title: 'Sprossen',
            icon: iconSprout,
            selected: 'Sprossen' === selectedItem,
          },
          {
            title: 'Ohne Sprossen',
            icon: iconGlazingBars,
            selected: 'Ohne Sprossen' === selectedItem,
          },
          {
            title: 'suche Beratung',
            icon: iconConsultation,
            selected: 'suche Beratung' === selectedItem,
          },
        ]}
        selectedItems={selectedItem ? [selectedItem] : []}
        setSelectedItems={onItemClick}
      />
    </div>
  );
};
