import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import * as React from 'react';

import { ICustomGatsbyImageData } from '../../utils/types';

import * as styles from './PartnerImage.module.less';

interface IPartnerProps {
  image: ICustomGatsbyImageData;
  description?: {
    description: string;
  };
}

export const PartnerImage = ({
  image,
  description,
}: IPartnerProps): JSX.Element => {
  const featuredImage = image && getImage(image.gatsbyImageData);
  return (
    <div className={styles.container} data-testid="partnerImage">
      <div>
        {featuredImage && (
          <GatsbyImage image={featuredImage} alt={image.title} />
        )}
        {description && <p>{description.description}</p>}
      </div>
    </div>
  );
};
