import { INLINES } from '@contentful/rich-text-types';
import { Collapse } from 'antd';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import * as React from 'react';

import arrowIcon from '../../images/arrow-icon.svg';
import { ICustomGatsbyImageData } from '../../utils/types';

import * as styles from './Accordion.module.less';

const { Panel } = Collapse;

export interface IAccordionProps {
  title: string;
  description?: { description: string } | null;
  elements: {
    title: string;
    textContent: { raw: string } | null;
    links: { title: string; url: string | null }[] | null;
    featuredImage: ICustomGatsbyImageData | null;
  }[];
  tanned?: boolean | null;
}

// https://stackoverflow.com/a/15734347
export function isUrlOrMailTo(str: string): boolean {
  const mailToPattern = /^mailto:/;
  const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;
  return urlPattern.test(str) || mailToPattern.test(str);
}

export const Accordion = ({
  title,
  description,
  elements,
  tanned,
}: IAccordionProps): JSX.Element => {
  const renderOptions = {
    renderNode: {
      [INLINES.HYPERLINK]: (node, children) => {
        const { uri } = node.data;
        const href = isUrlOrMailTo(uri) ? uri : `/${uri}`;
        return (
          <a href={href} rel="noreferrer noopener">
            {children}
          </a>
        );
      },
    },
  };

  return (
    <div
      className={`${styles.container} ${tanned ? styles.tanned : ''}`}
      data-testid="accordion"
    >
      <div className={styles.head}>
        <h2>{title}</h2>
        {description && (
          <p data-testid="accordion-paragraph">{description.description}</p>
        )}
      </div>
      <div className={styles.body}>
        <Collapse
          className={styles.accordion}
          accordion
          defaultActiveKey={['0']}
          expandIconPosition="right"
          expandIcon={({ isActive }) => (
            <img
              // pass the scoped classname to the accordion
              className={`${isActive ? 'rotated' : ''}`}
              src={arrowIcon}
              alt="expand-icon"
            />
          )}
          ghost
        >
          {React.Children.toArray(
            elements.map(
              ({ title, featuredImage, textContent, links }, index) => {
                let treatedImage;
                if (featuredImage) {
                  treatedImage = getImage(featuredImage.gatsbyImageData);
                }
                return (
                  <Panel
                    header={<h3>{title}</h3>}
                    // pass the scoped classname to the accordion
                    className={featuredImage ? 'noPadding' : ''}
                    key={index}
                  >
                    <div className={styles.content}>
                      <div>
                        {treatedImage && featuredImage && (
                          <GatsbyImage
                            data-testid="content-image"
                            image={treatedImage}
                            alt={featuredImage.title}
                          />
                        )}
                        {textContent &&
                          renderRichText(
                            { ...textContent, references: [] },
                            renderOptions
                          )}
                      </div>
                      {links && (
                        <ul>
                          {React.Children.toArray(
                            links.map(({ title, url }) =>
                              url ? (
                                <li>
                                  <Link to={url}>{title}</Link>
                                </li>
                              ) : null
                            )
                          )}
                        </ul>
                      )}
                    </div>
                  </Panel>
                );
              }
            )
          )}
        </Collapse>
      </div>
    </div>
  );
};
