import { Modal, Tabs } from 'antd';
import clsx from 'clsx';
import * as React from 'react';

import { useTranslate } from '../../utils/translation/useTranslation';
import { DigitalCatalogueForm, IFormValues, PrintCatalogueForm } from '../Form';

import * as styles from './CatalogFormModal.module.less';

interface IProps {
  isOpen: boolean;
  onSubmit: (formValues: IFormValues) => void;
  onClose: () => void;
}

export const CatalogFormModal: React.FunctionComponent<IProps> = ({
  isOpen,
  onSubmit,
  onClose,
}) => {
  const [activeTab, setActiveTab] = React.useState('1');

  const { t } = useTranslate();

  return (
    <Modal
      visible={isOpen}
      footer={null}
      width="720px"
      closeIcon={<span className={styles.closeButton} />}
      onCancel={onClose}
    >
      <div className={styles.container} data-testid="catalog-form-modal">
        <h2 className={styles.title}>{t('catalogTitle')}</h2>
        <p className={styles.description}>{t('catalogDescription')}</p>
        <Tabs
          moreIcon={null}
          hideAdd
          activeKey={activeTab}
          type="editable-card"
          onChange={setActiveTab}
          className={styles.tabs}
        >
          <Tabs.TabPane
            tab={t('printCatalogTitle')}
            key="1"
            closable={false}
            className={clsx({
              [styles.pane]: true,
              [styles.activePane]: activeTab === '1',
            })}
          >
            <PrintCatalogueForm handleSubmit={onSubmit} />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={t('digitalCatalogTitle')}
            key="2"
            closable={false}
            className={clsx({
              [styles.pane]: true,
              [styles.activePane]: activeTab === '2',
            })}
          >
            <DigitalCatalogueForm handleSubmit={onSubmit} />
          </Tabs.TabPane>
        </Tabs>
      </div>
    </Modal>
  );
};