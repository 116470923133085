import { Link as GatsbyLink, navigate } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import * as React from 'react';

import { ICustomGatsbyImageData } from '../../../utils/types';

import * as styles from './BenefitCard.module.less';

interface IBenefitCardProps {
  title: string;
  image: ICustomGatsbyImageData;
  link: string;
  onClick?: (event: React.SyntheticEvent) => void;
}

export const BenefitCard: React.FunctionComponent<IBenefitCardProps> = ({
  title,
  image: elementImage,
  link,
  onClick,
}) => {
  const image = elementImage && getImage(elementImage.gatsbyImageData);
  return image ? (
    <div
      aria-label={title}
      className={styles.container}
      onClick={onClick ? onClick : () => navigate(`/${link}`)}
    >
      <div className={styles.imageContainer}>
        <div className={styles.overlay} />
        <GatsbyImage
          image={image}
          alt={elementImage.title || ''}
          objectFit="cover"
        />
      </div>

      <div className={styles.info}>
        <h3>{title}</h3>
        {onClick ? <div /> : <GatsbyLink aria-label={title} to={`/${link}`} />}
      </div>
    </div>
  ) : null;
};
