import { Link } from 'gatsby';
import * as React from 'react';

import * as styles from '../shared/clickable.module.less';

export interface IClickToActionProps {
  /**
   * This will be the title of the CTA
   */
  title: string;
  /**
   * This is used for additional tet under the CTA title
   */
  subTitle?: string;
  /**
   * This is used to pass a url and the component will behave as a link
   */
  href?: string;
  /**
   * This is used to pass a function to the CTA.
   *
   * Important!!
   *
   * If the href is passed the component will behave as a link and omits the function
   */
  onClick?: (arg: React.SyntheticEvent) => void;
  /**
   * This is used to pass additional classnames to manipulate styles if needed
   */
  className?: string;
}

export const ClickToAction: React.FC<IClickToActionProps> = ({
  title,
  subTitle,
  href,
  onClick,
  className,
}) => {
  return href ? (
    <Link
      to={`/${href}`}
      className={`${styles.clickToAction} ${className ? className : ''}`}
      data-testid="clickToAction"
    >
      <span className={styles.title}>{title}</span>
      <span className={styles.subtitle}> {subTitle}</span>
    </Link>
  ) : onClick ? (
    <button
      onClick={onClick}
      className={`${styles.clickToAction} ${className ? className : ''}`}
      data-testid="clickToAction"
    >
      <span className={styles.title}>{title}</span>
      <span className={styles.subtitle}> {subTitle}</span>
    </button>
  ) : null;
};
