import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import * as React from 'react';

import { Link } from '../../components/Clickable';
import { ICustomGatsbyImageData } from '../../utils/types';

import * as styles from './Testimonial.module.less';

interface ITestimonial {
  title: string;
  entry: {
    name: string;
    occupation: string;
    link: string | null;
    featuredImage: ICustomGatsbyImageData;
    content: {
      content: string;
    };
  }[];
  /**
   * Used to define the theme of the testimonial if it's a team review or a partner reviews
   * false by default
   */
  isPartner?: boolean;
}

export const Testimonial = ({
  title,
  entry,
  isPartner = false,
}: ITestimonial): JSX.Element => {
  // To be able to extract the colored word in the title because there
  // no selector to select the last word in css
  const newTitle = title.substring(0, title.lastIndexOf(' '));
  const coloredWord = title.substring(title.lastIndexOf(' '));
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const { name, occupation, link, featuredImage, content } = entry[
    selectedIndex
  ];

  const image = getImage(featuredImage.gatsbyImageData);

  return (
    <div
      data-testid="testimonial"
      className={`${styles.container} ${
        isPartner ? styles.almondContainer : ''
      }`}
    >
      <div
        className={`${styles.subContainer} ${
          isPartner ? styles.almondSub : ''
        }`}
      >
        <h2>
          {newTitle}
          <span>{coloredWord}</span>
        </h2>
        {image && (
          <GatsbyImage
            className={styles.reviewImage}
            image={image}
            alt={featuredImage.title}
          />
        )}
        <div
          className={`${styles.content} ${
            isPartner ? styles.almondContent : ''
          }`}
        >
          <p>{content.content}</p>
          {link && (
            <Link href={link} isExternal>
              {link}
            </Link>
          )}
          <div
            className={`${styles.personalInfos} ${
              isPartner ? styles.almondPersonalInfos : ''
            }`}
            data-testid="personal-info"
          >
            <div>
              <h3>{name}</h3>
              <h4>{occupation}</h4>
            </div>
            {entry.length > 1 && (
              <ul>
                {React.Children.toArray(
                  entry.map(({ featuredImage }, index) => {
                    const linkImage = getImage(featuredImage.gatsbyImageData);
                    return (
                      <li
                        className={
                          index === selectedIndex
                            ? styles.selectedImage
                            : styles.faded
                        }
                        onClick={() => setSelectedIndex(index)}
                      >
                        {linkImage && (
                          <GatsbyImage
                            image={linkImage}
                            alt={featuredImage?.title}
                          />
                        )}
                      </li>
                    );
                  })
                )}
              </ul>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
