import * as React from 'react';

import { BenefitCard, CatalogFormModal, Slider } from '../';
import { IContentfulBenefitsElement } from '../../utils/fragments/benefitsBlockFragment';
import { IFormValues } from '../Form';

import * as styles from './Benefits.module.less';

interface IBenefitsProps {
  title: string;
  elements: IContentfulBenefitsElement[];
}

export const Benefits = ({ title, elements }: IBenefitsProps): JSX.Element => {
  const [isCatalogModalOpen, setIsCatalogModalOpen] = React.useState(false);

  const newTitle = title.substring(0, title.lastIndexOf(' '));
  let coloredWord = title.substring(title.lastIndexOf(' '));
  let symbol;
  // to color the last symbol and deleted it from the colored word
  if (coloredWord.match(/[$-/:-?{-~!"^_`[\]]/)) {
    symbol = coloredWord.match(/[$-/:-?{-~!"^_`[\]]/);
    coloredWord = coloredWord.slice(0, -1);
  }

  const onOpenTileClick = () => {
    setIsCatalogModalOpen(true);
  };
  const closeCatalogModal = () => {
    setIsCatalogModalOpen(false);
  };

  const katalogFormSubmit = (values: IFormValues) => {
    return values;
  };

  return (
    <div data-testid="benefits">
      {elements.length > 3 ? (
        <Slider
          title={title}
          titleSize={'xlarge'}
          children={elements.map(({ title, image, link, isFormPopup }) => {
            const onClick = isFormPopup ? onOpenTileClick : undefined;
            return (
              <BenefitCard
                title={title}
                image={image}
                link={link}
                onClick={onClick}
              />
            );
          })}
          titleIsDecorated
          titleIsUpperCase
        />
      ) : (
        <div className={styles.container}>
          <h2>
            {newTitle}
            <span>{coloredWord}</span>
            {symbol}
          </h2>
          <div className={styles.wrapper}>
            {React.Children.toArray(
              elements.map(({ title, image, link, isFormPopup }) => {
                const onClick = isFormPopup ? onOpenTileClick : undefined;
                return (
                  <BenefitCard
                    title={title}
                    image={image}
                    link={link}
                    onClick={onClick}
                  />
                );
              })
            )}
          </div>
        </div>
      )}
      <CatalogFormModal
        isOpen={isCatalogModalOpen}
        onSubmit={katalogFormSubmit}
        onClose={closeCatalogModal}
      />
    </div>
  );
};
