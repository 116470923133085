import * as React from 'react';

import { ValuesSlider } from '../../ValuesSlider';
import * as styles from '../FensterTurenWidget.module.less';

interface IAmountOfWindowsProps {
  product: string;
  value: number;
  onValueChange: (value: number) => void;
}

export const AmountOfWindows: React.FunctionComponent<IAmountOfWindowsProps> = (
  props
) => {
  return (
    <div>
      <div className={styles.question}>
        Wie viele {props.product} benötigen Sie?
      </div>
      <ValuesSlider
        product={props.product}
        value={props.value}
        min={0}
        max={30}
        onChange={props.onValueChange}
      />
    </div>
  );
};
