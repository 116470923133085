import * as React from 'react';

import { WidgetSuccessMessage } from '../WidgetSuccessMessage';
import { IWidgetFormValues, WidgetForm } from '../widgetForm';

import * as styles from './ContactForm.module.less';

interface IContactForm {
  onSubmit: (values: IWidgetFormValues) => void;
  isSubmitted?: boolean;
  showIdentityField?: boolean;
}

export const ContactForm: React.FunctionComponent<IContactForm> = ({
  onSubmit,
  isSubmitted,
  showIdentityField,
}) => {
  return (
    <div className={styles.container}>
      {isSubmitted ? (
        <WidgetSuccessMessage />
      ) : (
        <WidgetForm
          onSubmit={onSubmit}
          showComapnyField
          showIdentityField={showIdentityField}
        />
      )}
    </div>
  );
};
