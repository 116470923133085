import * as React from 'react';

import * as styles from './MapBlock.module.less';

interface IMapBlockProps {
  title: string | null;
  address: string;
}

export const MapBlock: React.FunctionComponent<IMapBlockProps> = (props) => {
  const [showMap, setShowMap] = React.useState(false);
  const iframeSrc = encodeURI(
    `https://maps.google.com/maps?q=${props.address}&t=&z=13&ie=UTF8&iwloc=&output=embed`
  );
  const container: React.RefObject<HTMLIFrameElement> = React.createRef();

  React.useEffect(() => {
    if (mapObserver && 'IntersectionObserver' in window) {
      if (container && container.current) {
        mapObserver?.observe(container.current);
      }
    } else {
      setShowMap(true);
    }
  }, [container]);

  function onMapIntersection(entries) {
    if (!entries || entries.length <= 0) {
      return;
    }

    if (entries[0].isIntersecting) {
      setShowMap(true);
      mapObserver?.disconnect();
    }
  }

  const mapObserver =
    typeof window !== 'undefined'
      ? new IntersectionObserver(onMapIntersection, {
          rootMargin: '100px 0px',
          threshold: 0.25,
        })
      : undefined;

  return (
    <section className={styles.locationMap} data-testid="map">
      {props.title && <h2>{props.title}</h2>}
      <div className={styles.mapouter}>
        <div className={styles.gmap_canvas} ref={container}>
          {showMap && (
            <iframe
              title={props.title || 'map'}
              width="100%"
              height="100%"
              id="gmap_canvas"
              src={iframeSrc}
              frameBorder="0"
              scrolling="no"
              marginHeight={0}
              marginWidth={0}
            ></iframe>
          )}
        </div>
      </div>
    </section>
  );
};
