import { Breadcrumb } from 'antd';
import { Link as GatsbyLink } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import * as React from 'react';

import { LanguageContext } from '../../../context/LanguageContext';
import { ICustomGatsbyImageData } from '../../../utils/types';
import { Link } from '../../Clickable';

import * as styles from './ReferencesHero.module.less';

export interface IMainPost {
  title: string;
  featuredImage: ICustomGatsbyImageData;
  tags?: { title: string }[];
  link?: { slug: string; title: string };
}

export interface IHeroProps {
  title?: string;
  mainPost: IMainPost;
  location?: {
    pathname: string;
  };
}

export const ReferencesHero = ({
  title,
  mainPost,
  location,
}: IHeroProps): React.ReactElement => {
  const path = location?.pathname
    ?.split('/')
    .filter((el) => el !== '' && el !== 'en');
  const featuredImage = getImage(mainPost.featuredImage.gatsbyImageData);
  const language = React.useContext(LanguageContext);
  const homePageUrl = `${language === 'En' ? '/en/' : '/'}`;

  return (
    <div className={styles.container} data-testid="referencesHero">
      {path && (
        <Breadcrumb className={styles.breadcrumb} separator="|">
          <Breadcrumb.Item className={styles.homePageItem}>
            <GatsbyLink to={homePageUrl}></GatsbyLink>
          </Breadcrumb.Item>
          {React.Children.toArray(
            path.map((el, index) => (
              <Breadcrumb.Item
                className={`${styles.breadcrumbItem} ${index === path.length - 1 ? styles.currentPageItem : ''
                  }`}
              >
                {index === path.length - 1 ? (
                  <span>
                    {path.length > 1
                      ? index === path.length - 1
                        ? mainPost.title
                        : el
                      : el}
                  </span>
                ) : (
                  <GatsbyLink
                    to={`${homePageUrl}${index === 1 ? `${path[index - 1]}/${el}/` : `${el}/`
                      }`}
                  >
                    {path.length > 1
                      ? index === path.length - 1
                        ? mainPost.title
                        : el
                      : el}
                  </GatsbyLink>
                )}
              </Breadcrumb.Item>
            ))
          )}
        </Breadcrumb>
      )}

      <div className={styles.wrapper}>
        {featuredImage && (
          <GatsbyImage
            image={featuredImage}
            alt={mainPost.featuredImage.title}
          />
        )}
        {title && <h1 className={styles.title}>{title}</h1>}
        <div className={styles.card}>
          <h1 className={styles.subheading}>{mainPost.title}</h1>
          <div className={styles.cardBody}>
            <ul className={styles.tagsBlock}>
              {mainPost.tags &&
                React.Children.toArray(
                  mainPost.tags.map(({ title }) => (
                    <li className={styles.tag}>{title}</li>
                  ))
                )}
            </ul>
            {mainPost.link && (
              <div className={styles.link}>
                <Link href={`./${mainPost.link.slug}/`} variant="primary">
                  {mainPost.link.title}
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
