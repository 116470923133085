import { Link } from 'gatsby';
import * as React from 'react';

import { IMenuLink } from '..';

import * as styles from './SubMenu.module.less';

export interface ISubMenuProps {
  link: IMenuLink;
  showSubMenu: boolean;
  toggleSubMenu: () => void;
}

export const SubMenu = ({
  link,
  showSubMenu,
  toggleSubMenu,
}: ISubMenuProps): JSX.Element => {
  const { title, menuTitle, subPages } = link;

  return (
    <div
      className={`${styles.container} ${showSubMenu ? '' : styles.subMenuHidden
        }`}
    >
      <span onClick={toggleSubMenu} data-testid={'submenu-title'}>
        {menuTitle ? menuTitle : title}
      </span>
      <ul>
        {subPages &&
          subPages.map(({ menuTitle, slug }) => (
            <li key={slug}>
              <Link to={slug}>
                {menuTitle}
                <div />
              </Link>
            </li>
          ))}
      </ul>
    </div>
  );
};
