import { Breadcrumb } from 'antd';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import * as React from 'react';

import { LanguageContext } from '../../../context/LanguageContext';
import { ICustomGatsbyImageData } from '../../../utils/types';
import {
  Button,
  ClickToAction,
  IClickToActionProps,
  ITileProps,
  Tile,
} from '../../Clickable';

import * as styles from './MainHero.module.less';
import arrow from './img/arrow-down.svg';

export interface IMainHeroProps {
  title: string;
  description?: {
    description: string;
  };
  image?: ICustomGatsbyImageData;
  // section id to scroll to when arrow is clicked
  sectionId?: string;
  tiles: ITileProps[] | null;
  location?: {
    pathname: string;
  };
  clickToActionTile?: IClickToActionProps;
  clickToActionPrimaryButton?: IClickToActionProps;
}

export const MainHero = ({
  title,
  description,
  image,
  sectionId,
  tiles,
  location,
  clickToActionTile,
  clickToActionPrimaryButton
}: IMainHeroProps): React.ReactElement => {
  let newTitle;
  let coloredWord;
  const trimmedTitle = title.trim();
  if (trimmedTitle.split(' ').length > 1) {
    const lastIndex = trimmedTitle.lastIndexOf(' ');
    newTitle = trimmedTitle.substring(0, lastIndex);
    coloredWord = trimmedTitle.substring(lastIndex);
  } else {
    newTitle = trimmedTitle;
  }

  const path = location?.pathname
    ?.split('/')
    .filter((el) => el !== '' && el !== 'en');
  const language = React.useContext(LanguageContext);
  const homePageUrl = `${language === 'En' ? '/en/' : '/'}`;

  const featuredImage = image && getImage(image.gatsbyImageData);
  return (
    <div className={styles.container} data-testid="mainHero">
      <div className={styles.wrapper}>
        <div className={styles.contentContainer}>
          <div className={styles.content}>
            <h1>
              {newTitle}
              {coloredWord && <span>{coloredWord}</span>}
            </h1>
            <p>{description?.description}</p>
            {clickToActionPrimaryButton ? (
              <div>
                <Button variant='primary' isAnchor href={clickToActionPrimaryButton.href}>
                  {clickToActionPrimaryButton.title}
                </Button>
              </div>
            ) : (
              <Link to={`#${sectionId}`} aria-label={`Link to ${sectionId}`}>
                <img src={arrow} alt="" />
              </Link>
            )}
          </div>
        </div>
        <div className={styles.overlayContainer}>
          <div className={styles.overlay} />
          {featuredImage && (
            <GatsbyImage image={featuredImage} alt={image.title} />
          )}
        </div>
        {clickToActionTile && <ClickToAction {...clickToActionTile} />}
      </div>
      {tiles && (
        <div className={styles.tilesContainer}>
          {React.Children.toArray(
            tiles.map(({ title, description, icon, link, hoverIcon }) => (
              <Tile
                icon={icon}
                title={title}
                description={description}
                link={link}
                hoverIcon={hoverIcon}
                slug={''}
              />
            ))
          )}
        </div>
      )}
      {path && (
        <Breadcrumb className={styles.breadcrumb} separator="|">
          <Breadcrumb.Item className={styles.homePageItem}>
            <Link to={homePageUrl} aria-label="Link to homepage"></Link>
          </Breadcrumb.Item>
          {React.Children.toArray(
            path.map((el, index) => (
              <Breadcrumb.Item
                className={`${styles.breadcrumbItem} ${index === path.length - 1 ? styles.currentPageItem : ''
                  }`}
              >
                {index === path.length - 1 || el === 'fuer-profis' ? (
                  <span>{el.replace(new RegExp('-', 'g'), ' ')}</span>
                ) : (
                  <Link to={`${homePageUrl}${el}`} aria-label={el}>
                    {el.replace(new RegExp('-', 'g'), ' ')}
                  </Link>
                )}
              </Breadcrumb.Item>
            ))
          )}
        </Breadcrumb>
      )}
    </div>
  );
};
