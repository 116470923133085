import * as React from 'react';

import * as styles from './WidgetCard.module.less';

export interface IWidgetCardProps {
  title: string;
  icon: string;
  selected: boolean;
  onClick?: (title: string) => void;
}

export const WidgetCard = ({
  title,
  icon,
  onClick,
  selected,
}: IWidgetCardProps): JSX.Element => {
  const handleClick = (title) => {
    onClick && onClick(title);
  };

  return (
    <div
      className={`${styles.container} ${selected ? styles.selected : ''}`}
      onClick={() => handleClick(title)}
      data-testid="widget-card"
    >
      <div />
      <img src={icon} alt="card-icon" />
      <p>{title}</p>
    </div>
  );
};
