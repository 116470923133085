import * as React from 'react';

import * as styles from '../../FensterTurenWidget/FensterTurenWidget.module.less';
import iconBoth from '../../FensterTurenWidget/img/pictogram-beides-64.svg';
import iconFixedGlass from '../../FensterTurenWidget/img/pictogram-festverglast-64.svg';
import iconOpeningSash from '../../FensterTurenWidget/img/pictogram-oeffnungsfluegel-64.svg';
import { WidgetCardsContainer } from '../../WidgetCardsContainer';

interface IOpeningProps {
  onSelect: (element: string) => void;
  selectedItem: string;
}

export const Opening: React.FunctionComponent<IOpeningProps> = ({
  onSelect,
  selectedItem,
}) => {
  const onItemClick = (titles: string[]) => {
    onSelect(titles[0] || '');
  };

  return (
    <div>
      <div className={styles.question}>
        Benötigen Sie eine Öffnungsmöglichkeit?
      </div>
      {/* TODO: USE THE CORRECT ICONS */}
      <WidgetCardsContainer
        elements={[
          {
            title: 'mit Öffnungsflügel',
            icon: iconOpeningSash,
            selected: 'mit Öffnungsflügel' === selectedItem,
          },
          {
            title: 'festverglast',
            icon: iconFixedGlass,
            selected: 'festverglast' === selectedItem,
          },
          {
            title: 'beides',
            icon: iconBoth,
            selected: 'beides' === selectedItem,
          },
        ]}
        selectedItems={selectedItem ? [selectedItem] : []}
        setSelectedItems={onItemClick}
      />
    </div>
  );
};
