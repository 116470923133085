import { Col, Row } from 'antd';
import { Link as GatsbyLink } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import * as React from 'react';

import { IMenuLink } from '..';
import { Link } from '../../Clickable';

import * as styles from './SubHeader.module.less';

export interface ISubHeaderProps {
  link: IMenuLink;
  toggleSubHeader: (state: boolean) => void;
  decorated?: boolean;
}

export const SubHeader = ({
  link,
  toggleSubHeader,
  decorated,
}: ISubHeaderProps): JSX.Element => {
  const { title, subPages, btnTitle, slug: subHeaderSLug } = link;
  const newTitle = title.includes('/') ? title.replace('/', ' & ') : title;

  return (
    <Row
      className={`${styles.container} ${decorated ? styles.decorated : ''}`}
      onMouseLeave={() => toggleSubHeader(false)}
    >
      <Col span={4} className={styles.content}>
        <h2>{link.menuTitle ? link.menuTitle : newTitle}</h2>
        <ul>
          {subPages &&
            subPages.map(({ menuTitle, slug }) => (
              <li key={slug}>
                <GatsbyLink to={slug}>{menuTitle}</GatsbyLink>
              </li>
            ))}
        </ul>
        {link.order !== 6 && (
          <Link variant="secondary" href={`${subHeaderSLug}/`} isDecorated>
            {btnTitle ? btnTitle : newTitle}
          </Link>
        )}
      </Col>
      <Col span={19} offset={1}>
        <Row className={styles.gallery}>
          {subPages &&
            React.Children.toArray(
              subPages.map(
                ({ menuTitle, slug: subPageSlug, featuredImage }) => {
                  const image =
                    featuredImage && getImage(featuredImage.gatsbyImageData);
                  return (
                    <Col span={5} offset={1} className={styles.item}>
                      {/* Overlay filter  */}
                      <GatsbyLink to={subPageSlug} />
                      {image && <GatsbyImage image={image} alt={menuTitle} />}
                      <div className={styles.itemText}>
                        <span>{menuTitle}</span>
                      </div>
                    </Col>
                  );
                }
              )
            )}
        </Row>
      </Col>
    </Row>
  );
};
