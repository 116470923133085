import * as React from 'react';

import { WidgetCardsContainer } from '../../WidgetCardsContainer';
import * as styles from '../FensterTurenWidget.module.less';
import iconConsultation from '../img/pictogram-beratung-64.svg';
import iconClassic from '../img/pictogram-klassisch-64.svg';
import iconModern from '../img/pictogram-moderne-64.svg';

interface IDesignProps {
  onSelect: (element: string) => void;
  selectedItem: string;
}

export const Design: React.FunctionComponent<IDesignProps> = ({
  onSelect,
  selectedItem,
}) => {
  const onItemClick = (titles: string[]) => {
    onSelect(titles[0] || '');
  };
  return (
    <div>
      <div className={styles.question}>
        Welches Design soll Ihre neue Tür haben?
      </div>
      <WidgetCardsContainer
        elements={[
          {
            title: 'Modern',
            icon: iconModern,
            selected: 'Modern' === selectedItem,
          },
          {
            title: 'Klassisch',
            icon: iconClassic,
            selected: 'Klassisch' === selectedItem,
          },
          {
            title: 'suche Beratung',
            icon: iconConsultation,
            selected: 'suche Beratung' === selectedItem,
          },
        ]}
        selectedItems={selectedItem ? [selectedItem] : []}
        setSelectedItems={onItemClick}
      />
    </div>
  );
};
