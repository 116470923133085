import { renderRichText } from 'gatsby-source-contentful/rich-text';
import * as React from 'react';

import * as styles from './VacationBanner.module.less';
import snowIcon from './img/snow-icon.svg';

export interface IVacationBanner {
  content: {
    raw: string;
  };
  eventType: 'Default' | 'Christmas';
  hideBanner: () => void;
}

export const VacationBanner = ({
  content,
  eventType = 'Default',
  hideBanner,
}: IVacationBanner): JSX.Element => {
  const renderOptions = {
    renderText: (text: string) => {
      return text.split('\n').reduce((children, textSegment, index) => {
        return [...children, index > 0 && <br key={index} />, textSegment];
      }, []);
    },
  };

  return (
    <div
      className={`${styles.container} ${
        eventType === 'Christmas' ? styles.withChristmas : ''
      }`}
    >
      {eventType === 'Christmas' && <img src={snowIcon} alt="snow-icon" />}
      <div>{renderRichText({ ...content, references: [] }, renderOptions)}</div>
      <button onClick={hideBanner} />
    </div>
  );
};
