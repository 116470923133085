import * as React from 'react';

import { ITileProps, Tile } from '..';
import { CatalogFormModal } from '../CatalogFormModal';
import { IFormValues } from '../Form';

import * as styles from './PreFooter.module.less';

export interface IPreFooterProps {
  items: ITileProps[];
}

export const PreFooter = ({ items }: IPreFooterProps): React.ReactElement => {
  const [isCatalogModalOpen, setIsCatalogModalOpen] = React.useState(false);
  const onFirstTileClick = () => {
    setIsCatalogModalOpen(true);
  };
  const closeCatalogModal = () => {
    setIsCatalogModalOpen(false);
  };

  const onFirstTileFormSubmit = (values: IFormValues) => {
    return values;
  };

  return (
    <div className={styles.container} data-testid="preFooter">
      {React.Children.toArray(
        // TODO: check if link is required
        items.map(
          ({ title, description, icon, link, hoverIcon, slug }, index) => {
            const onClick = index === 0 ? onFirstTileClick : undefined;
            return (
              <Tile
                icon={icon}
                title={title}
                description={description}
                link={link}
                hoverIcon={hoverIcon}
                onClick={onClick}
                slug={slug}
              />
            );
          }
        )
      )}
      <CatalogFormModal
        isOpen={isCatalogModalOpen}
        onSubmit={onFirstTileFormSubmit}
        onClose={closeCatalogModal}
      />
    </div>
  );
};
