import Cookies from 'js-cookie';
import * as React from 'react';

import { trackFormSuccess, trackWidgetStep } from '../../utils/helpers';
import { Button } from '../Clickable';
import { WidgetFormQuote } from '../WidgetFormQuote';
import { WidgetSteps } from '../WidgetSteps';
import { WidgetSuccessMessage } from '../WidgetSuccessMessage';

import * as styles from './FensterTurenWidget.module.less';

import {
  AmountOfWindows,
  Design,
  GlazingBars,
  Material,
  PersonalInformation,
  Property,
  QuestionScreen,
  RenovationNewBuilding,
  ShippingDate,
  SurveyProductItem,
} from '.';

export const TurenWidget: React.FunctionComponent = () => {
  const [buildingType, setBuildingType] = React.useState('');
  const [importantWindows, setImportantWindows] = React.useState<string[]>([]);
  const [importantDoors, setImportantDoors] = React.useState<string[]>([]);
  const [doorsMaterial, setDoorsMaterial] = React.useState('');
  const [glazingBars, setGlazingBars] = React.useState('');
  const [numberOfWindows, setNumberOfWindows] = React.useState(2);
  const [executionPeriod, setExecutionPeriod] = React.useState('');

  const [doorType, setDoorType] = React.useState('');
  const [doorDesign, setDoorDesign] = React.useState('');
  const [seeWindows, setSeeWindows] = React.useState('');

  const [product, setProduct] = React.useState<'doors' | 'windows'>('doors');

  const [activeStep, setActiveStep] = React.useState(1);
  const [showForm, setShowForm] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false);

  React.useEffect(() => {
    trackWidgetStep('turen', activeStep);
    window.location.hash = `step${activeStep}`;
  }, [activeStep]);

  const numberOfSteps = 5;

  const doorsSteps = {
    1: doorType,
    2: doorDesign,
    3: importantDoors,
    4: doorsMaterial,
    5: seeWindows,
  };

  const doorsScreens = {
    1: <SurveyProductItem selectedItem={doorType} onSelect={setDoorType} />,
    2: <Design onSelect={setDoorDesign} selectedItem={doorDesign} />,
    3: <Property onSelect={setImportantDoors} selectedItems={importantDoors} />,
    4: (
      <Material
        text="Aus welchem Material sollen Ihre Fenster und Türen sein?"
        onSelect={setDoorsMaterial}
        selectedItem={doorsMaterial}
      />
    ),
    5: (
      <QuestionScreen
        onNoClick={() => {
          setSeeWindows('no');
          setShowForm(true);
        }}
        onYesClick={() => {
          setSeeWindows('yes');
          setProduct('windows');
          setActiveStep(1);
        }}
        onSelect={setSeeWindows}
        selectedItem={seeWindows}
      />
    ),
  };

  const windowsScreens = {
    1: (
      <RenovationNewBuilding
        onSelect={setBuildingType}
        selectedItem={buildingType}
      />
    ),
    2: (
      <Property
        onSelect={setImportantWindows}
        selectedItems={importantWindows}
      />
    ),
    3: <GlazingBars onSelect={setGlazingBars} selectedItem={glazingBars} />,
    4: (
      <AmountOfWindows
        product="Fenster"
        value={numberOfWindows}
        onValueChange={setNumberOfWindows}
      />
    ),
    5: (
      <ShippingDate
        product="Fenster"
        onSelect={setExecutionPeriod}
        selectedItem={executionPeriod}
      />
    ),
  };

  const listOfChoices = [
    { name: 'Türart', value: doorType },
    { name: 'Design', value: doorDesign },
    { name: 'Wichtig', value: importantDoors.join(', ') },
    { name: 'Material', value: doorsMaterial },
    { name: 'Fenster', value: seeWindows },

    { name: 'Gebäudeart', value: buildingType },
    // this is overriding another poperty later.
    { name: 'Wichtig', value: importantWindows.join(', ') },
    { name: 'Sprossen', value: glazingBars },
    { name: 'Anzahl', value: numberOfWindows },
    { name: 'Ausführungszeitraum', value: executionPeriod },
  ].filter((choice) => !!choice.value);

  const handleFinish = (values) => {
    fetch('/.netlify/functions/SubmitWidget', {
      method: 'POST',
      body: JSON.stringify({
        ...values,
        formName: 'Turen',
        gclid: Cookies.get('gclid'),
        details: listOfChoices.reduce(
          (acc, curr) => ({
            ...acc,
            [curr.name]: curr.value,
          }),
          {}
        ),
      }),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.statusText);
        } else {
          setSubmitted(true);
          trackFormSuccess('New-Widget-Türen');
          return res;
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const formSection = (
    <PersonalInformation
      onFormSubmit={handleFinish}
      listOfChoices={listOfChoices}
    />
  );

  const showPrevButton = !showForm && activeStep !== 1;
  const showNextButton =
    product === 'doors' ? !showForm && activeStep !== 5 : !showForm;

  const buttonsBlock = (
    <div className={styles.buttonsBlock}>
      {showPrevButton && (
        <Button
          variant="secondary"
          onClick={() => {
            setActiveStep((currentStep) => currentStep - 1);
          }}
        >
          Zurück
        </Button>
      )}
      {showNextButton && (
        <Button
          variant="primary"
          onClick={() => {
            const isMultipleSelectionStep = activeStep === 3;

            const stepValue = doorsSteps[activeStep];

            const stepHasValue = isMultipleSelectionStep
              ? stepValue.length !== 0
              : stepValue !== '';

            if (!stepHasValue) return;

            if (product === 'windows' && activeStep === 5) {
              setShowForm(true);
            } else {
              setActiveStep((currentStep) => currentStep + 1);
            }
          }}
        >
          Weiter
        </Button>
      )}
    </div>
  );

  return (
    <section
      className={`${styles.container} ${activeStep == 5 ? styles.fullWidth : ''
        }`}
    >
      {activeStep === 1 && <div className={styles.description}>Die folgenden Fragen helfen uns dabei, Ihre Bedürfnisse besser zu verstehen.
        So bekommen Sie von uns genau die Informationen, die für Sie relevant sind.</div>}
      {!showForm && (
        <WidgetSteps activeStep={activeStep} numberOfSteps={numberOfSteps} />
      )}
      {product === 'doors' && !showForm && doorsScreens[activeStep]}
      {product === 'windows' && !showForm && windowsScreens[activeStep]}
      {showForm && !submitted && formSection}
      {submitted && <WidgetSuccessMessage />}
      {showForm && <WidgetFormQuote />}
      {buttonsBlock}
    </section>
  );
};
