import { Collapse, Tabs } from 'antd';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import * as React from 'react';

import arrowIcon from '../../images/arrow-icon.svg';
import { useTranslate } from '../../utils/translation/useTranslation';
import { ICustomGatsbyImageData } from '../../utils/types';
import { DigitalCatalogueForm, IFormValues, PrintCatalogueForm } from '../Form';

import * as styles from './Catalogue.module.less';

interface ICatalogueProps {
  title: string;
  slug: string;
  isDigitalOnly: boolean;
  subHeading: string;
  icons: {
    file: { url: string };
    title: string;
  }[];
  featuredImage: ICustomGatsbyImageData;
  onSubmit?: (formValues: IFormValues) => void;
}

const { Panel } = Collapse;
const { TabPane } = Tabs;

export const Catalogue = ({
  title,
  slug,
  isDigitalOnly,
  subHeading,
  icons,
  featuredImage,
  onSubmit,
}: ICatalogueProps): JSX.Element => {
  const [activeTab, setActiveTab] = React.useState('1');
  const image = getImage(featuredImage.gatsbyImageData);

  const { t } = useTranslate();

  const handleSubmit = (values: IFormValues) => {
    onSubmit && onSubmit(values);
  };

  return (
    <div className={styles.container} data-testid="catalogue">
      <div className={styles.sideWrapper}>
        <div className={styles.sideInfowrapper}>
          <h2>{title}</h2>
          <h3>{subHeading}</h3>
          <div className={styles.itemsWrapper}>
            {React.Children.toArray(
              icons.map(({ file: { url }, title }) => (
                <div className={styles.item}>
                  <img src={url} alt={title} loading="lazy" />
                  <h4>{title}</h4>
                </div>
              ))
            )}
          </div>
        </div>
        {image && <GatsbyImage image={image} alt={featuredImage.title} />}
      </div>

      <div className={`${styles.formWrapper} ${styles.displayOnDesktop}`}>
        <Tabs
          moreIcon={null}
          hideAdd
          activeKey={activeTab}
          type="editable-card"
          onChange={setActiveTab}
          className={styles.tabs}
        >
          {isDigitalOnly ? null : (
            <TabPane
              tab={t('printCatalogTitle')}
              key={1}
              closable={false}
              className={styles.pane}
            >
              <PrintCatalogueForm
                catalogSlug={slug}
                handleSubmit={handleSubmit}
              />
            </TabPane>
          )}
          <TabPane
            tab={t('digitalCatalogTitle')}
            key={isDigitalOnly ? 1 : 2}
            closable={false}
            className={styles.pane}
          >
            <DigitalCatalogueForm
              catalogSlug={slug}
              handleSubmit={handleSubmit}
            />
          </TabPane>
        </Tabs>
      </div>
      {/* This collapse will be displayed on mobile devices only */}
      <Collapse
        className={styles.accordion}
        ghost
        expandIconPosition="right"
        expandIcon={({ isActive }) => (
          <img
            className={`${isActive ? 'rotated' : ''}`}
            src={arrowIcon}
            alt="expand-icon"
          />
        )}
      >
        <Panel key={1} header={t('catalogMobilePanelTitle')}>
          <div className={styles.formWrapper}>
            <Tabs
              hideAdd
              activeKey={activeTab}
              type="editable-card"
              onChange={setActiveTab}
              className={styles.tabs}
            >
              {isDigitalOnly ? null : (
                <TabPane
                  tab={t('printCatalogTitle')}
                  key={1}
                  closable={false}
                  className={styles.pane}
                >
                  <PrintCatalogueForm
                    catalogSlug={slug}
                    handleSubmit={handleSubmit}
                  />
                </TabPane>
              )}
              <TabPane
                tab={t('digitalCatalogTitle')}
                key={isDigitalOnly ? 1 : 2}
                closable={false}
                className={styles.pane}
              >
                <DigitalCatalogueForm
                  catalogSlug={slug}
                  handleSubmit={handleSubmit}
                />
              </TabPane>
            </Tabs>
          </div>
        </Panel>
      </Collapse>
    </div>
  );
};
