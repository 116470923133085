import { Checkbox, Col, Form, Input, Radio, Row } from 'antd';
import * as React from 'react';

import { Button, Link } from '../';

import * as styles from './widgetForm.module.less';

export interface IWidgetFormValues {
  gender: 'Frau' | 'Herr';
  vorName: string;
  nachName: string;
  unternehmen: string;
  plz: string;
  email: string;
  telefon: string | number;
  Kommentar: string;
}

interface IWidgetFormProps {
  onSubmit?: (values: IWidgetFormValues) => void;
  showComapnyField?: boolean;
  showIdentityField?: boolean;
}

export const WidgetForm: React.FunctionComponent<IWidgetFormProps> = ({
  onSubmit,
  showComapnyField,
  showIdentityField,
}) => {
  const [errorMessage, setErrorMessage] = React.useState('');

  const handleSubmit = (values: IWidgetFormValues) => {
    try {
      onSubmit && onSubmit(values);
    } catch (error) {
      setErrorMessage(error);
    }
  };

  return (
    <div className={styles.container}>
      <Form onFinish={handleSubmit}>
        <div className={styles.formDetail}>*Pflichtfelder bitte ausfüllen</div>
        {showIdentityField && (
          <>
            <div>Ich bin*</div>
            <Form.Item
              name="identity"
              rules={[
                {
                  required: true,
                  message: 'Bitte wählen Sie eine der Optionen!',
                },
              ]}
            >
              <Radio.Group name="identity">
                <Radio value="Planer">Planer</Radio>
                <Radio value="Fachbetrieb">Fachbetrieb</Radio>
                <Radio value="Privatkunde">Privatkunde</Radio>
              </Radio.Group>
            </Form.Item>
          </>
        )}
        <div className={styles.formDetail}>Anrede</div>
        <Form.Item
          name="gender"
          rules={[
            {
              required: false,
              message: 'Bitte wählen Sie Ihr Geschlecht!',
            },
          ]}
        >
          <Radio.Group name="gender">
            <Radio value="Frau">Frau</Radio>
            <Radio value="Herr">Herr</Radio>
          </Radio.Group>
        </Form.Item>
        <Row justify="space-between">
          <Col xs={24} sm={11} lg={24} xl={11}>
            <Form.Item
              name="Vorname"
              rules={[
                {
                  required: true,
                  message: 'Bitte geben Sie Ihren Vornamen ein!',
                },
              ]}
            >
              <Input name="Vorname" placeholder="Vorname*" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={11} lg={24} xl={11}>
            <Form.Item
              name="Nachname"
              rules={[
                {
                  required: true,
                  message: 'Bitte geben Sie Ihren Nachnamen ein!',
                },
              ]}
            >
              <Input name="Nachname" placeholder="Nachname*" />
            </Form.Item>
          </Col>
        </Row>
        {showComapnyField && (
          <Form.Item
            name="unternehmen"
            rules={[
              {
                required: showIdentityField ? false : true,
                message: 'Bitte geben Sie Ihren Unternehmen ein!',
              },
            ]}
          >
            <Input
              name="Unternehmen"
              placeholder={`Unternehmen${showIdentityField ? '' : '*'}`}
            />
          </Form.Item>
        )}
        <Form.Item
          name="PLZ"
          rules={[
            {
              required: true,
              message: 'Bitte geben Sie Ihre Postleitzahl ein!',
            },
          ]}
        >
          <Input name="PLZ" placeholder="PLZ*" type="number" />
        </Form.Item>
        <Form.Item
          name="E-Mail"
          rules={[
            {
              required: true,
              message: 'Bitte geben Sie Ihre E-Mail Adresse ein!',
            },
            {
              type: 'email',
              message:
                'Bitte geben Sie eine gültige E-Mail Adresse ein (IE:example@gmail.com)',
            },
          ]}
        >
          <Input name="E-Mail" placeholder="E-Mail*" />
        </Form.Item>
        <Form.Item
          name="Telefon"
          rules={[{ pattern: /^\d+$/, message: 'Bitte nur Nummern eingeben!' }]}
        >
          <Input name="Telefon" placeholder="Telefon" />
        </Form.Item>

        <Form.Item name="Kommentar">
          <Input.TextArea
            name="Kommentar"
            placeholder="Kommentar"
            cols={1}
            rows={5}
          />
        </Form.Item>
        <Form.Item
          name="accept-check"
          valuePropName="checked"
          rules={[
            {
              required: true,
              message:
                'Bitte überprüfen Sie die Datenschutzerklärung und stimmen Sie der Datenverarbeitung zu!',
            },
          ]}
        >
          <Checkbox name="accept-check">
            Ich habe die{' '}
            <Link href="/datenschutzerklaerung/" className={styles.formLink}>
              Datenschutzerklärung
            </Link>{' '}
            gelesen und willige in die Datenverarbeitung ein.*
          </Checkbox>
        </Form.Item>
        <Form.Item>
          <Button type="submit" variant={'primary'}>
            {showIdentityField ? 'Absenden' : 'Anfrage verschicken'}
          </Button>
        </Form.Item>
        {errorMessage && (
          <Form.Item>
            <p>{errorMessage}</p>
          </Form.Item>
        )}
      </Form>
    </div>
  );
};
