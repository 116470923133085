import { Link as GatsbyLink } from 'gatsby';
import * as React from 'react';

import * as styles from '../shared/clickable.module.less';

interface ILinkProps {
  /**
   * The url used for the link
   */
  href: string;
  /**
   * How the link should look like, the default is ghost
   */
  variant?: 'ghost' | 'primary' | 'secondary';
  /**
   * Should the link have an icon, it work only with ghost variant
   */
  isDecorated?: boolean;
  /**
   * To apply custom css to the component
   */
  className?: string;
  isExternal?: boolean;
}

export const Link = ({
  href,
  variant = 'ghost',
  isDecorated,
  children,
  className,
  isExternal,
  ...rest
}: React.PropsWithChildren<ILinkProps> &
  React.HTMLProps<HTMLAnchorElement>): JSX.Element => {
  if (isExternal)
    return (
      //variant is required so there's no need to verify if it exist or not
      // thus we can pass it directly to the styles array and have our selector
      <a
        href={href}
        className={`${styles.clickable} ${styles[variant]} ${
          isDecorated ? styles.decorated : ''
        } ${className ? className : ''}`}
        target="_blank"
        rel="noopener noreferrer"
        {...rest}
      >
        {children}
      </a>
    );
  else
    return (
      <GatsbyLink
        to={href}
        className={`${styles.clickable} ${styles[variant]} ${
          isDecorated ? styles.decorated : ''
        } ${className ? className : ''}`}
        data-testid={rest['data-testid']}
        onMouseLeave={rest.onMouseLeave}
        onMouseEnter={rest.onMouseEnter}
      >
        {children}
      </GatsbyLink>
    );
};
