import { List } from 'antd';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import * as React from 'react';

import {
  IContentfulList,
  IProductDetailsTable,
} from '../../utils/fragments/productDetailsBlockFragment';
import { ICustomGatsbyImageData } from '../../utils/types';
import { Table } from '../Table';

import * as styles from './ProductDetails.module.less';

interface IProductDetailsProps {
  title: string;
  subtitle?: string | null;
  image?: ICustomGatsbyImageData | null;
  /**
   * details can be passed as a list or a table
   */
  details?: IContentfulList | IProductDetailsTable;
  isDecorated?: boolean | null;
}

export const ProductDetails = ({
  title,
  subtitle,
  image: imageProp,
  details,
  isDecorated = false,
}: IProductDetailsProps): JSX.Element => {
  const image = imageProp && getImage(imageProp.gatsbyImageData);
  return (
    <div className={styles.container} data-testid="productDetails">
      <h2 className={`${styles.title} ${isDecorated ? styles.decorated : ''}`}>
        {title}
      </h2>
      {subtitle && <h3 className={styles.subtitle}>{subtitle}</h3>}
      <div className={`${styles.wrapper} ${imageProp ? styles.withImage : ''}`}>
        {image && <GatsbyImage image={image} alt={imageProp?.title || ''} />}
        {details?.__typename === 'ContentfulList' && (
          <List
            size="large"
            className={styles.list}
            dataSource={details.elements}
            renderItem={({ title, content: { content } }, index) => (
              <List.Item>
                <div className={styles.item}>
                  <span>{index + 1}</span>
                  <div>
                    <h3>{title}</h3>
                    <p>{content}</p>
                  </div>
                </div>
              </List.Item>
            )}
          />
        )}
        {details?.__typename === 'ContentfulTable' && (
          <Table className={styles.table} data={details.content.content} />
        )}
      </div>
    </div>
  );
};
