// extracted by mini-css-extract-plugin
export var container = "SmallHero-module--container--1cXK3";
export var logo = "SmallHero-module--logo--3ppkt";
export var title = "SmallHero-module--title--3NXiX";
export var coloredWord = "SmallHero-module--coloredWord--ukTMP";
export var wrapper = "SmallHero-module--wrapper--3RcnK";
export var imageOverlay = "SmallHero-module--imageOverlay--1f39c";
export var breadcrumb = "SmallHero-module--breadcrumb--1JrtQ";
export var homePageItem = "SmallHero-module--homePageItem--31XK6";
export var breadcrumbItem = "SmallHero-module--breadcrumbItem--cqyAS";
export var currentPageItem = "SmallHero-module--currentPageItem--3xXHL";
export var descriptionContainer = "SmallHero-module--descriptionContainer--YhksF";
export var subheading = "SmallHero-module--subheading--1cpEj";
export var description = "SmallHero-module--description--1wXFa";