import * as React from 'react';

import { IContentfulVacationBanner } from '../../utils/types';
import { Footer, IInfoLink } from '../Footer';
import { data as FooterData } from '../Footer/data';
import { IMenuLink, Menu } from '../Menu';
import { socialLinks } from '../Menu/shared/data';

export interface ILayoutProps {
  links: IMenuLink[];
  infoLinks: IInfoLink[];
  children: React.ReactNode;
  isWidget?: boolean;
  vacationBanner?: IContentfulVacationBanner;
}

export const Layout = ({
  links,
  children,
  infoLinks,
  isWidget,
  vacationBanner,
}: ILayoutProps): JSX.Element => {
  return (
    <div data-testid="layout">
      <Menu
        links={links}
        socialLinks={socialLinks}
        isWidget={isWidget}
        vacationBanner={vacationBanner}
      />
      <main>{children}</main>
      <Footer
        socialLinks={FooterData.socialLinks}
        menuLinks={links}
        infoLinks={infoLinks}
      />
    </div>
  );
};
